// require all prototypes from the prototype file
import * as prototype from "./prototypes";
import { baseURL, baseURL1 } from "./baseURL";
import axios from "axios";
import OpenAI from "openai"
import { store } from "./ConfigureStore";
import { GPTTechSkillServiceFilteringFromExp, GPTPersonalSkillServiceFilteringFromExp } from "../utils/skillsService";
import { saveIntoServerCache } from "../utils/saveIntoCache";

//login loading dispatching function
export const socket_loading = () => ({
  type: prototype.SOCKET_LOADING,
});

// login failed dispatching function
export const socketio = (response) => ({
  type: prototype.SOCKET,
  payload: response,
});

//login loading dispatching function
export const total_page_loading = () => ({
  type: prototype.TOTAL_PAGE_LOADING,
});

// login failed dispatching function
export const total_page = (response) => ({
  type: prototype.TOTAL_PAGE,
  payload: response,
});

//login loading dispatching function
export const login_loading = () => ({
  type: prototype.LOGIN_LOADING,
});

// login failed dispatching function
export const login_failed = (response) => ({
  type: prototype.LOGIN_FAILED,
  payload: response,
});

//login dispatching function
export const login_success = (response) => ({
  type: prototype.LOGIN_USER,
  payload: response,
});

export const clearLogin = () => async (dispatch) => {
  // localStorage.removeItem("token");
  dispatch(login_loading(true));
  dispatch(signup_loading(true));
  dispatch(userNameInfo_loading(true));
  dispatch(getting_cv_details_loading(true));
  dispatch(v_mail_loading(true));
  dispatch(r_reset_loading(true));
  dispatch(JobtitleDetails_loading(true));
  dispatch(clearStoredValue());
  dispatch(cv_id_loading(true));
  dispatch(cv_loading(true));
  dispatch(about_loading(true));
  dispatch(secret_loading(true));
  dispatch(getting_employer_profile_loading(true));
  dispatch(job_loading(true));
  dispatch(job_posting_details_loading(true));
  dispatch(JobDescription_loading(true));
  dispatch(JobDetails_loading(true));
  dispatch(chat_info_loading(true));
  dispatch(end_to_end_chat_loading(true));
  dispatch(send_message_loading(true));
  dispatch(LanguageDetails_loading(true));
  dispatch(decision_loading(true));
  dispatch(notification_loading(true));
  dispatch(total_page_loading(true));
  dispatch(send_otp_loading(true));
  dispatch(verify_otp_loading(true));
  dispatch(jobs_posted_loading(true));
  dispatch(candidate_type_loading(true));
  dispatch(candidate_category_loading(true));
  dispatch(onlineUsersClear(true));
  dispatch(analytics_loading(true));
  dispatch(analytics_loading_weekly(true));
  dispatch(SiteErrorLoading(true));
  // dispatch(save_link_loading(true));
  // dispatch(summaryUpdateCountLoading(true));
};

export const logOut = () => async (dispatch) => {
  fetch(baseURL + "/api/users/logout", {
    method: "POST",
    body: JSON.stringify({ token: localStorage.getItem("token") }),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log(resp.message);
      if (resp.success == true) {
        localStorage.removeItem("token");
        // window.location.assign(resp.status)
      }
    }).catch(err => {
      // console.log("Logout error", err)
    });
};

// last page loading
export const last_page_loading = () => ({
  type: prototype.GET_LAST_PAGE_VISITED_LOADING,
});

export const last_page_error = (response) => ({
  type: prototype.GET_LAST_PAGE_VISITED_ERROR,
  payload: response,
});

export const last_page = (response) => ({
  type: prototype.GET_LAST_PAGE_VISITED,
  payload: response,
});

export const referralLinkLoading = (response) => ({
  type: prototype.REFERRER_LINK_LOADING,
  payload: response,
});

export const referralLink = (response) => ({
  type: prototype.REFERRER_LINK,
  payload: response,
});

// login function
// cred means the credentials for logging in (username, passowrd, acctType-> official or client)

export const signInFunction = (cred) => async (dispatch) => {
  // upon calling this fuction first dispatch a loading status into the store
  dispatch(login_loading(true));
  const mail = cred.username?.trim();
  const pass = cred.password?.trim();
  let obj = {
    username: mail,
    password: pass,
  };
  return axios
    .post(baseURL + "/api/users/signin", obj, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true
    })
    .then((resp) => {
      // console.log("login resp", resp)
      if (resp.data.data.success === true) {
        localStorage.setItem("token", resp.data.data.token);
        dispatch(login_success({ profile_status: resp.data.data.profile_status.profile_status, AcctType: resp.data.data.profile_status.AcctType }));
        dispatch(referralLink(resp.data.data?.referral));
        const store_value = store.getState();
        // console.log("link", store_value.userLink.message);
        // console.log("log in resp", resp);

        if (store_value.userLink.message !== null) {
          if (resp.data.data.profile_status.AcctType === "Client") {
            if (resp.data.data.profile_status.profile_status === true || resp.data.data.profile_status.profile_status === "true") {
              window.location.assign(store_value.userLink.message);
            } else if (resp.data.data.profile_status.profile_status === false || resp.data.data.profile_status.profile_status === "false") {
              window.location.assign(
                baseURL1 +
                `/user/dashboard?open=true`
              );
            }
          } else if (resp.data.data.profile_status.AcctType === "Official") {
            if (resp.data.data.profile_status.profile_status === true || resp.data.data.profile_status.profile_status === "true") {
              window.location.assign(store_value.userLink.message);
            } else if (resp.data.data.profile_status.profile_status === false || resp.data.data.profile_status.profile_status === "false") {
              window.location.assign(
                baseURL1 +
                `/employer/dashboard?open=true`
              );
            }
          }
        } else {
          localStorage.setItem("token", resp.data.data.token);
          if (resp.data.data.profile_status.AcctType === "Client") {
            if (resp.data.data.profile_status.profile_status === true || resp.data.data.profile_status.profile_status === "true") {
              window.location.assign(
                baseURL1 +
                `${resp.data.data.lastpage}`
              );
            } else if (resp.data.data.profile_status.profile_status === false || resp.data.data.profile_status.profile_status === "false") {
              window.location.assign(
                baseURL1 +
                `${resp.data.data.lastpage}`
              );
            }
          } else if (resp.data.data.profile_status.AcctType === "Official") {
            if (resp.data.data.profile_status.profile_status === true || resp.data.data.profile_status.profile_status === "true") {
              window.location.assign(
                baseURL1 +
                `${resp.data.data.lastpage}`
              );
            } else if (resp.data.data.profile_status.profile_status === false || resp.data.data.profile_status.profile_status === "false") {
              window.location.assign(
                baseURL1 +
                `${resp.data.data.lastpage}`
              );
            }
          }
        }
      } else {
        dispatch(login_failed("Incorrect password or username"));
      }
    })
    .catch((err) => {
      // console.log("This error occurred while logging in", err);
      dispatch(login_failed(err.response.data.data.status));
    });
};

// logout loading dispatch fucntion

export const logout_loading = () => ({
  type: prototype.LOGOUT_LOADING,
});

export const logout_success = (response) => ({
  type: prototype.LOG_OUT,
  payload: response,
});

export const returnHome = (status) => async (dispatch) => {
  localStorage.removeItem("token");
  dispatch(login_loading(true));
  dispatch(clearLogin());
  dispatch(logOut());
  window.location.assign(baseURL1);
}

//signup loading dispatching function
export const signup_loading = () => ({
  type: prototype.SIGNUP_LOADING,
});

// login failed dispatching function
export const signup_failed = (response) => ({
  type: prototype.SIGNUP_FAILED,
  payload: response,
});

//login dispatching function
export const signup_success = (response) => ({
  type: prototype.SIGNUP_USER,
  payload: response,
});

// signup function
// cred means the credentials for signing in (username, passowrd, acctType-> official or client)

export const signUpFunction = (cred) => async (dispatch) => {
  // upon calling this fuction first dispatch a loading status into the store
  dispatch(signup_loading(true));
  const mail = cred.username?.trim();
  const pass = cred.password?.trim();
  const code = cred.code !== "null" && cred.code !== null ? cred.code?.trim() : "";
  const flow = cred.flow !== "null" && cred.flow !== null ? cred.flow?.trim() : "";
  const obj = {
    email: mail,
    password: pass,
    AcctType: cred.AcctType,
    code: code,
    flow: flow
  };
  // console.log(obj);
  return await axios
    .post(baseURL + "/api/users/signup", obj, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((resp) => {
      // console.log("signing in resp", resp);
      if (resp.data.success === true) {
        // store the authentication token value inside the localstorage got he browser.
        dispatch(signup_success(resp.data));
      }
    })
    .catch((err) => {
      // console.log("This error occurred while signing in", err);
      dispatch(signup_failed(err.response.data.message));
    });
};

// official google auth function

export const officialGoogleAuth = () => async (dispatch) => {
  // open the auth route
  window.open(baseURL + "/api/auth/google2", "_self");
};

// call this funcion to get signed in user authorization token

export const googleSuccess = () => async (dispatch) => {
  // dispatch(login_success({ profile_status: token }));
  let url = baseURL + `/api/auth/success?code=${localStorage.getItem("ref")}`;
  await axios(url, { withCredentials: true })
    .then(resp => {
      // console.log("data data", resp);
      if (resp.data.success == true) {
        localStorage.removeItem("ref");
        dispatch(login_success({ profile_status: resp.data.profile_status, AcctType: resp.data.AcctType }));
        localStorage.setItem("token", resp.data.token);
        dispatch(referralLink(resp.data.data?.createRef?.link));
        if (resp.data.lastpage) {
          // dispatch(last_page(`${resp.data.lastpage}`))
          // window.location.assign(baseURL1 + `${resp.data.lastpage}`);
        }
      }
    }).catch(err => {
      // console.log("err", err);
      if (err.response?.data.success == false) {
        window.location.assign(baseURL1 + "/");
      }
    });
};



// cv section
export const cv_loading = () => ({
  type: prototype.CV_LOADING,
});

export const cv_failed = (response) => ({
  type: prototype.GENERATING_CV_FAILED,
  payload: response,
});

export const cv_success = (response) => ({
  type: prototype.CV_GENERATED_SUCCESSFULLY,
  payload: response,
});

// default credentials
// on clickng Done btn, call this function here.
export const return_CV_state_back_to_null = () => (dispatch) => {
  dispatch(cv_loading(true));
  dispatch(cv_id_loading(true));
};

export const Personal_CV_state_back_to_null = () => (dispatch) => {
  dispatch(cv_loading(true));
};

export const spanish_loading = () => ({
  type: prototype.SPANISH_LOADING,
});

export const spanish_success = (spanish) => ({
  type: prototype.SPANISH,
  payload: spanish,
});

export const dispatchSpanish = (arg) => async (dispatch) => {
  dispatch(spanish_success(arg));
};

export const summaryUpdateCountLoading = () => ({
  type: prototype.COUNT_LOADING
})
export const summaryUpdateCount = () => ({
  type: prototype.COUNT
})

export const about_loading = () => ({
  type: prototype.ABOUT_LOADING,
});

export const about_failed = (cred) => ({
  type: prototype.ABOUT_FAILED,
  payload: cred,
});

export const about_success = (cred) => ({
  type: prototype.ABOUT,
  payload: cred,
});

export const generate_employer_about = (cred) => async (dispatch) => {
  dispatch(about_loading(true));
  // console.log("cred", cred)
  const command = `Prompt for Generating an Enhanced Company Introduction:
Given the user's selected input and output language (${cred.language}), this task involves transforming and enriching the provided company details into a succinct yet informative introduction. The introduction should encapsulate the essence of the company, tailored for business contexts, and maintain a strict length limit of under 300 words. The process includes capitalizing the company name and industry type, as well as ensuring spelling accuracy in the industry type to enhance the output's professionalism.

Instructions:

Language Adaptation: Before processing, adjust the prompt to the language specified by ${cred.language} to ensure consistency in the output.
Input Details: Take the provided details — company description (${cred.about}), company name (${cred.name}), city (${cred.city}), and industry type (${cred.type}) — and apply necessary transformations. This includes capitalizing the company name and industry type and verifying spelling accuracy for the industry type.
Content Enhancement: Fill in gaps with industry-standard and related information to present the company impressively. Utilize knowledge of the industry to add relevant details that might not be explicitly provided by the user but are essential for creating a robust introduction.
Output Formatting: Present the transformed and enhanced company details in a JSON format with specific keys: CompanyName, CompanyType, CompanyDescription. This structured output is designed for clarity and immediate use in various business contexts.
Example Structure for the Final Output:

json
Copy code
{
    "CompanyName": "Transformed and Capitalized Company Name",
    "CompanyType": "Enhanced, Capitalized, and Spell-checked Industry Type",
    "CompanyDescription": "A succinct yet comprehensive introduction, under 300 words, enriched with industry standards and pertinent information."
}`

  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true
  });
  await openai.chat.completions.create({
    model: "gpt-4-turbo",
    messages: [{ role: "user", content: command }],
    temperature: 0.9,
    response_format: { type: "json_object" }
  })
    .then((resp) => {
      const result = resp.choices[0].message.content;
      // console.log("result", result);
      let strip = result.slice(result.indexOf("{"), result.lastIndexOf("}") + 1);
      let r1 = strip;
      try {
        r1 = JSON.parse(r1);
      } catch (error) {
        if (error instanceof SyntaxError) {
          dispatch(generate_employer_about(cred));
        } else {
          // console.error('Unexpected error:', error.message);
        }
      }

      if (typeof r1 == "object") {
        dispatch(about_success(r1));
      }
      return result;
    })
    .catch((err) => {
      if (
        err.message == "Network Error" ||
        err.message == "timeout exceeded" ||
        err.message == "Request failed with status code 503"
      ) {
        dispatch(about_failed("Network error"));
      }
    });
};

export const lang_type = (cred) => ({
  type: prototype.LANG_TYPE_S,
  payload: cred,
});

// generate resume function
export const generate_my_cv = (cred) => async (dispatch) => {
  dispatch(cv_loading(true));
  // console.log("My credentials", cred)
  if (Object.keys(cred).length == 0) {
    // dispatch error message
    dispatch(cv_failed("No credentials"));
  } else {
    // console.log("cred", cred);
    const final_obj = { ...cred, Lang: [{ lang: "English" }] };
    dispatch(cv_success(final_obj));
  }
};

// dispatch the id selected to store
export const cv_id_loading = () => ({
  type: prototype.CV_ID_LOADING,
});

export const dispatch_cv_id = (id) => ({
  type: prototype.CV_ID,
  payload: id,
});

export const Cv_id = (id) => (dispatch) => {
  dispatch(dispatch_cv_id(id.id));
};
// Existing resume section

export const user_CV_state_back_to_null = () => (dispatch) => {
  dispatch(existing_resume_loading(true));
};

// get user's name to be used in dashboard

export const userNameInfo_loading = () => ({
  type: prototype.LOADING_USER_NAME,
});

export const userInfo = (response) => ({
  type: prototype.USER_NAME_DB,
  payload: response,
});

export const userName_from_db = () => async (dispatch) => {
  return fetch(baseURL + "/api/users/myInfo", {
    headers: {
      // Authorization: "Bearer " + params,
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("user information", resp);
      if (resp.success === true) {
        dispatch(userInfo(resp.data));
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => {
      // console.log("getting user name error", err);
    });
};

export const save_last_resume = (response) => ({
  type: prototype.LAST_RESUME,
  payload: response,
});

export const lastResme = (cred) => async (dispatch) => {
  dispatch(save_last_resume(cred));
};

export const getting_cv_details_loading = () => ({
  type: prototype.GETTING_CV_LOADING,
});

export const getting_failed = (response) => ({
  type: prototype.GETTING_CV_FAILED,
  payload: response,
});

export const getting_successful = (response) => ({
  type: prototype.GETTING_CV_SUCCESS,
  payload: response,
});

// empployer start

export const getting_employer_profile_loading = () => ({
  type: prototype.GET_EMPLOYER_PROFILE_LOADING,
});

export const getting_employer_profile_failed = (response) => ({
  type: prototype.GET_EMPLOYER_PROFILE_FAILED,
  payload: response,
});

export const getting_employer_profile_successful = (response) => ({
  type: prototype.GET_EMPLOYER_PROFILE_SUCCESS,
  payload: response,
});

export const dispatch_employer_employee_info_loading = (response) => ({
  type: prototype.EMPLOYER_EMPLOYEEINFO_LOADING,
});

export const dispatch_employer_employee_info = (response) => ({
  type: prototype.EMPLOYER_EMPLOYEEINFO,
  payload: response,
});

export const getEmployerProfile = () => async (dispatch) => {
  return fetch(baseURL + "/api/employer/my_employee_profile", {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include" // This enables sending cookies cross-origin
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("profile resp", resp);

      if (resp.success === true) {
        if (resp.data === null) {
          const response = [];
          // dispatch(getting_profile_successful(response));
        } else {
          if (resp.data.profile) {
            dispatch(getting_employer_profile_successful(resp.data.profile));
          }
          if (resp.data.user) {
            dispatch(userInfo(resp.data.user));
          }
        }
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      } else {
        dispatch(getting_employer_profile_failed(resp.status));
      }
    })
    .catch((err) => {
      // console.log("error while fetching cvs from database", err);
      dispatch(getting_employer_profile_failed(err));
    });
};

export const updateStripeCredentials = (/* secret, */ jobposting_credit) => async (dispatch) => {
  return fetch(baseURL + "/api/employer/updateStripeCredentials", {
    method: "POST",
    body: JSON.stringify({
      // secret: secret,
      stripe_jobposting_credit: jobposting_credit,
    }),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then(resp => resp.json())
    .then(resp => {
      if (resp.success && resp.data !== null) {
        dispatch(getting_employer_profile_successful(resp.data.profile));
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      }
    }).catch(err => {
      // console.log("Unable to update profile with stripe payment credentials")
    });
}

// employer ends

// save link
export const save_link_loading = (response) => ({
  type: prototype.SAVE_LINK_LOADING,
});

export const savelink = (response) => ({
  type: prototype.SAVE_LINK,
  payload: response,
});

export const getting_profile_profile_loading = () => ({
  type: prototype.GET_PUBLIC_PROFILE_LOADING,
});

export const getting_public_profile_failed = (response) => ({
  type: prototype.GET_PUBLIC_PROFILE_FAILED,
  payload: response,
});

export const getting_public_profile_successful = (response) => ({
  type: prototype.GET_PUBLIC_PROFILE_SUCCESS,
  payload: response,
});

export const employeePublicView = (profile_id) => async (dispatch) => {
  dispatch(getting_profile_profile_loading(true));
  return fetch(baseURL + `/api/users/profile/${profile_id}`, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("profile resp", resp);

      if (resp.success === true) {
        if (resp.data === null) {
          const response = [];
          dispatch(getting_public_profile_successful(response));
        } else {
          dispatch(getting_public_profile_successful(resp.data));
        }
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      } else {
        dispatch(getting_public_profile_failed(resp.message));
      }
    })
    .catch((err) => {
      // console.log("error while fetching cvs from database", err);
      dispatch(getting_public_profile_failed(err));
    });
};

// function to save users cv details to the backend
export const SiteError = (response) => ({
  type: prototype.ERROR,
  payload: response,
});
export const SiteErrorLoading = () => ({
  type: prototype.ERROR_LOADING,
});

export const Click = (response) => ({
  type: prototype.CLICK,
  payload: response,
});
export const ClickLoading = () => ({
  type: prototype.CLICK_LOADING,
});
// forget password section

export const v_mail_loading = () => ({
  type: prototype.V_MAIL_LOADING,
});

export const v_mail_failed = (response) => ({
  type: prototype.V_MAIL_FAILED,
  payload: response,
});

export const v_mail_success = () => ({
  type: prototype.V_MAIL_SUCCESS,
});

export const sendMail = (mail) => async (dispatch) => {
  dispatch(v_mail_loading(true));

  const obj = {
    username: mail,
  };
  fetch(baseURL + "/api/users/yourMail", {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("Mail sent for verification", resp);
      if (resp.success === true) {
        dispatch(v_mail_success(resp.status));
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      } else {
        dispatch(v_mail_failed(resp.message));
      }
    })
    .catch((err) => {
      // console.log("an error occured", err);
      dispatch(v_mail_failed(err));
    });
};

export const r_reset_loading = () => ({
  type: prototype.R_SET_LOADING,
});

export const r_reset_failed = (response) => ({
  type: prototype.R_SET_FAILED,
  payload: response,
});

export const r_reset_success = (response) => ({
  type: prototype.R_SET_SUCCESS,
  payload: response,
});

export const reset_password = (cred) => async (dispatch) => {
  dispatch(r_reset_loading(true));
  const obj = {
    newPassword: cred.password,
    confirmPassword: cred.confirmPassword,
    userId: cred.userId,
  };

  fetch(baseURL + "/api/users/forgetPassword", {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("password changed successfully", resp);
      if (resp.success === true) {
        dispatch(r_reset_success(resp.data));
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      } else {
        dispatch(r_reset_failed(resp.status));
      }
    })
    .catch((err) => {
      // console.log("An error occurred while setting a new password.");
      dispatch(r_reset_failed(err));
    });
};

export const skillsDetails = (skills) => ({
  type: prototype.SKILLS,
  payload: skills,
});

export const skillsDetails_loading = () => ({
  type: prototype.SKILLS_LOADING,
});

// dispatch skills into the store
export const dispatchSkills = (skills) => (dispatch) => {
  dispatch(skillsDetails(skills));
};

export const p_skillsDetails = (pskills) => ({
  type: prototype.PERSONAL_SKILLS,
  payload: pskills,
});

export const p_skillsDetails_loading = () => ({
  type: prototype.PERSONAL_SKILLS_LOADING,
});

// dispatch skills into the store
export const dispatchPskills = (pskills) => (dispatch) => {
  dispatch(p_skillsDetails(pskills));
};

export const JobtitleDetails = (jobtitle) => ({
  type: prototype.JOBTITLE,
  payload: jobtitle,
});

export const JobtitleDetails_loading = () => ({
  type: prototype.JOBTITLE_LOADING,
});

// dispatch job title into the store
export const dispatchJobtitle = (Jobtitle) => (dispatch) => {
  dispatch(JobtitleDetails(Jobtitle));
};
export const LanguageDetails = (lang) => ({
  type: prototype.LANGUAGE,
  payload: lang,
});

export const LanguageDetails_loading = () => ({
  type: prototype.LANGUAGE_LOADING,
});

// dispatch activities into the store
export const dispatchLanguage = (lang) => (dispatch) => {
  dispatch(LanguageDetails(lang));
};

export const LanguageTranslate = (lg) => ({
  type: prototype.LANGUAGE_TRANSLATION,
  payload: lg,
});

// export const LanguageTranslate_loading = () => ({
//   type: prototype.LANGUAGE_TRANSLATION_LOADING,
// });

// dispatch activities into the store
export const dispatchT_Language = (lg) => (dispatch) => {
  dispatch(LanguageTranslate(lg));
};

export const clearStoredValue = () => (dispatch) => {
  dispatch(LanguageDetails_loading(true));
  dispatch(skillsDetails_loading(true));
  dispatch(JobtitleDetails_loading(true));
  dispatch(p_skillsDetails_loading(true));
};

export const secret_loading = () => ({ // resume secret 
  type: prototype.SECRET_LOADING,
});

export const save_secret_success = (err) => ({
  type: prototype.SECRET,
  payload: err,
});

export const save_secret = (secret) => async (dispatch) => {
  dispatch(save_secret_success(secret));
};

export const lang_loading = () => ({
  type: prototype.LANG_LOADING,
});

export const lang_success = (lang) => ({
  type: prototype.LANG,
  payload: lang,
});
// resume language
export const lang_function = (cert) => async (dispatch) => {
  dispatch(lang_success(cert));
};

// Job posting redux functions start
export const JobDetailed = (JobDetails) => ({
  type: prototype.JOBDETAILS,
  payload: JobDetails,
});

export const JobDetails_loading = () => ({
  type: prototype.JOBDETAILS_LOADING,
});

// dispatch activities into the store
export const dispatchJobDetail = (JobDetails) => (dispatch) => {
  dispatch(JobDetailed(JobDetails));
};

export const JobDescriptioned = (award) => ({
  type: prototype.JOBDESCRIPTION,
  payload: award,
});

export const JobDescription_loading = () => ({
  type: prototype.JOBDESCRIPTION_LOADING,
});

// dispatch activities into the store
export const dispatchJobDescription = (JobDescription) => (dispatch) => {
  dispatch(JobDescriptioned(JobDescription));
};

export const job_loading = () => ({
  type: prototype.JOB_LOADING,
});

export const job_success = (details) => ({
  type: prototype.JOB,
  payload: details,
});

export const job_failed = (details) => ({
  type: prototype.JOB_FAILED,
  payload: details,
});

// that includes the minimum requirements
//  and Requirements 
export const job_creation_helper_function = (cred) => async (dispatch) => {
  const command = `Given the user's selected input and output language (${cred.language}), you are tasked as an HR specialist to create a comprehensive job posting. Start by capitalizing and correcting the spellings of the job title (${cred.job_title}). Then, generate a job description that is at least 250 words long, detailing the minimum requirements typical for a (${cred.job_title}) role.

Additionally, enumerate the top 20 key responsibilities and the top 10 necessary technical skills for the (${cred.job_title}) role, ensuring these elements are distinct from the job description itself.

Instructions:

Language Adaptation: Adjust the prompt to the language specified by ${cred.language}, ensuring consistency throughout the job posting.
Job Title Processing: Capitalize the job title (${cred.job_title}) and correct any spelling errors.
Job Description Generation: Craft a job description of at least 250 words for the role, ensuring it is informative and engaging.
Key Responsibilities and Technical Skills Listing: Clearly list the top 20 key responsibilities and the top 10 necessary technical skills and soft skills under separate keys, emphasizing that these are distinct from the job description.
Job Category Selection: Categorise this job ${jobTitle} into one, two or three of this categories by selecting the most fitting category for the job from the following provided list: "
Food and Drinks, Hospitality and Tourism, Warehousing, Construction and Trades, Manufacturing and Production, Entry Level Office, Landscaping, Electrical, Hvac & Plumbing". If the job title can't be categorized into any of the above categories, assign "Entry Level Office" to the job title only when there are no other fitting option within the category. Return the result in JSON readable format putting the category into an array following this key: "Category"

Return the result or response in (${cred.Language}) language.

Output Formatting: Present the job posting details in a JSON format with keys for Description, Responsibilities, Skills, Category, and Title, ensuring the format is easily readable and parsable.
Example Structure for the Final Output:
 
{
    "Description": "Detailed and brief job description of at least 250 words.",
    "Responsibilities": ["Responsibility 1", "Responsibility 2", ..., "Responsibility 20"],
    "Skills": ["Technical Skill 1", "Technical Skill 2", ..., "Technical Skill 10"],
    "Category": "Selected Category",
    "Title": "Capitalized and Spell-checked Job Title"
}
  `;

  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true
  });
  await openai.chat.completions.create({
    model: "gpt-4o",
    messages: [{ role: "user", content: command }],
    temperature: 0.9,
    response_format: { type: "json_object" }
  })
    .then((resp) => {
      // // console.log(
      //   "Job creation result = ",
      //   resp.choices[0].message.content
      // );
      const result = resp.choices[0].message.content;
      let r1 = result.replace("\n", "");
      // // console.log(
      //   "Strip!!!",
      //   r1.slice(r1.indexOf("{"), r1.lastIndexOf("}") + 1)
      // );
      let r2 = r1.slice(r1.indexOf("{"), r1.lastIndexOf("}") + 1);
      // let returned_vaue = {};
      // if (Array.isArray(r2.Responsibilities)) {
      //   let arr = [];

      // }
      // // console.log("r2", r2);
      try {
        r2 = JSON.parse(r2);
        // // console.log("r2", r2);
      } catch (error) {
        if (error instanceof SyntaxError) {
          // console.error('Invalid JSON format:', error.message);
          // console.log("Calling the function again.")
          dispatch(job_creation_helper_function(cred));
        } else {
          // console.error('Unexpected error:', error.message);
        }
      }
      if (typeof r2 == "object") {
        if (Array.isArray(r2.Responsibilities) == true) {
          r2.Responsibilities = r2.Responsibilities.join("\n");
        } else {
          r2.Responsibilities = r2.Responsibilities;
        }
        dispatch(job_success(r2));
      }
    })
    .catch((err) => {
      // console.log(err)
    });
};


// job posting details storing functions to save intending job post values in case of refresh
export const job_posting_details_loading = () => ({
  type: prototype.JOB_POSTING_DETAILS_LOADING,
});

export const job_posting_details = (details) => ({
  type: prototype.JOB_POSTING_DETAILS,
  payload: details,
});

export const posting_loading = () => ({
  type: prototype.POSTING_LOADING,
});

export const posting_err = (details) => ({
  type: prototype.POSTING_ERR,
  payload: details,
});

export const posting_success = (details) => ({
  type: prototype.POSTING_SUCCESS,
  payload: details,
});

export const create_job = (cred) => async (dispatch) => {
  let token = localStorage.getItem("token");
  return fetch(baseURL + "/api/job/create_job_post", {
    method: "POST",
    body: JSON.stringify(cred),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + token,
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("resp", resp);
      if (resp.success === true) {
        // console.log("Job created successfully", resp);
        dispatch(posting_success(resp.data.job));
        if (resp.data?.profile) {
          dispatch(getting_employer_profile_successful(resp.data.profile));
        }
        dispatch(SiteError(resp.data.message));
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("err", resp.message);
        dispatch(posting_err(resp.message.split(":")[1].replace('"', "")));
      }
    })
    .catch((err) => {
      // console.log("This error occured while posting or creating a job: ", err)
    }
    );
};

// JObs posted redux action

export const jobs_posted_loading = () => ({
  type: prototype.JOBS_POSTED_LOADING,
});

export const jobs_posted_err = (data) => ({
  type: prototype.JOBS_POSTED_FAILED,
  payload: data,
});

export const jobs_posted_success = (data) => ({
  type: prototype.JOBS_POSTED,
  payload: data,
});

export const getAllJobsPosted = (page) => async (dispatch) => {
  // console.log("Bearer " + localStorage.getItem("token"));
  return fetch(baseURL + `/api/job/get_employers_job_posts/${page}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("All jobs ", resp);
      if (resp.success == true) {
        dispatch(jobs_posted_success(resp?.data?.jobPosts));
        dispatch(total_page(resp?.data?.page_num));
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      } else {
        window.location.assign(baseURL1)
        // dispatch(jobs_posted_err(resp.data));
      }
    })
    .catch((err) => {
      // console.log(err)
    });
};

// job to update by an employer

export const job_to_update_loading = () => ({
  type: prototype.JOB_TO_UPDATE_LOADING,
});

export const job_to_update = (data) => ({
  type: prototype.JOB_TO_UPDATE,
  payload: data,
});

export const updateJob = (jobName, value) => async (dispatch) => {
  // console.log("value", value);
  return fetch(baseURL + "/api/job/update_job_data/" + jobName, {
    method: "PATCH",
    body: JSON.stringify(value),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("response", resp)
      if (resp.success == true) {
        // replace the job in the list of job posted array
        const job_store = store.getState();
        const job_posted = job_store.jobs_posted;
        // console.log("Job posted", job_posted)
        // get the index of the object to replace
        let indexOfJob = [];
        job_posted.message.forEach((element) => {
          if (element._id === resp.data._id) {
            element = resp.data;
            indexOfJob.push(element);
          }
          indexOfJob.push(element);
        });
        dispatch(jobs_posted_success(indexOfJob));
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      }
    })
    .catch((err) => {
      // console.log("Job update error", err)
    });
};
// get applicants of a particular job

export const job_applicants_loading = () => ({
  type: prototype.JOB_APPLICANTS_LOADING,
});

export const job_applicants_failed = (data) => ({
  type: prototype.JOB_APPLICANTS_FAILED,
  payload: data,
});

export const job_applicants = (data) => ({
  type: prototype.JOB_APPLICANTS,
  payload: data,
});

export const getJobApplicants = (jobName) => async (dispatch) => {
  return fetch(baseURL + "/api/job/get_applicants/" + jobName, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("applicants result", resp.data);
        dispatch(job_applicants(resp.data));
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("error resp", resp);
        dispatch(job_applicants_failed(resp.status));
      }
    })
    .catch((err) => {
      // console.log("Applicants getting func failed", err)
    });
};

export const recommended_candi_loading = () => ({
  type: prototype.RECOMMENDED_CANDIDATE_LOADING,
});

export const recommended_candi_failed = (data) => ({
  type: prototype.RECOMMENDED_CANDIDATE_FAILED,
  payload: data,
});

export const recommended_candid = (data) => ({
  type: prototype.RECOMMENDED_CANDIDATE,
  payload: data,
});

export const getRecommendedCandidate = (page) => async (dispatch) => {
  dispatch(recommended_candi_loading(true));
  // default page is 1 till further notice
  let p = {
    page: !!page ? page : 1,
  };
  return fetch(baseURL + "/api/employer/recommendedClients", {
    method: "POST",
    body: JSON.stringify(p),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(recommended_candid(resp.data?.result));
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("recommended candid failed", resp);
        dispatch(recommended_candi_failed(resp.data));
      }
    })
    .catch((err) => {
      // console.log("An error occured while getting recommended candidate", err)
    }
    );
};


// get chat information

export const message_notification_loading = () => ({
  type: prototype.MESSAGE_NOTIFICATION_LOADING,
});

export const message_notification = (data) => ({
  type: prototype.MESSAGE_NOTIFICATION,
  payload: data,
});

export const chat_info_loading = () => ({
  type: prototype.CHAT_INFO_LOADING,
});

export const chat_info_failed = (data) => ({
  type: prototype.CHAT_INFO_FAILED,
  payload: data,
});

export const chat_info = (data) => ({
  type: prototype.CHAT_INFO,
  payload: data,
});

export const chat_info_set = (data) => ({
  type: prototype.CHAT_INFO,
  payload: data,
});

export const getChatInformation = (profileId) => async (dispatch) => {
  return fetch(baseURL + `/api/chat/getChat/${profileId}`, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: 'include', // 'same-origin', 'omit' are other options
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("chat information", resp);
        dispatch(chat_info(resp.data));
        let msg_notification = 0;
        // loop tru resp.data to get the number of message notifications
        for (let i = 0; i <= resp?.data.length - 1; i++) {
          if (resp?.data[i].Notification > 0) {
            msg_notification += 1;
          }
        }
        dispatch(message_notification(msg_notification)); // dispatch the overall notification.
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("error resp", resp);
        dispatch(chat_info_failed(resp.status));
      }
    })
    .catch((err) => {
      // console.log("chat information getting func failed", err)
    });
};

export const getChatInfo = (profileId) => async (dispatch) => {
  dispatch(chat_info_loading(true));
  return fetch(baseURL + `/api/chat/getChat/${profileId}`, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("chat information", resp);
        dispatch(chat_info(resp.data));
      }
    })
    .catch((err) => {
      // console.log("chat information getting func failed", err)
    });
}

// get chat information wit a particular user

export const end_to_end_chat_loading = () => ({
  type: prototype.END_TO_END_CHAT_LOADING,
});

export const end_to_end_chat_failed = (data) => ({
  type: prototype.END_TO_END_CHAT_FAILED,
  payload: data,
});

export const end_to_end_chat = (data) => ({
  type: prototype.END_TO_END_CHAT,
  payload: data,
});

export const getEndToEndChatInformation =
  (employerId, employeeId) => async (dispatch) => {
    return fetch(baseURL + `/api/chat/getChatMessage/${employerId}/${employeeId}`, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("token"),
      },
      credentials: "include"
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success === true) {
          // console.log("chat information", resp);
          dispatch(end_to_end_chat(resp.data));
        } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "Refresh token mismatch" || resp.message == "No token provided")) {
          dispatch(returnHome(baseURL1));
        } else {
          // console.log("error resp", resp);
          dispatch(end_to_end_chat_failed(resp.status));
        }
      })
      .catch((err) => {
        // console.log("chat information with another user func failed", err)
      }
      );
  };

// get chat information

export const send_message_loading = () => ({
  type: prototype.SEND_MESSAGE_LOADING,
});

export const send_message_failed = (data) => ({
  type: prototype.SEND_MESSAGE_FAILED,
  payload: data,
});

export const send_message = (data) => ({
  type: prototype.SEND_MESSAGE,
  payload: data,
});

export const sendMessage = (chatId, senderId, message, profileId) => async (dispatch) => {
  // dispatch(send_message_loading());
  const obj = {
    chatId: chatId,
    senderId: senderId,
    message: message
  }
  // console.log("obj", obj)
  return fetch(baseURL + `/api/chat/sendMessage`, {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("chat information", resp);
        dispatch(send_message(resp.data));
        dispatch(end_to_end_chat(resp.data));
        // dispatch(getChatInfo(profileId));
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("error resp", resp);
        dispatch(send_message_failed(resp.status));
      }
    })
    .catch((err) => {
      // console.log("sending message to another user func failed", err)
    }
    );
};


// establish a chat
export const establishChat = (employerId, employeeId, JobTitle, resumeId, jobId) => async (dispatch) => {
  const obj = {
    employerId: employerId,
    employeeId: employeeId,
    JobTitle: JobTitle,
    resumeId: resumeId,
    jobId: jobId
  }
  // console.log("obj", obj);
  return fetch(baseURL + "/api/chat/establish", {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  }).then(resp => resp.json())
    .then(resp => {
      if (resp.success === true) {
        // console.log("Chat established successfully", resp);
        // if ( resp.data !== null ) {
        //   dispatch(chat_info(resp.data));
        // }
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      }
    })
}


// send decision card for employers

export const decision_loading = () => ({
  type: prototype.DECISION_CARD_LOADING,
});

export const decision_failed = (data) => ({
  type: prototype.DECISION_CARD_FAILED,
  payload: data,
});

export const decision_card = (data) => ({
  type: prototype.DECISION_CARD,
  payload: data,
});

export const sendDecisionCard = (cred) => async (dispatch) => {
  const obj = {
    info: cred.additionalInfo,
    compensationInfo: cred.compensationInfo,
    location: cred.jobLocation,
    workday: cred.selectedDays,
    start_date: cred.selectedOptions.startingDateSelect,
    open_time: cred.selectedOptions.openingTimeSelect,
    closing_time: cred.selectedOptions.closingTimeSelect,
    decisionCardId: cred.decisionCardId
  }
  // console.log("obj", obj)
  return fetch(baseURL + "/api/decision/send", {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  }).then(resp => resp.json())
    .then(resp => {
      // console.log("Decision card initialized successfully", resp);
      if (resp.success === true) {
        (dispatch(decision_card(resp.data)));
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      }
    })
}

export const getDecisionCard = (decisionCardId) => async (dispatch) => {
  return fetch(baseURL + "/api/decision/getDecisionCard/" + decisionCardId, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  }).then(resp => resp.json())
    .then(resp => {
      // console.log("Decision card initialized successfully", resp);
      if (resp.success === true) {
        (dispatch(decision_card(resp.data)));
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      }
    })
}

export const notification_loading = () => ({
  type: prototype.NOTIFICATION_LOADING,
});

export const notification_success = (data) => ({
  type: prototype.NOTIFICATION,
  payload: data,
});

export const getNotification = (employerId) => async (dispatch) => {
  return fetch(baseURL + "/api/job/jobNotification", {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  }).then(resp => resp.json())
    .then(resp => {
      // console.log("Job notification has arrive", resp);
      if (resp.success === true) {
        (dispatch(notification_success(resp.data)))
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      }
    }).catch(err => {
      // console.log("error", err)
    });
}
// Send verification otp

// save a job that was posted

export const verify_otp_loading = () => ({
  type: prototype.PHONE_VERIFICATION_LOADING,
});

export const verify_otp_failed = (data) => ({
  type: prototype.PHONE_VERIFICATION_FAILED,
  payload: data,
});

export const verify_otp = (data) => ({
  type: prototype.PHONE_VERIFICATION,
  payload: data,
});

export const verifyOtp = (code, phoneNumber) => async (dispatch) => {
  dispatch(verify_otp_loading());
  const obj = {
    token: code,
    phone_number: phoneNumber
  }
  return fetch(baseURL + `/api/users/phone/verify_otp`, {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        // console.log("Verification successful", resp)
        dispatch(userInfo(resp?.data));
        dispatch(verify_otp(resp.status));
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("otp verification failed", resp);
        dispatch(verify_otp_failed(resp.message));
      }
    })
    .catch((err) => {
      // console.log("An error occured while verifying otp", err)
    }
    );
};


// sending of otp

export const send_otp_loading = () => ({
  type: prototype.SEND_OTP_LOADING,
});

export const send_otp_failed = (data) => ({
  type: prototype.SEND_OTP_FAILED,
  payload: data,
});

export const send_otp = (data) => ({
  type: prototype.SEND_OTP,
  payload: data,
});

export const sendOtp = (phoneNumber) => async (dispatch) => {
  dispatch(send_otp_loading());
  const obj = {
    phone_number: phoneNumber
  }
  return fetch(baseURL + `/api/users/phone/send_otp`, {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(send_otp(resp.status));
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("send otp failed", resp);
        dispatch(send_otp_failed(resp.message));
      }
    })
    .catch((err) => {
      // console.log("An error occured while sending otp", err)
    }
    );
};


// get a job

export const get_a_job_loading = () => ({
  type: prototype.GET_A_JOB_LOADING,
});

export const get_a_job_failed = (data) => ({
  type: prototype.GET_A_JOB_FAILED,
  payload: data,
});

export const get_a_job = (data) => ({
  type: prototype.GET_A_JOB,
  payload: data,
});


export const getAjob = ({ jobId }) => async (dispatch) => {
  return fetch(baseURL + `/api/job/getAjob/${jobId}`, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.success === true) {
        dispatch(get_a_job(resp.data));
      } else if (resp.success === false && resp.message == "Unauthorized") {
        dispatch(returnHome(resp.status));
      } else {
        // console.log("Job not gotten successfully", resp);
        dispatch(get_a_job_failed(resp.message));
      }
    })
    .catch((err) => {
      // console.log("An error occured while sending otp", err)
    }
    );
}

// get a public user resume

export const getPublicUserResumeLoading = () => ({
  type: prototype.PUBLIC_RESUME_LOADING,
});

export const getPublicUserResumeFailed = (data) => ({
  type: prototype.PUBLIC_RESUME_FAILED,
  payload: data,
});

export const getPublicUserResume = (data) => ({
  type: prototype.PUBLIC_RESUME,
  payload: data,
});

export const getPublicUserResumeData = (userId) => async (dispatch) => {
  dispatch(getPublicUserResumeLoading(true));
  return fetch(baseURL + `/api/resumeUpload/getcvpublic/${userId}`, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // console.log("resume resp", resp);

      if (resp.success === true) {
        if (resp.data === null) {
          const response = [];
          dispatch(getPublicUserResume(response));
        } else {
          dispatch(getPublicUserResume(resp.data));
        }
      } else {
        dispatch(getPublicUserResumeFailed(resp.message));
      }
    })
    .catch((err) => {
      // console.log("error while fetching cvs from database", err);
      dispatch(getPublicUserResumeFailed(err));
    });
}

// add an employee to the list of job applicants

export const application_public_loading = () => ({
  type: prototype.APPLICATION_PUBLIC_LOADING,
});

export const application_public_error = (data) => ({
  type: prototype.APPLICATION_PUBLIC_FAILED,
  payload: data,
});

export const application_public_successful = (data) => ({
  type: prototype.APPLICATION_PUBLIC_SUCCESSFUL,
  payload: data,
});

export const ApplyForAJobPublic = (jobName, resumeId, userId, type) => async (dispatch) => {
  dispatch(application_public_loading(true));
  return fetch(baseURL + `/api/job/apply_for_job_public/${jobName}/${userId}/${resumeId}/${type}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // do something
      if (resp.success === true) {
        dispatch(application_public_successful(resp.status));
      } else {
        // console.log(resp);
        dispatch(application_public_error(resp?.message));
      }
    })
    .catch((err) => {
      // console.log("application error", err)
    });
};

export const new_job_match_loading = () => ({
  type: prototype.NEW_JOB_POST_LOADING,
});

export const new_job_match_error = (data) => ({
  type: prototype.NEW_JOB_POST_FAILED,
  payload: data,
});

export const new_job_match_successful = (data) => ({
  type: prototype.NEW_JOB_POST_SUCCESSFUL,
  payload: data,
});

export const createReferralLinkLoading = () => ({
  type: prototype.CREATE_REFERRAL_LINK_LOADING,
});

export const createReferralLinkSuccess = (referralLink) => ({
  type: prototype.CREATE_REFERRAL_LINK_SUCCESS,
  payload: referralLink,
});

export const createReferralLinkFailed = (error) => ({
  type: prototype.CREATE_REFERRAL_LINK_FAILED,
  payload: error,
});



export const createReferralLink = (userId) => async (dispatch) => {
  dispatch(createReferralLinkLoading());

  return fetch(baseURL + `/api/referral/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("token"),
    },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then((resp) => {
      // do something
      if (resp.success === true) {
        dispatch(createReferralLinkSuccess(resp.status));
      } else {
        dispatch(createReferralLinkFailed(resp?.message));
      }
    })
    .catch((err) => {
      // console.log("application error", err)
    });
}

export const candidate_category_loading = () => ({
  type: prototype.CANDIDATE_CATEGORY_LOADING,
});

export const candidate_category_success = (data) => ({
  type: prototype.CANDIDATE_CATEGORY,
  payload: data,
});

export const candidate_skill_match_loading = () => ({
  type: prototype.CANDIDATE_SKILL_MATCH_LOADING,
});

export const candidate_skill_match_success = (data) => ({
  type: prototype.CANDIDATE_SKILL_MATCH,
  payload: data,
});

export const candidate_type_loading = () => ({
  type: prototype.CANDIDATE_TYPE_LOADING,
});

export const candidate_type_success = (data) => ({
  type: prototype.CANDIDATE_TYPE,
  payload: data,
});

export const onlineUsersClear = () => ({
  type: prototype.ONLINE_LOADING,
});

export const onlineUsers = (users) => ({
  type: prototype.ONLINE,
  payload: users,
});

export const individual_job_cat = (data) => ({
  type: prototype.INDIVIDUAL_JOB_CANDIDATE_SUCCESSFUL,
  payload: data,
});

export const individual_job_cat_failed = (data) => ({
  type: prototype.INDIVIDUAL_JOB_CANDIDATE_FAILED,
  payload: data,
});

export const individual_job_cat_loading = () => ({
  type: prototype.INDIVIDUAL_JOB_CANDIDATE_LOADING,
});

export const SidebarOpen = () => ({
  type: prototype.SIDEBAR,
})

export const analytics = (data) => ({
  type: prototype.ANALYTICS,
  payload: data,
});

export const analytics_loading = () => ({
  type: prototype.ANALYTICS_LOADING,
});

export const getEmployerAnalytics = () => async (dispatch) => {
  try {
    let response = await axios.get(`${baseURL}/api/analytics/employer-monthly`, {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    });
    // console.log("response", response);
    if (response.data.success == true) {
      dispatch(analytics(response.data.data));
    } else {
      // dispatch(analytics([]));
    }
  } catch (error) {
    throw error;
  }
}

export const analytics_weekly = (data) => ({
  type: prototype.ANALYTICS_WEEKLY,
  payload: data,
});

export const analytics_loading_weekly = () => ({
  type: prototype.ANALYTICS_LOADING_WEEKLY,
});

export const getEmployerAnalyticsWeekly = (startDate, endDate, type) => async (dispatch) => {
  try {
    let obj = {
      startDate,
      endDate,
      type
    }
    let response = await axios.post(`${baseURL}/api/analytics/employer-weekly`, JSON.stringify(obj), {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    });
    // console.log("response", response);
    if (response.data.success == true) {
      dispatch(analytics_weekly(response.data.data));
    } else {
      // dispatch(analytics([]));
    }
  } catch (error) {
    throw error;
  }
}

export const job_doc = (data) => ({
  type: prototype.JOBS_Doc,
  payload: data,
});

export const job_doc_failed = (data) => ({
  type: prototype.JOBS_Doc_FAILED,
  payload: data,
});

export const job_doc_loading = () => ({
  type: prototype.JOBS_Doc_LOADING,
});

export const UploadJobDocument = (arg) => async (dispatch) => {
  const bearer = localStorage.getItem("token");
  // // console.log("arg", arg)
  return fetch(baseURL + "/api/extract/JobDoc", {
    method: "POST",
    body: arg,
    // headers: {
    //   "Content-Type": "application/json",
    //   // Authorization: "Bearer " + bearer,
    // },
    credentials: "include"
  })
    .then((resp) => resp.json())
    .then(resp => {
      // console.log("extracted", resp)
      if (resp.success == true && resp.data !== null) {
        dispatch(job_doc(resp.data));
        dispatch(job_success(resp.data));
        dispatch(job_posting_details(resp.data));
        let store_value = store.getState();
        let profile = store_value.employer.message;
        if (profile == null) {
          window.open(baseURL1 + "/employer/welcome", "_self");
        } else {
          window.open(baseURL1 + "/employer/create-job", "_self");
        }
      } else {
        // // console.log(resp);
        if (resp.success == false && resp.message == "No token provided") {
          dispatch(logOut());
          window.location.assign(baseURL1);
        }
        dispatch(job_doc_failed("Please try again."))
      }
    }).catch(err => {
      // console.log("error", err)
    })
};

