import React from "react";
import { RatingSvg } from "../svgs/svgs";
import UserAvatar from "../avatar/avatar";
import { useNavigate } from "react-router-dom";

export default function JobPostCandidate(props) {
  const navigate = useNavigate();
  const width = window.innerWidth;

  // console.log(width)
  const handleApplicationView = () => {
    if (props.status === "INTERVIEW" || props.status === "HIRED") {
      navigate("/employer/inbox");
    } else {
      navigate(
        `/employer/jobs/${props.jobName}/candidates/${props.profileId}/${props.dataIndex}`
      )
    }
  }

  return (
    <tr  onClick={
      handleApplicationView
    }
      className={`text-neutral-900 text-base cursor-pointer font-medium tracking-wide font-Poppins rounded-lg border-t border-indigo-300 mt-4`}
    >
      <td className="h-[66.3px] py-3 pl-6 flex items-center gap-2 whitespace-nowrap max-w-[200px] lg:max-w-[300px] xl:max-w-[350px] xtraxl:max-w-[350px] pr-3">
      <img className="w-10 h-10 rounded-[4px]" src={props.avatar} />
        <span className="truncate text-[#5843BD] text-center capitalize">{props.candidate?.toLowerCase()}</span>
      </td>
      <td className="py-3 hidden xl:table-cell">
        <p className="text-center whitespace-nowrap"> {props.jobApplicationDate}</p>
      </td>
      <td className="py-3"><p className="text-center whitespace-nowrap">{props.testScore !== undefined ? `${props?.testScore}%` : "0%"}</p></td>
      <td>
        <div className="py-4 w-full flex justify-center items-center">
          <div className={`py-3 pr-3 whitespace-nowrap ${props.status === "Matched"
            ? "rounded-[4px] py-2 px-3 bg-[#5843BD80] text-sm lg:text-base font-medium leading-normal text-white"
            : props.status === "Pending"
              ? "rounded-[4px] py-2 px-3 bg-[#FF663326] text-sm lg:text-base font-medium leading-normal text-[#111111]"
              : "rounded-[4px] py-2 px-3 bg-[#FF6633] text-sm lg:text-base font-medium leading-normal text-[#FFFFFF]"
            }`}>{props.status}</div>
        </div>
      </td>
      <td className="py-3 whitespace-nowrap">
        <div
          className="py-2 pr-4 whitespace-nowrap text-center cursor-pointer text-sm lg:text-base font-medium w-full leading-normal text-left text-[#5843BD]"
         
        >
          View Details
        </div>
      </td>
    </tr>
  );
}
