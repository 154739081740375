import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApplicantSkillTestPrevItem from "../common/applicant_answer_skill_test_item";

export default function TestDetails() {
  const dataIndex = useParams().applicationDataIndexNum;
  const applicants = useSelector((state) => state.applicants.message);

  const skillTest = applicants?.jobInfo?.skillTest?.skillTestQuestions;
  const answers = applicants?.applications[dataIndex]?.answers;
  const performanceSummary = applicants?.applications[dataIndex]?.performanceSummary;

  // Destructure strengths, weaknesses, and summary safely
  const { strengths = [], weaknesses = [], summary = "" } = performanceSummary || {};

  return (
    <>
      <div className="h-calc-150 md:h-calc-70 px-4 py-8 overflow-y-scroll flex justify-center">
        <div className="w-full flex flex-col gap-y-6 lg:max-w-[1000px] xl:max-w-[1100px] xllarge:max-w-[1200px]">

          {/* Performance Summary Section */}
          {performanceSummary && (
            <div className="w-full px-4 sm:px-6 py-6 bg-white border border-gray-200 flex-col justify-start items-start gap-4 flex">
              <h2 className="text-[#252430] text-[24px] leading-[1.2] font-semibold">Performance Summary</h2>

              {/* Strengths */}
              <div>
                <h3 className="text-[#252430] text-[20px] leading-[1.2] font-semibold">Strengths:</h3>
                {strengths.length > 0 ? (
                  <ul className="list-disc list-inside">
                    {strengths.map((item, index) => (
                      <li key={index} className="text-[#252430] text-[16px] leading-[1.5]">
                        {item}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-[#252430] text-[16px] leading-[1.5]">No strengths identified.</p>
                )}
              </div>

              {/* Weaknesses */}
              <div>
                <h3 className="text-[#252430] text-[20px] leading-[1.2] font-semibold">Weaknesses:</h3>
                {weaknesses.length > 0 ? (
                  <ul className="list-disc list-inside">
                    {weaknesses.map((item, index) => (
                      <li key={index} className="text-[#252430] text-[16px] leading-[1.5]">
                        {item}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-[#252430] text-[16px] leading-[1.5]">No weaknesses identified.</p>
                )}
              </div>

              {/* Summary */}
              <div>
                <h3 className="text-[#252430] text-[20px] leading-[1.2] font-semibold">Overall Performance:</h3>
                <p className="text-[#252430] text-[16px] leading-[1.5] italic">{summary}</p>
              </div>
            </div>
          )}

          {/* Skill Test Section */}
          {skillTest && answers && (
            <div className="w-full px-4 sm:px-6 py-6 bg-[#F9F4FD] border border-[#E6E9F5] flex-col justify-start items-start gap-8 flex">
              <div className="flex items-center w-full justify-between">
                <p className="text-[#252430] text-[24px] leading-[1.2]">Candidate Skill Test Preview</p>
              </div>

              <div className="flex flex-col w-full gap-y-6">
                {skillTest.map((test, i) => {
                  const answerIndex = answers.findIndex(answer => answer.question === test.question);
                  const userAnswer = answerIndex !== -1 ? answers[answerIndex].answer : null;

                  return (
                    <ApplicantSkillTestPrevItem
                      key={i}
                      userAnswer={userAnswer}
                      question={test.question}
                      options={test.options}
                      answer={test.answer}
                      i={i + 1}
                    />
                  );
                })}

              </div>
            </div>
          )}
        </div>
      </div>
      <div></div>
    </>
  );
}
