import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PricingSection from "../common/pricing_section";
import { ToastContainer, toast } from "react-toastify";
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from "react-redux";
import { useAnalytics } from '../../context/AnalyticsContext';
import { baseURL } from "../../Redux/baseURL";
import { createPortal } from "react-dom";
import { getEmployerProfile, getting_employer_profile_successful } from "../../Redux/ActionCreator";
import CancelSubscriptionModal from "../common/cancel_subscription";
import LoaderModal from "../common/LoaderModal";
import { getExpectedAmountToPay } from "../../utils/subscriptionHelper/getExpectedAmount";
import AddPaymentMethod from "./updatePaymentMethod";
import PaymentFailedModal from "../common/paymentFailed"

export default function SubscriptionPage() {
  const sidebar = useSelector(e => e.sidebarOpen.message);
  const [cancel, setCancel] = useState(false);

  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const employer = useSelector(e => e.employer.message);
  const [errorMessage, setErrorMessage] = useState(null);
  // initialize useStripe and useEffect hook
  const stripe = useStripe();
  const elements = useElements();
  const [cardElement, setCardElement] = useState(null);
  const [coupon, setCoupon] = useState('');
  const [load, setLoad] = useState(false);
  const [updatePaymentMtd, setUpdatePaymentMtd] = useState(false);

  useEffect(() => {
    dispatch(getEmployerProfile());
  }, []);

  // test card active = 4242 4242 4242 4242
  // insufficient card = 4000 0000 0000 9995
  const handleSubscribe = async (type, cta, operationType) => {
    // console.log("type", type, "cta", cta, "operationType", operationType);

    if (employer.plan == undefined && (cta == "Sign Up Now" || cta == "Start Free 14-day Trial") && operationType == "subscribe") {
      // if (!stripe || !elements) {
      //   // Stripe.js hasn't yet loaded.
      //   // Make sure to disable form submission until Stripe.js has loaded.
      //   return;
      // }
      const response = async () => {
        fetch(baseURL + "/api/stripe/test", {
          method: "POST",
          body: JSON.stringify({
            name: employer?.name,
            email: employer?.email,
            subPlanType: type,
            coupon: coupon
          }),
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + localStorage.getItem("token"),
          },
          credentials: "include"
        }).then(resp => resp.json())
          .then(resp => {
            if (resp.statusCode == 200 && resp.success === true) {
              window.open(resp.data.url, "_self");
            } else if (resp.success === false && resp?.message == "Unauthorized") {
              dispatch(returnHome(resp?.status));
            } else if (resp.statusCode == undefined) {
              if (resp?.title == "Error") {
                setTimeout(() => {
                  toast.error(resp.error?.raw?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }, 50);
              }
            }
            return resp;
          }).catch(err => {
            // console.log("my err", err)
          });
      }
      let res = await response();

      analytics.track("Payment btn from stripe", {
        Type: "Employer",
        time: new Date(),
        Email: employer?.email,
        Company_name: employer?.name,
        profileId: employer?._id,
        userId: employer?.userId
      });
    } else {
      // type basic_monthly cta Renew Plan operationType subscribe

      if (cta == "Sign Up Now") {
        setTimeout(() => {
          toast.warning("You already subscribed to a plan. Kindly click on ungrade button to upgrade your plan. Thanks", {
            position: toast.POSITION.TOP_CENTER,
          })
        }, 50);
      }

      if ((cta == "Upgrade Plan" || cta == "Downgrade Plan") && (operationType == "upgrade" || operationType == "subscribe")) {
        // handleUpgrade(true, cta, type, "text-flag");
      }

      if (cta == "Cancel Plan" && operationType == "cancel") {
        if (type !== employer?.plan) {
          toast.error("You can only cancel the current plan you are subscribed to. Thanks", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          await handleCancelPlan();
        }
      }
      // setTimeout(() => {
      //   toast.warning("You already subscribed to a plan. Kindly click on ungrade button to upgrade your plan. Thanks", {
      //     position: toast.POSITION.TOP_CENTER,
      //   })
      // }, 50);
    }
  }

  const handleRenewPlan = async () => {
    // // console.log("type", type);
    if (employer.plan !== undefined) {
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const response = async () => {
        fetch(baseURL + "/api/stripe/renewPlan", {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + localStorage.getItem("token"),
          },
          credentials: "include"
        }).then(resp => resp.json())
          .then(resp => {
            if (resp.statusCode == 200 && resp.success === true) {
              toast.success("Plan renewed successfully", {
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (resp.success === false && resp?.message == "Unauthorized") {
              dispatch(returnHome(resp?.status));
            } else if (resp.statusCode == undefined) {
              if (resp?.title == "Error") {
                setTimeout(() => {
                  toast.error(resp.error?.raw?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }, 50);
              }
            }
            return resp;
          }).catch(err => {
            // console.log("my err", err)
          });
      }

      let res = await response();

    } else {
      setTimeout(() => {
        toast.warning("You have to subscribe to a plan first. Thanks", {
          position: toast.POSITION.TOP_CENTER,
        })
      }, 50);
    }
  }

  const handleUpgradePlan = async (type) => {
    // console.log("upgrade type", type);
    if (employer.plan !== undefined) {
      // if (!stripe || !elements) {
      //   // Stripe.js hasn't yet loaded.
      //   // Make sure to disable form submission until Stripe.js has loaded.
      //   return;
      // }
      const response = async () => {
        fetch(baseURL + "/api/stripe/upgradePlan", {
          method: "PATCH",
          body: JSON.stringify({
            subscriptionPlan: type
          }),
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + localStorage.getItem("token"),
          },
          credentials: "include"
        }).then(resp => resp.json())
          .then(resp => {
            if (resp.statusCode == 200 && resp.success === true) {
              toast.success("Plan upgraded successfully", {
                position: toast.POSITION.TOP_CENTER,
              });
              // console.log("resp", resp);
              // dispatch(getting_employer_profile_successful(resp.data));
              setTimeout(() => {
                dispatch(getEmployerProfile()); // wait for the webhook to update the employer profile
                handleClose("upgrade");
              }, 100);
            } else if (resp.success === false && resp?.message == "Unauthorized") {
              dispatch(returnHome(resp?.status));
            } else if (resp.statusCode == undefined) {
              if (resp?.title == "Error") {
                setTimeout(() => {
                  toast.error(resp.error?.raw?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }, 50);
              }
            }
            return resp;
          }).catch(err => {
            // console.log("my err", err)
          });
      }
      if (type == employer?.plan) {
        setTimeout(() => {
          toast.warning("You can't upgrade to the same plan. Thanks", {
            position: toast.POSITION.TOP_CENTER,
          })
        }, 50);
      } else {
        let res = await response();
      }

    } else {
      setTimeout(() => {
        toast.warning("You have to subscribe to a plan first. Thanks", {
          position: toast.POSITION.TOP_CENTER,
        })
      }, 50);
    }
  }


  const handleCancelPlan = async (type) => {
    // // console.log("type", type);
    if (employer.plan !== undefined) {
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const response = async () => {
        fetch(baseURL + "/api/stripe/cancelPlan", {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + localStorage.getItem("token"),
          },
          credentials: "include"
        }).then(resp => resp.json())
          .then(resp => {
            if (resp.statusCode == 200 && resp.success === true) {
              toast.success("Plan cancelled successfully.", {
                position: toast.POSITION.TOP_CENTER,
              });
              setTimeout(() => {
                dispatch(getEmployerProfile()); // wait for the webhook to update the employer profile
                handleClose("cancel");
              }, 50);
            } else if (resp.success === false && resp?.message == "Unauthorized") {
              dispatch(returnHome(resp?.status));
            } else if (resp.statusCode == undefined) {
              if (resp?.title == "Error") {
                setTimeout(() => {
                  toast.error(resp.error?.raw?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }, 300);
              }
            }
            return resp;
          }).catch(err => {
            // console.log("my err", err)
          });
      }
      let res = await response();

    } else {
      setTimeout(() => {
        toast.warning("You have to subscribe to a plan first. Thanks", {
          position: toast.POSITION.TOP_CENTER,
        })
      }, 50);
    }
  }

  let [operation, setOperation] = useState("subscribe");
  let [direct, setDirect] = useState(false);
  const [cta, setCta] = useState("Upgrade Plan");
  const [type, setType] = useState("");
  const handleCancel = () => {
    setCancel(true);
    setCta("Downgrade Plan");
    setOperation("cancel");
    setDirect(false);
  };

  let [flag, setFlag] = useState(null);
  let [amountToPay, setAmoutToPay] = useState(null);

  const handleUpgrade = async (status, cta, type, flag) => {
    if (employer?.plan == undefined) {
      navigate("/employer/subscribe/select")
    } else {
      setCta(cta || "Upgrade Plan");
      setOperation("upgrade");
      // setOperation(cta == "Upgrade Plan" ? "upgrade" : "downgrade");
      setDirect(status);
      setType(type);
      if (flag == "text-flag") {
        // get expected amount first
        if (cta == "Upgrade Plan") {
          let expectedAmount = await getExpectedAmountToPay(type);
          expectedAmount = await expectedAmount.json();
          if (expectedAmount) setLoad(false);
          // console.log("expected amount ", expectedAmount.data);
          setAmoutToPay(expectedAmount.data);
        }
        setFlag(flag);
        setCancel(true);
      } else {
        setCancel(true);
      }
    }
  };

  let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const handleClose = (type) => {
    if (type == "cancel") {
      setCancel(false);
      setOperation("subscribe");
    }
  }

  const [usedSlot, setUsedSlot] = useState(null);
  let [subStartdate, setSubStartDate] = useState("Start date");
  let [subNextBillingDate, setSubNextBillingDate] = useState("No active plan");
  let [activePlan, setActivePlan] = useState("");
  let [duration, setDuration] = useState("");

  useEffect(() => {
    if (employer?.plan == "basic_monthly") {
      setActivePlan("Basic");
      setDuration("Monthly");
    } else if (employer?.plan == "basic_yearly") {
      setActivePlan("Basic");
      setDuration("Yearly");
    } else if (employer?.plan == "standard_monthly") {
      setActivePlan("Standard");
      setDuration("Monthly");
    } else if (employer?.plan == "standard_yearly") {
      setActivePlan("Standard");
      setDuration("Yearly");
    } else if (employer?.plan == "professional_monthly") {
      setActivePlan("Professional");
      setDuration("Monthly");
    } else if (employer?.plan == "professional_yearly") {
      setActivePlan("Professional");
      setDuration("Yearly");
    } else {
      setActivePlan("");
      setDuration("");
    }
  }, [employer]);

  const [ paymentFailed, setPaymentFailed ] = useState(false);
  let searchParams = new URLSearchParams(window.location.search).get("subscribe");
  // console.log("search params", searchParams);

  useEffect(() => {
    if (searchParams == "true") {
      setTimeout(() => {
        dispatch(getEmployerProfile());
      }, 300);
    }
  }, [searchParams]);

  useEffect(() => {

    // if (employer?.paymentStatus == "failed") {
    //   setPaymentFailed(true);
    // }

    if (employer?.plan == undefined) {
      setUsedSlot("0 of 0");
    } else if (employer?.plan == "professional_monthly") {
      setUsedSlot("Unlimited monthly");
    } else if (employer?.plan == "professional_yearly") {
      setUsedSlot("Unlimited yearly");
    } else {
      setUsedSlot(`${employer?.stripe_jobposting_credit_used !== undefined ? employer?.stripe_jobposting_credit_used : 0} / ${employer?.stripe_jobposting_credit} jobs`);
    }


    // date 
    let starDate = `${monthNames[new Date(employer?.currentSubStartDate)?.getMonth()]} ${new Date(employer?.currentSubStartDate)?.getDate()}. ${new Date(employer?.currentSubStartDate)?.getFullYear()}`
    let endDate = `${monthNames[new Date(employer?.subNextBillingDate)?.getMonth()]} ${new Date(employer?.subNextBillingDate)?.getDate()}. ${new Date(employer?.subNextBillingDate)?.getFullYear()}`

    if (employer?.currentSubStartDate !== undefined) {
      setSubStartDate(starDate);
    } else {
      setSubStartDate("Start date");
    }
    if (employer?.subNextBillingDate !== undefined) {
      setSubNextBillingDate(endDate);
    } else {
      setSubNextBillingDate("No active plan");
    }
    
    if (searchParams == "true" && employer?.paymentStatus == "failed") {
      setPaymentFailed(true);
    }

  }, [employer]);

  const handleCloseFailedPaymentModal = () => {
    setPaymentFailed(false);
  }


  let cancelPlan = cancel ? createPortal(
    <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px] " onClick={() => handleClose("cancel")}  >
      <CancelSubscriptionModal handleSubscribe={handleSubscribe} setCancel={setCancel} operationType={operation} close={handleClose} direct={direct} cta={cta} type={type} flag={flag} amountToPay={amountToPay} />
    </div>, document.getElementById("modal")
  ) : null

  let failedPaymentModal = paymentFailed == true ? createPortal(
    <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px] " onClick={() => handleCloseFailedPaymentModal()}  >
      <PaymentFailedModal handleCloseFailedPaymentModal={handleCloseFailedPaymentModal} cta={cta} />
    </div>, document.getElementById("modal")
  ) : null

  return (
    <>
      <div className="h-calc-150 md:h-calc-70 px-4 py-8 overflow-y-scroll flex justify-center">
        <div className="w-full flex flex-col gap-8 md:px-0 lg:max-w-[1000px] xl:max-w-[1100px] xllarge:max-w-[1200px]">
          <div className="flex flex-col gap-y-6">
            <div className="flex flex-col gap-y-1">
              <p className="text-[20px] md:text-[24px] leading-[1.5] font-semibold text-black">
                My Subscription Plan
              </p>
              <p className="text-base leading-[1.5] text-black">
                Select a plan that meets your needs, renew an existing plan and <br className="hidden md:block" />
                check your subscription stats.
              </p>
            </div>

            <div className={`grid grid-cols-1 xl:grid-cols-[2fr_3fr] gap-4 ${sidebar ? '' : ''}`} >
              <div className={`bg-[#22263A] min-h-[200px] ${sidebar ? '' : ''} gap-y-5 flex flex-col justify-between rounded-lg p-4`}>
                <div className="flex flex-col gap-y-3">
                  <p className="leading-[1.75] text-base text-white ">Active Plan {employer?.cancelStatus == true ? "(status: Cancelled)" : null}</p>
                  <p className={`text-base text-white  ${employer?.plan == undefined ? "italic text-xl leading-[1.4]" : "leading-[1.75] font-semibold"}`}>
                    {employer?.plan == undefined ? <>You have no active plan.<br /> Subscribe to a plan below.</> : activePlan} <span className="font-normal">{employer?.plan == undefined ? null : (`(${duration})`)}</span>
                  </p>
                </div>
                <div className="flex flex-col md:flex-row gap-y-2 md:gap-x-6 lg:gap-x-8 items-center">
                  <div className="text-base w-full cursor-pointer focus:text-white active:text-white hover:text-white leading-normal w-full md:min-w-[190px] flex justify-center items-center py-3 dash-link-grad text-center text-white font-medium rounded-lg" /* to="upgrade" */ onClick={() => handleUpgrade(false)}>
                    {employer?.plan == undefined ? "See available plans" : "Upgrade plan"}
                  </div>
                  {employer?.plan != undefined &&
                    <div className="text-base focus:text-white active:text-white hover:text-white leading-normal w-full md:min-w-[190px] flex justify-center items-center py-3 text-center text-white font-medium rounded-lg border-[1.5px] border-[#FCFCFC33] cursor-pointer" onClick={handleCancel}>
                      Cancel Subscription
                    </div>
                  }
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col gap-y-2">
                  <div className="flex flex-col gap-y-4 bg-white p-4 rounded-lg">
                    <p className="text-base leading-[1.75] text-[#1D2739]">Amount paid</p>
                    <p className="text-base leading-[1.75] text-[#1D2739] font-medium">${employer?.paid_amount || 0}</p>
                  </div>
                  <div className="flex flex-col gap-y-4 bg-white p-4 rounded-lg">
                    <p className="text-base leading-[1.75] text-[#1D2739]">Used job Posts</p>
                    <div className="flex gap-y-1 justify-between">
                      <p className="text-base leading-[1.75] text-[#1D2739] font-medium">{usedSlot}</p>
                      {usedSlot !== "No available job slot or credit" && <Link to="/employer/jobs" className="flex justify-start items-center gap-1">
                        <p className="text-sm leading-[1.5] text-[#5843BD] font-medium" onClick={() => navigate("/employer/jobs")}>view jobs</p>
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.91797 7.5013H11.0846M11.0846 7.5013L8.7513 9.83464M11.0846 7.5013L8.7513 5.16797" stroke="#5843BD" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </Link>}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-y-2">
                  <div className="flex flex-col gap-y-4 bg-white p-4 rounded-lg">
                    <p className="text-base leading-[1.75] text-[#1D2739]">Renewal date</p>
                    <div className="flex gap-y-1 justify-between">
                      <p className="text-base leading-[1.75] text-[#1D2739] font-medium">{subNextBillingDate}</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-4 bg-white p-4 rounded-lg">
                    <p className="text-base leading-[1.75] text-[#1D2739]">Payment Details {employer?.plan == undefined ? null : <span className="text-base leading-[1.75] text-[#1D2739]">Expires {/* {employer?.cardExpires} */}</span>}</p>
                    <div className="text-base leading-[1.75] text-[#1D2739]">{employer?.plan == undefined ? "Not yet added" : (
                      <p><span className="px-2 text-[#171E6C] font-bold border border-[#1D2739] rounded-md">{employer?.cardType}</span> Ending in {employer?.lastFourDigit} </p>
                      )} {employer?.plan == undefined ? null : <span className="text-sm leading-[1.5] text-[#5843BD] font-medium cursor-pointer" onClick={() => setUpdatePaymentMtd(true)}>{updatePaymentMtd == true ? <AddPaymentMethod setUpdatePaymentMtd={setUpdatePaymentMtd}/> : "Edit Details"}</span>}</div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <PricingSection handleSubscribe={handleSubscribe} operationType={operation} display={false} hide={true} />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {cancelPlan}
      {failedPaymentModal}
      {load && <LoaderModal text="Getting the upgrade fee..." />}
    </>
  );
}
