import { baseURL } from "../../Redux/baseURL";

export const getExpectedAmountToPay = async (type) => {
    let body = {
      subscriptionPlan: type
    }
    return fetch(`${baseURL}/api/stripe/expected`, {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
}