import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import BackButton from "../../common/back_button";
import HelmetProvide from "../../common/helmet";
import { ToastContainer, toast } from "react-toastify";
import SkillTestItem from "../../common/skill_test_item";
import Tab from "../../common/tab/tab";
import { saveIntoServerCache, getOneFromServerCache } from "../../../utils/saveIntoCache";
import { useSelector, useDispatch } from "react-redux";
import { baseURL1, baseURL } from "../../../Redux/baseURL";
import { logOut } from "../../../Redux/ActionCreator";
import LoaderModal from "../../common/LoaderModal";

export default function SkillsTest(props) {

  const dispatch = useDispatch();
  const jobDetails = useSelector((info) => info.jobDetails.jobDetails);
  let [skillTest, setSkillTest] = useState([]);
  let [allAnswer, setAllAnswers] = useState([]);
  const [generateStatus, setGenerateStatus] = useState(false);
  const [three, setThreetime] = useState(0);

  useEffect(() => {
    const getSkillTest = async () => {
      try {
        let key = "skillTest-" + jobDetails.jobTitle;
        let Test = getOneFromServerCache(key);
        Test.then(res => res.json())
          .then(data => {
            if (data.message == "No token provided" || data.message == "Unauthorized") {
              setTimeout(() => {
                toast.error("Session Expired! Please Login Again", {
                  position: toast.POSITION.TOP_CENTER,
                });
                dispatch(logOut());
                window.location.assign(baseURL1);
              }, 50)
            }
            // console.log("data", data.data);
            let resp = JSON.parse(data.data);
            if (resp !== null) {
              setSkillTest(resp?.questions !== undefined ? resp.questions : resp);
              setAllAnswers(prev => resp?.questions !== undefined ? resp?.questions?.map((item) => item.answer) : resp?.map((item) => item.answer));
            } else {
              // that means there is no skil test for the job now
              createSkillTest()
            }
          })
      } catch (error) {
      //  console.log("error", error);
        throw error;
      }
    }
    getSkillTest();
  }, []);

  useEffect(() => {
    // i want to check all options respectively
  }, [skillTest]);

  const onChangeHandler = async (e) => {
    // console.log("Was called!", e);
    const { name, value, type, checked } = e;
    let index = parseInt(name, 10);
    skillTest[index - 1].question = value;
    setSkillTest(skillTest);
    let key = "skillTest-" + jobDetails.jobTitle;
    await saveIntoServerCache(key, skillTest);
  }

  const onChangeHandlerForOption = async (e) => {
    // console.log("option called!", e);
    const { name, value, type, checked } = e;
    let nameIndex = name.split(",");
    let i = parseInt(nameIndex[0], 10);
    let j = parseInt(nameIndex[1], 10);
    skillTest[i - 1].options[j] = value;
    setSkillTest(skillTest);
    let key = "skillTest-" + jobDetails.jobTitle;
    await saveIntoServerCache(key, skillTest);
  }

  const onChangeHandlerForOptionChecked = async (e) => {
    // console.log("option called!", e.target.value);
    const { name, value, type, checked } = e.target;
    let nameIndex = value.split(",");
    let i = parseInt(nameIndex[0], 10);
    let j = parseInt(nameIndex[1], 10);
    skillTest[i - 1].answer = skillTest[i - 1].options[j];
    setAllAnswers(prev => skillTest?.map((item) => item.answer));
    setSkillTest(skillTest);
    // console.log("skillTest", skillTest);
    let key = "skillTest-" + jobDetails.jobTitle;
    await saveIntoServerCache(key, skillTest);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log("skillTest", skillTest);
      let key = "skillTest-" + jobDetails.jobTitle;
      await saveIntoServerCache(key, skillTest);
      props.handleStepChangeForward();
    } catch (error) {
      throw error;
    }
  }

  const createSkillTest = async () => {
    setGenerateStatus(true);
    return fetch(baseURL + `/api/test/skillTest/${jobDetails?.jobTitle}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(resp => {
        setGenerateStatus(false);
        // console.log("skill test", resp);
        setSkillTest(resp?.data?.questions !== undefined ? resp?.data.questions : resp?.data);
        setAllAnswers(prev => resp?.data.questions !== undefined ? resp?.data?.questions?.map((item) => item.answer) : resp?.data?.map((item) => item.answer));
      }).catch(err => {
       // console.log("error", err);
      });
  }

  let decideModal = generateStatus ? <LoaderModal text={`Generating skill test for ${jobDetails?.jobTitle} job`} /> : null

  return (
    <>
      <HelmetProvide
        title="Moil | Create job"
        description="Create and manage job listings on Moil. Connect with talented blue-collar workers and find the best candidates for your team."
      />

      <div className="px-3 lg:pl-6 py-8 w-full flex justify-center h-calc-150 md:h-calc-70 overflow-y-scroll">
        <div className="h-max w-full flex justify-center">
          <div className="flex w-full max-w-[900px] xtraxl:max-w-[1200px] flex-col gap-y-6 justify-center">
            <BackButton onClick={() => props.handleStepChangeBackward()} />
            <div className="flex flex-col gap-y-1">
              <p class=" text-[24px] font-semibold leading-normal text-left text-[#0D0D0D]">
                Post a job
              </p>

              <p class="text-base font-normal leading-normal text-left text-[#0D0D0D]">
                Create job with just 2 easy steps.<br />Type in your job title and click “Generate for me”.
              </p>
            </div>

            <div className="tabarea justify-start items-center gap-6 inline-flex w-full flex-shrink-0  overflow-hidden">
              <Tab
                // label="Personal Information"
                label="Job Details"
                current={false}
                completed={true}
                num="1"
              />
              <Tab num="2" label="Employment Details" current={false} completed={true} />
              <Tab num="3" label="Skill Test" current={false} completed={false} />
            </div>

            <form
              className="flex-col justify-start items-start flex w-full"
            >
              <div className="bg-white p-6 flex flex-col gap-y-4 md:gap-y-6 rounded-xl">
                <div className="flex flex-col gap-y-2">
                  <p className="text-[#5843BD] text-[20px] leading-[1.2] font-semibold">{jobDetails?.jobTitle} Skill Test</p>
                  <p className="text-[#4E5161] text-base leading-[1.2] font-medium">Preview and edit the skill test. The correct answers have been checked for you. However, candidates cannot see this. Click on a question or an aswer to edit it</p>

                  {three < 4 && (
                    <div onClick={() => {
                      createSkillTest();
                      setThreetime(prev => prev + 1);
                    }}
                      className="p-4 cursor-pointer rounded-lg bg-[#FF6633] text-white w-max flex items-center gap-x-2.5">
                      <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 9C0 11.3869 0.948211 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18C11.39 18 13.68 17.06 15.4 15.4L13.9 13.9C13.2704 14.5667 12.5107 15.0971 11.6679 15.4583C10.825 15.8196 9.91698 16.0039 9 16C2.76 16 -0.36 8.46 4.05 4.05C8.46 -0.36 16 2.77 16 9H13L17 13H17.1L21 9H18C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9Z" fill="white" />
                      </svg>
                      Generate New Questions
                    </div>
                  )}
                </div>

                <div className="flex flex-col gap-y-4 md:gap-y-6 ">
                  {
                    skillTest?.map((test, i) => {
                      return (
                        <SkillTestItem key={i} i={i + 1} question={test.question} answer={allAnswer} options={test.options} onChangeHandler={onChangeHandler} onChangeHandlerForOptionChecked={onChangeHandlerForOptionChecked} onChangeHandlerForOption={onChangeHandlerForOption} />
                      )
                    })
                  }
                </div>
              </div>
            </form>

            <div className="self-stretch justify-between items-start gap-4 inline-flex">
              {
                <div
                  className="px-3 xsm:px-5 cursor-pointer text-sm sm:text-base py-3 justify-center items-start gap-2.5 flex items-center"
                  onClick={() => props.handleStepChangeBackward(1)}
                >
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 8L17.43 9.393L11.85 15H24V17H11.85L17.43 22.573L16 24L8 16L16 8Z" fill="#FF6633" />
                    <path d="M16 30C18.7689 30 21.4757 29.1789 23.778 27.6406C26.0803 26.1022 27.8747 23.9157 28.9343 21.3576C29.9939 18.7994 30.2712 15.9845 29.731 13.2687C29.1908 10.553 27.8574 8.05845 25.8995 6.10051C23.9416 4.14258 21.447 2.80921 18.7313 2.26901C16.0155 1.72882 13.2006 2.00607 10.6424 3.06569C8.08426 4.12532 5.89776 5.91973 4.35942 8.22202C2.82108 10.5243 1.99999 13.2311 1.99999 16C2.00423 19.7117 3.48059 23.2702 6.10518 25.8948C8.72977 28.5194 12.2883 29.9958 16 30ZM16 4.00001C18.3734 4.00001 20.6934 4.70379 22.6668 6.02237C24.6402 7.34095 26.1783 9.21509 27.0865 11.4078C27.9948 13.6005 28.2324 16.0133 27.7694 18.3411C27.3064 20.6689 26.1635 22.8071 24.4853 24.4853C22.807 26.1635 20.6688 27.3064 18.3411 27.7694C16.0133 28.2325 13.6005 27.9948 11.4078 27.0866C9.21508 26.1783 7.34094 24.6402 6.02236 22.6668C4.70378 20.6935 3.99999 18.3734 3.99999 16C4.0037 12.8185 5.26917 9.76845 7.5188 7.51882C9.76844 5.26918 12.8185 4.00371 16 4.00001Z" fill="#FF6633" />
                  </svg>
                  <span className="text-center text-[#FF6633] text-sm sm:text-base font-semibold">
                    Previous
                  </span>
                </div>
              }
              <button
                onClick={handleSubmit}
                className="px-3 xsm:px-5 py-3 text-sm sm:text-base bg-indigo-700 rounded-lg shadow justify-center items-start gap-2.5 flex items-center"
              >

                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 8L14.57 9.393L20.15 15H8V17H20.15L14.57 22.573L16 24L24 16L16 8Z" fill="white" />
                  <path d="M16 30C13.2311 30 10.5243 29.1789 8.22202 27.6406C5.91973 26.1022 4.12532 23.9157 3.06569 21.3576C2.00607 18.7994 1.72882 15.9845 2.26901 13.2687C2.80921 10.553 4.14258 8.05845 6.10051 6.10051C8.05845 4.14258 10.553 2.80921 13.2687 2.26901C15.9845 1.72882 18.7994 2.00607 21.3576 3.06569C23.9157 4.12532 26.1022 5.91973 27.6406 8.22202C29.1789 10.5243 30 13.2311 30 16C29.9958 19.7117 28.5194 23.2702 25.8948 25.8948C23.2702 28.5194 19.7117 29.9958 16 30ZM16 4.00001C13.6266 4.00001 11.3066 4.70379 9.33316 6.02237C7.35977 7.34095 5.8217 9.21509 4.91345 11.4078C4.0052 13.6005 3.76756 16.0133 4.23058 18.3411C4.69361 20.6689 5.83649 22.8071 7.51472 24.4853C9.19296 26.1635 11.3312 27.3064 13.6589 27.7694C15.9867 28.2325 18.3995 27.9948 20.5922 27.0866C22.7849 26.1783 24.6591 24.6402 25.9776 22.6668C27.2962 20.6935 28 18.3734 28 16C27.9963 12.8185 26.7308 9.76845 24.4812 7.51882C22.2316 5.26918 19.1815 4.00371 16 4.00001Z" fill="white" />
                </svg>

                <span className="text-center text-white text-sm sm:text-base font-semibold whitespace-nowrap">
                  <>
                    Submit Job Post
                  </>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
      {decideModal}
    </>
  )
}
