import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import PricingSection from '../common/pricing_section';
import { useDispatch, useSelector } from "react-redux";
import { getEmployerProfile, returnHome } from "../../Redux/ActionCreator";
import { baseURL } from "../../Redux/baseURL";
import { castArray } from "lodash";
import BackButton from "../common/back_button";
import LoaderModal from "../common/LoaderModal";

export default function SelectPlan() {

  const employer = useSelector(e => e.employer.message);
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false)
  const handleUpgradePlan = async (type, cta, operationType) => {
    console.log("Select type", type, "cta", cta);
    if (employer?.plan !== undefined && (cta == "Select Plan" || cta == "Select Plan")) {
      // if (!stripe || !elements) {
      //   // Stripe.js hasn't yet loaded.
      //   // Make sure to disable form submission until Stripe.js has loaded.
      //   return;
      // }
      const response = async () => {
        setLoad(true)
        fetch(baseURL + "/api/stripe/upgradePlan", {
          method: "PATCH",
          body: JSON.stringify({
            subscriptionPlan: type
          }),
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include"
        }).then(resp => resp.json())
          .then(resp => {
            setLoad(false)
            if (resp.statusCode == 200 && resp.success === true) {
              toast.success("Plan upgraded successfully", {
                position: toast.POSITION.TOP_CENTER,
              });
              console.log("resp", resp);
              setTimeout(() => {
                dispatch(getEmployerProfile()); // wait for the webhook to update the employer profile
                // handleClose("upgrade");
              }, 300);
            } else if (resp.success === false && resp?.message == "Unauthorized") {
              dispatch(returnHome(resp?.status));
            } else if (resp.statusCode == undefined) {
              if (resp?.title == "Error") {
                setTimeout(() => {
                  toast.error(resp.error?.raw?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }, 50);
              }
            }
            return resp;
          }).catch(err => console.log("my err", err));
      }
      if (type == employer?.plan) {
        setTimeout(() => {
          toast.warning("You can't upgrade to the same plan. Thanks", {
            position: toast.POSITION.TOP_CENTER,
          })
        }, 50);
      } else {
        let res = await response();
      }

    } else {
      if (employer?.plan == undefined) {
        handleSubscribe(type, cta, operationType);
      } else {
        setTimeout(() => {
          toast.warning("You have to subscribe to a plan first. Thanks", {
            position: toast.POSITION.TOP_CENTER,
          })
        }, 50);
      }
    }
  }
  const [activePlan, setActivePlan] = useState("");
  const [duration, setDuration] = useState("");

  const handleSubscribe = async (type, cta, operationType) => {
    if (employer?.plan == undefined && (cta == "Sign Up Now" || cta == "Start Free 14-day Trial") && operationType == "subscribe") {
      const response = async () => {
        fetch(baseURL + "/api/stripe/test", {
          method: "POST",
          body: JSON.stringify({
            name: employer?.name,
            email: employer?.email,
            subPlanType: type,
            // coupon: coupon
          }),
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + localStorage.getItem("token"),
          },
          credentials: "include"
        }).then(resp => resp.json())
          .then(resp => {
            if (resp.statusCode == 200 && resp.success === true) {
              window.open(resp.data.url, "_self");
            } else if (resp.success === false && resp?.message == "Unauthorized") {
              dispatch(returnHome(resp?.status));
            } else if (resp.statusCode == undefined) {
              if (resp?.title == "Error") {
                setTimeout(() => {
                  toast.error(resp.error?.raw?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }, 50);
              }
            }
            return resp;
          }).catch(err => console.log("my err", err));
      }
      let res = await response();
    }
  }

  useEffect(() => {
    if (employer?.plan == "basic_monthly") {
      setActivePlan("Basic");
      setDuration("Monthly");
    } else if (employer?.plan == "basic_yearly") {
      setActivePlan("Basic");
      setDuration("Yearly");
    } else if (employer?.plan == "standard_monthly") {
      setActivePlan("Standard");
      setDuration("Monthly");
    } else if (employer?.plan == "standard_yearly") {
      setActivePlan("Standard");
      setDuration("Yearly");
    } else if (employer?.plan == "professional_monthly") {
      setActivePlan("Professional");
      setDuration("Monthly");
    } else if (employer?.plan == "professional_yearly") {
      setActivePlan("Professional");
      setDuration("Yearly");
    }
  }, []);


  return (
    <>
      <div className='h-calc-150 md:h-calc-70 px-4 py-8 overflow-y-scroll flex justify-center'>
        <div className='w-full flex flex-col gap-8 md:px-0 lg:max-w-[1000px] xl:max-w-[1100px] xllarge:max-w-[1200px]'>
          <div className='flex flex-col gap-y-2 xl:flex-row justify-between'>
            <div className="flex flex-col gap-y-1">
              <BackButton onClick={() => window.history.back()} />
              <p className="text-[20px] md:text-[24px] leading-[1.5] font-semibold text-black">
                Select Your Plan
              </p>
              <p className="text-base leading-[1.5] text-black">
                Choose a plan that fits your needs and unlock new features to enhance your experience on Moil. Please select a subscription option below.
              </p>
            </div>

            <div className='px-4 flex justify-center xl:w-max py-3 gap-x-5 flex rounded-lg h-fit dash-link-grad'>
              {employer?.plan === undefined ? (
                <p className='text-white whitespace-nowrap text-center text-base font-medium'>
                  No active plan
                </p>
              ) : <>
                <p className='text-white whitespace-nowrap text-center text-base font-medium'>
                  Active Plan
                </p>
                <p className='text-white whitespace-nowrap text-center text-base font-medium'>
                  {activePlan} <span className='text-base font-normal'>({duration})</span>
                </p>
              </>}
            </div>
          </div>
          <PricingSection handleSubscribe={handleUpgradePlan} type="annually" operationType="subscribe" hide={true} display={true} preShow={employer?.plan} />
        </div>
      </div>
      <ToastContainer />
      {load && <LoaderModal text="Processing Payment..." />}
    </>
  );
}
