import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./Redux/ConfigureStore";
// import { datadogRum } from '@datadog/browser-rum';
import ErrorBoundary from "./ErrorHandler/functionalBoundary";
// Add these lines at the top of your entry file
const buffer = require('buffer');
global.Buffer = buffer.Buffer;

// datadogRum.init({
//   applicationId: 'c9d4a33a-b909-4452-9531-bcc780408f7b',
//   clientToken: 'pub7e08293b7f6621852d3d81e2278052fd',
//   // `site` refers to the Datadog site parameter of your organization
//   // see https://docs.datadoghq.com/getting_started/site/
//   site: 'datadoghq.com',
//   service: 'business',
//   env: process.env.NODE_ENV || 'production',
//   // Specify a version number to identify the deployed version of your application in Datadog
//   version: '1.0.0',
//   sessionSampleRate: 100,
//   sessionReplaySampleRate: 20,
//   trackUserInteractions: true,
//   trackResources: true,
//   trackLongTasks: true,
//   defaultPrivacyLevel: 'mask-user-input',
// });

// datadogRum.startSessionReplayRecording(); // Optional: to record user sessions

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <HelmetProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
    </HelmetProvider> 
  </ErrorBoundary>
);
