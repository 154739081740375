export const convertStringToArray = (str) => {
    let newStr; 
    let returnedStr = new Array();
    if (typeof str == "string") {
        // newStr = str.replace(/\n/g, "").split(".");
        newStr = str.split(/[\n.]/);
        // console.log("newStr", newStr);
        if (newStr[newStr.length - 1].length == 0) {
            newStr.pop();
        }
        for (let i = 0; i <= newStr.length - 1; i++) {
            returnedStr.push(newStr[i].replace(/[^\w\s]/g, ""));
        }
    }
    return returnedStr;
}

export const correctJobSkills = (value) => {
    if (value !== undefined) {
        let returnedvalue = value[0].split(/,|\.\s|\t|\n/);
        return returnedvalue;
    }
}

export const convertStringToHtmlWithBr = (str) => {
    if (typeof str === "string") {
        // Replace all newlines with <br> and add <br> after full stops.
        const newStr = str.replace(/\n/g, "<br>") //.replace(/\.\s*/g, ".<br>");
        return newStr;
    }
    return str;
};

// export const convertHtmlBrToCommaArray = (htmlString) => {
//     // console.log(" typeof htmlString",  htmlString);
//     if (typeof htmlString === "string") {
//         // Use a regex to match all <li>...</li> elements and capture their contents.
//         const liArray = htmlString.match(/<li[^>]*>(.*?)<\/li>/gi);
//         return liArray || []; // Return the array or an empty array if no matches found
//     }
//     return [];
// };

export const convertHtmlBrToCommaArray = (htmlString) => {
    // console.log(" typeof htmlString", htmlString);
    if (typeof htmlString === "string") {
        // Use a regex to match all <p>...</p> and <li>...</li> elements and capture their contents.
        const matches = htmlString.match(/<(p|li|em|strong)[^>]*>(.*?)<\/\1>/gi);
        return matches || []; // Return the array or an empty array if no matches found
    }
    return [];
};
