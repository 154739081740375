import React, { useEffect, useState } from "react"
import { Document, Page, StyleSheet, View, Text, PDFViewer, Svg, Line, Image, Link, Circle } from '@react-pdf/renderer';


// require fonts


import font from "./font/poppins-v20-latin-600.ttf";
import font1 from "./font/poppins-v20-latin-300.ttf";
import { Font } from '@react-pdf/renderer';

Font.register({ family: 'Poppins', src: font1});
Font.register({ family: 'Poppins-bold', src: font});


// require useSelector from react-redux
import { useSelector } from "react-redux";


const styles = StyleSheet.create({
  page: {
    fontFamily: "Inter-regular",
    display: "flex",
    flexDirection: "row",
    paddingTop: 40,
    paddingBottom: 30,
  },
  viewer: {
    width: '65vw', height: '100vh',
    '@media maxWidth: 500' : {
      width: "50vw", height: "auto",
      overFlow: "scroll"
    }
  },
  left_view: {
/*     backgroundColor: "#FF6634", */

    backgroundColor: "#FFFFFF",
    width: "40vw",
    paddingBottom: 30,
    paddingTop: 10,
    paddingLeft: 30,
    color: "#233A5F"

  },
  right_view: {
    backgroundColor: "#FFFFFF",
    color: "#233A5F",
    width: "65vw",
    marginTop: 20,
    paddingRight: 30,
    marginLeft: 30,
  },
  HeaderText: {
    fontFamily: "Poppins",
    fontSize: 10,
    align: "Left",
    verticalAlign: "Top",
    width: 200,
    height: "15px",
    color: "#233A5F",
/*     color: "#FF6633" */
  },
  educatintext: {
    fontFamily: "Poppins",
    fontSize: 8,
    paddingTop: 0,
    align: "Left",
    verticalAlign: "Top",
    width: 180,
    color: "#233A5F",
  },
});


function BlackEagle(props) {
  // console.log("props", props.details);
  let details;
  details = [props.details]
  const [ wrap_stat, setWrap_stat ] = useState(false);
  useEffect(() => {
    if (details[0].Experience.length >= 4) {
      setWrap_stat(true);
    } else {
      setWrap_stat(false);
    }
  }, [])

  let my_interest;
  if (details[0] !== null) {
    if (Array.isArray(details[0]?.Interest)) {
      my_interest = details[0]?.Interest;
    } else if (typeof details[0]?.Interest === "string") {
      my_interest = details[0]?.Interest.split(", ");
    }
  }
  if (Array.isArray(details[0]?.Technical_skills) === false ) {
    if (typeof details[0]?.Technical_skills === "string") {
      details[0].Technical_skills = details[0]?.Technical_skills.split(", ");
    }
   }

   if (Array.isArray(details[0]?.Personal_skills) === false ) {
    if (typeof details[0]?.Personal_skills === "string") {
      details[0].Personal_skills = details[0]?.Personal_skills.split(", ");
    }
   }


   const chunkSubstr = (str, size) => {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }

    return chunks;
  };

  Font.registerHyphenationCallback((word) => { // to avoid breaking of words.
    if (word.length > 16) {
      return chunkSubstr(word, 14);
    } else {
      return [word];
    }
  });

  
  return (
    <Document style={styles.page}>
      <Page size="A4" style={styles.page} wrap={wrap_stat}>
            {/* map the user's info to the CV */}
            { details?.map((data, key) => (
                <View style={{ display: "flex", flexDirection: "row" }} key={key}>
                  <View style={styles.left_view}>
                  {/* header start */}
                  <View style={{marginBottom: 10,}}>
                    <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                      <Text style={{color: "#233A5F", fontFamily: "Poppins-bold", fontSize: 18}} >{data.Fullname}</Text>
                    </View>
                    
                    {data.CvType === null || data.CvType === undefined ? null : <View>
                        <Text style={{fontSize: 10}} key={key}>{data.CvType}</Text>
                    </View>
                    }
                  </View>
                  <View style={{borderRadius: "12px", backgroundColor: "#E5E5E5", width: "186px", padding: "4px 8px", alignItems: "flex-start", display: "flex", flexDirection: "row", height: "23px", marginBottom: 5}}>
                    <Text style={styles.HeaderText}>{data.Phone_number}</Text>
                  </View>
                  <View style={{borderRadius: "12px", backgroundColor: "#E5E5E5", width: "186px", padding: "4px 8px", alignItems: "flex-start", display: "flex", flexDirection: "row", height: "23px", marginBottom: 5}}>
                    <Text style={styles.HeaderText}>{data.Email}</Text>
                  </View>
                  <View style={{borderRadius: "12px", backgroundColor: "#E5E5E5", width: "186px", padding: "4px 8px", alignItems: "flex-start", display: "flex", flexDirection: "row", height: "23px", marginBottom: 5}}>
                    <Text style={styles.HeaderText}>{data.Address}</Text>
                  </View>
                  {/* header end */}
    
                  {/* skills section start */}
                  {
                    data.Technical_skills?.length === 0 && data.Personal_skills?.length === 0 ? null :
                    <View style={{ marginLeft: 0, marginRight: 10, marginTop: 10}}>
                    {
                      data.Lang[0]?.lang === 'English' ? 
                      <Text style={{letterSpacing: 2, fontFamily: "Poppins-bold", fontSize: "12px"}}>Skills</Text> : <Text style={{letterSpacing: 2, fontFamily: "Poppins-bold", fontSize: "12px"}}>Habilidades</Text>
                    }
                    <View style={{marginTop: 5}}>
                      {/* map skills */}
                      { data.Technical_skills?.length === 0 ? null : 
                        <View>
                        {
                        data.Lang[0]?.lang === 'English' ? 
                        <Text style={{fontFamily: "Poppins-bold", fontSize: 10}}>Technical Skills</Text> : <Text style={{fontFamily: "Poppins-bold", fontSize: 10}}> Habilidades Técnicas </Text> 
                        }
                          { data.Technical_skills?.map((T_data, key) => (
                            <View style={{display: "flex", flexDirection: "row", marginTop: 2}} key={key}>
                              <View style={{marginRight: 5}}>
                                <Svg width="5" height="5" viewBox="0 0 100 100" style={{marginTop: 4}}>
                                  <Circle 
                                    cx='60'
                                    cy="60"
                                    r="40"
                                    fill='#233A5F'
                                  />
                                </Svg>
                              </View>
                              <View style={{display: "flex", alignItems: "flex-start"}}>
                                <Text style={styles.educatintext}>
                                  {T_data.value !== undefined ? <Text>{T_data.value}</Text> : <Text>{T_data}</Text>}
                                </Text>
                              </View>
                            </View>
                          ))}
                        </View>
                      }
                      {
                        data.Personal_skills?.length === 0 ? null : 
                        <View>
                          {
                          data.Lang[0]?.lang === 'English' ? 
                          <Text style={{fontFamily: "Poppins-bold", fontSize: 10, marginTop: 5}}>Personal Skills</Text> : <Text style={{fontFamily: "Poppins-bold", fontSize: 10, marginTop: 5}}>Habilidades personales</Text>
                          }
                          { data.Personal_skills?.map((P_data, key) => (
                            <View style={{display: "flex", flexDirection: "row", alignItems: "center"}} key={key}>
                              <View style={{marginRight: 5}}>
                                <Svg width="5" height="5" viewBox="0 0 100 100" style={{marginTop: 0}}>
                                  <Circle 
                                    cx='60'
                                    cy="60"
                                    r="40"
                                    fill='#233A5F'
                                  />
                                </Svg>
                              </View>
                              <View style={{display: "flex", alignItems: "flex-start"}}>
                                <Text style={styles.educatintext}>
                                  {P_data.value !== undefined ? <Text>{P_data.value}</Text> : <Text>{P_data}</Text>}
                                </Text>
                              </View>
                            </View>
                          ))}
                        </View>
                      }
                    </View>
                  </View>
                  }
                  {/* skills section end */}
    
                  {/* Honor & Award section start */}
                  {
                    data.Award?.length !== 0 && data.Award[0]?.Award_name.length !== 0 ?  
                    <View style={{marginLeft: 0, marginRight: 10, marginTop: 10}}>
                      {data.Award[0]?.Award_name === null ? null : 
                      <View>
                          {
                          data.Lang[0]?.lang === 'English' ? 
                          <Text style={{marginBottom: 0, fontFamily: "Poppins-bold", fontSize: "12px"}}>Honor & Award</Text> : <Text style={{marginBottom: 0, fontFamily: "Poppins-bold", fontSize: "12px"}}>Honor y Premio</Text> 
                          }
                        {/* map honor and award */}
                        { data.Award?.map((H_data, key) => (
                          <View style={{marginTop: 5}} key={key}>
                            <View style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: 185}}>
                              <View><Text style={{ fontFamily: "Poppins-bold", fontSize: "10px"}}>{H_data.Award_name}</Text></View>
                              <View style={{ fontFamily: "Poppins", fontSize: "8px"}}><Text>{H_data.Award_year}</Text></View>
                            </View>
                            <View style={{marginTop: 2}}>
                              <Text style={{ fontFamily: "Poppins", fontSize: "8px", width: 180}}>{H_data.Award_giver}</Text>
                            </View>
                          </View>
                        ))}</View>}
                    </View>  : null
                  }
                  {/* Honor & Award section end */}
    
                  {/* Links and Credentials section start */}
                  { data.Linkedin?.length === 0 && data.Website?.length === 0 ? null :
                    <View>
                    {
                    data.Lang[0]?.lang === 'English' ? 
                    <Text style={{marginTop: 10, fontFamily: "Poppins-bold", fontSize: "12px"}}>Links and Credentials</Text> : <Text style={{marginTop: 10, fontFamily: "Poppins-bold", fontSize: "12px"}}>Enlaces y Credenciales </Text>
                    }
                  <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: 10}}>

                    {data.Linkedin?.length !== 0 ?
                    <View style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight:10 }}>
                    <View style={{width: 10, height: 10, backgroundColor: "#FFFFFF", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: 2, marginRight: 5}}>
                      <Image src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704121/Website%20images/icons8-linkedin-30_2_gowpgi.png" style={{ borderRadius: "50%", width: 10, height: 10}}/>
                    </View>
                    <View>
                      <Text style={{fontFamily: "Poppins", fontSize: "8px"}}><Link src={data.Linkedin} style={{ textDecoration: "underline", color: "#233A5F"}}>{data.Linkedin}</Link></Text>
                    </View>
                  </View> : null}
  

                      { data.GitHub === undefined ? null : 
                          <View style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight:10 }}>
                            <View style={{width: 10, height: 10, display: "flex", flexDirection: "row", justifyContent: "center", marginRight: 5}}>
                              <Image src='https://res.cloudinary.com/drlcisipo/image/upload/v1705704117/Website%20images/icons8-github-24_abcpwl.png' style={{ width: 10, height: 10}}/>
                            </View>
                            <View>
                              <Text style={{fontFamily: "Poppins", fontSize: "8px"}}><Link src={data.GitHub} style={{ textDecoration: "underline", color: "#FFFFFF"}}>GitHub</Link></Text>
                            </View>
                          </View>
                      }

                      { data.instagram === undefined ? null :
                        <View style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight:10 }}>
                        <View style={{width: 15, height: 15,  display: "flex", flexDirection: "row", justifyContent: "center", marginRight: 5}}>
                          <Image src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704118/Website%20images/icons8-instagram-48_svbwqk.png" style={{  width: 15, height: 15}}/>
                        </View>
                        <View>
                          <Text style={{fontFamily: "Poppins", fontSize: "8px"}}><Link src={data.Instagram} style={{ textDecoration: "underline", color: "#FFFFFF"}}>Instagram</Link></Text>
                        </View>
                      </View>
                      }

                      { data.Website?.length === 0 ? null : 
                          
                        <View style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight:10 }}>
                        <View style={{width: 10, height: 10, display: "flex", flexDirection: "row", justifyContent: "center", marginRight: 5}}>
                          <Image src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704129/Website%20images/icons8-share-64_yjbdtm.png" style={{ width: 10, height: 10}}/>
                        </View>
                        <View>
                          <Text style={{fontFamily: "Poppins", fontSize: "8px"}}><Link src={data.Website} style={{ textDecoration: "underline", color: "#233A5F"}}>{data.Website}</Link></Text>
                        </View>
                      </View>
                      }
                  </View>
                </View>
                }
                  {/* Links and Credentials section end */}
                  
                  {/* Language section start */}
                  { data.Language?.length === 0 ? null : 
                  <View style={{marginLeft: 0, marginRight: 10, marginTop: 5}}>
                      {
                      data.Lang[0]?.lang === 'English' ? 
                      <Text style={{fontFamily: "Poppins-bold", fontSize: 12}}>Languages</Text> : <Text style={{fontFamily: "Poppins-bold", fontSize: 12}}>Idiomas</Text> 
                      }
                    <View>
                    { data.Language?.map((L_data, key) => (
                      <View style={{display: "flex", flexDirection: 'row', width: 150}} key={key}>
                        <Text style={{fontFamily: "Poppins", fontSize: 8, marginTop: 5, marginRight: 3}}>{L_data.language} -</Text>
                        <Text style={{fontFamily: "Poppins", fontSize: 8, marginTop: 5}}>{L_data.rate}</Text>
                      </View>
                    ))}
                    </View>
                  </View>
                  }
                  {/* Language section end */}
                </View>
                {/* Left side end */}
    
                {/* Right side start */}
                <View style={styles.right_view}>
                  {/* About section start */}
                  <View>
                    {
                    data.Lang[0]?.lang === 'English' ?
                    <Text style={{letterSpacing: 2, fontFamily: "Poppins-bold", fontSize: 12, marginLeft: 0, marginTop: 0}}>About</Text> : <Text style={{letterSpacing: 2, fontFamily: "Poppins-bold", fontSize: 12, marginLeft: 0, marginTop: 0}}>Sobre</Text>
                    }
                    <View style={{marginTop: 2, marginLeft: 2}}>
                      <Svg height="2" width="30">
                        <Line x1="0"
                          y1="0"
                          x2="100"
                          y2="0"
                          strokeWidth={3}
                          stroke="#233A5F" 
                        />
                      </Svg>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 330, marginLeft: 0, marginTop: 10}} >
                        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                            <Text style={{ marginTop: 0, fontFamily: "Poppins",  fontSize: 8}} >{data.Professional_summary} </Text>
                        </View>
                    </View>
                  </View>
                  {/* About section end */}
    
                  {/* Education section start */}
                  { Array.isArray(data.Education) === false ? null : 
                    <View style={{marginTop: 5}}>
                      { data.Education?.length === 0 ? null : 
                      <View>
                        <View>
                        {
                          data.Lang[0]?.lang === 'English' ?
                          <Text style={{letterSpacing: 2, fontFamily: "Poppins-bold", fontSize: 12, marginLeft: 0, marginTop: 0}}>Education</Text> : <Text style={{letterSpacing: 2, fontFamily: "Poppins-bold", fontSize: 12, marginLeft: 0, marginTop: 0}}>Educación</Text>
                        }
                        <View style={{marginTop: 5, marginLeft: 0}}>
                          <Svg height="2" width="30">
                            <Line x1="0"
                              y1="0"
                              x2="100"
                              y2="0"
                              strokeWidth={3}
                              stroke="#233A5F" 
                            />
                          </Svg>
                        </View>
                      </View>
                      {/* map the Education */}
                      {
                        data.Education?.map((E_data, key) => (
                          <View style={{display: "block", marginLeft: 0, marginTop: 5}}>
                            <View style={{width: 330, display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                              {E_data.End_date === null ? <Text style={{fontFamily: "Poppins", fontSize: "10px", marginLeft: 3}}>Present</Text> : <Text style={{fontFamily: "Poppins", fontSize: "10px", }}>{E_data.End_date}</Text>}
                            </View>
                            <View style={{display: "flex", flexDirection: "row", marginTop: 5 }}>
                              <View style={{marginLeft: 0,}}>
                              <Svg width="20" height="20" viewBox="0 0 400 400" style={{marginLeft: 0}}>
                                <Circle
                                  cx='200'
                                  cy="200"
                                  r="150"
                                  fill='#E5E5E5'
                                />
                              </Svg>
                            </View>
                            <View>
                              <View  style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 330, marginLeft: 10, }} >
                                  <Text style={{fontFamily: "Poppins-bold", fontSize: 10}}>{E_data.School} </Text>
                              </View>
                                <View  style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 330, marginLeft: 10, }} >
                                    <Text style={{ fontFamily: "Poppins", fontSize: 8}} key={key}>{E_data.Degree} {E_data.Course}</Text>
                                </View>
                              </View>
                            </View>
                          </View>
                        ))
                      }
                      </View>
                      }
                  </View>
                  }
                  {/* Education section end */}
    
                  {/* Experience section start */}
                  { data.Experience?.length === 0 ? null : 
                    <View style={{marginTop: 10,}}>
                    <View style={{width: 10, position: "relative"}}>
                        {
                          data.Lang[0]?.lang === 'English' ?
                          <Text style={{letterSpacing: 2, fontFamily: "Poppins-bold", fontSize: 12, marginLeft: 0, marginTop: 0, width: 100}}>Experience</Text> : <Text style={{letterSpacing: 2, fontFamily: "Poppins-bold", fontSize: 12, marginLeft: 0, marginTop: 0, width: 100}}>Experiencia </Text>
                        }
                      <View style={{marginTop: 5, marginLeft: 2}}>
                        <Svg height="2" width="30">
                          <Line x1="0"
                            y1="0"
                            x2="100"
                            y2="0"
                            strokeWidth={3}
                            stroke="#233A5F" 
                          />
                        </Svg>
                      </View>
                    </View>
                    {/* map Experience */}
                    <View>
                    { data.Experience?.map((Ex_data, key) => (
                      <View style={{display: "block",  marginLeft: 0,  marginTop: 10}}>
                        {/* <View style={{width: 67, display: "flex", justifyContent: "center", alignItems: "center", height: "auto" }}>
                          <Text style={{fontFamily: "Poppins", fontSize: "10px", width: 60}}></Text>
                        </View> */}
                        <View style={{display: "flex", flexDirection: "row" }}>
                          <View style={{marginLeft: 0}}>
                            <Svg width="20" height="20" viewBox="0 0 400 400" style={{marginLeft: 0}}>
                              <Circle 
                                cx='200'
                                cy="200"
                                r="150"
                                fill='#E5E5E5'
                              />
                            </Svg>
                          </View>
                        <View>
                          <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap", width: 300,  marginLeft: 10}}>
                              <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                                  <Text style={{ fontFamily: "Poppins-bold",  fontSize: 10}}>{Ex_data.Where_i_worked} </Text>
                              </View>
                          </View>
                          <view style={{display: "flex", flexDirection: "row", width: 330, marginLeft: 10}}>
                            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 190,  }} >
                                <Text style={{fontFamily: "Poppins", fontSize: 8,}} >{Ex_data.My_job_title} </Text>
                            </View> <Text style={{marginLeft: 3,fontFamily: "Poppins", fontSize: 10}}>  </Text>
                          <Text style={{marginLeft: 3,fontFamily: "Poppins-bold", fontSize: 8, width: 140}}>{Ex_data.Work_start_date} - {Ex_data.Work_end_date !== null ? <Text style={{marginLeft: 3,fontFamily: "Poppins-bold", fontSize: 8 }}>{Ex_data.Work_end_date}</Text> : <Text style={{marginLeft: 3,fontFamily: "Poppins-bold", fontSize: 8 }}>{data.Lang[0].lang === 'English' ? <Text style={{marginLeft: 3,fontFamily: "Poppins-bold", fontSize: 8 }}>Present</Text> : <Text style={{marginLeft: 3,fontFamily: "Poppins-bold", fontSize: 8 }}>Presente</Text>}</Text>}</Text></view>

                          { Array.isArray(Ex_data.Day_to_day_work) === true ? 
                            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap",  width: 280, marginTop: 5, marginLeft: 10 }}>
                              {Ex_data.Day_to_day_work?.map((ex_data, key) => (
                                <View style={{display: "flex", flexDirection: "row",  }} key={key}>
                                  <View>
                                    <Text style={{ marginRight: 8, marginTop: -5 }}>•</Text>
                                  </View>
                                  <View style={{  display: "flex", flexDirection: "row", flexWrap: "wrap",  }}>
                                    {ex_data[0] === "-" ? <Text style={{ marginTop: 0, fontFamily: "Poppins",fontSize: 8 }} >{ex_data.slice(1, ex_data.length)}</Text> : <Text style={{ marginTop: 0, fontFamily: "Poppins",fontSize: 8 }} >{ex_data.replace(/^(\d[^\w\s]*|[^\w\s])+/g, '')}</Text> }
                                  </View>
                                </View>
                            ))}
                            </View> : <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap", width: 300, marginTop: 5 }}>
                                <Text style={{marginLeft: 10, marginTop: 5, fontFamily: "Poppins", fontSize: 8 }}>{Ex_data.Day_to_day_work.replace(/^(\d[^\w\s]*|[^\w\s])+/g, '')} </Text>
                            </View>
                            }
                          </View>
                        </View>
                      </View>
                    ))}
                    </View>
                  </View>
                  }
                  {/* Experience section end */}

                  {/* Reference start */}
                  { Array.isArray(data.Reference) === false ? null : 
                  <View style={{marginTop: 10}}>
                    { data.Reference?.length !== 0 && data.Reference[0]?.Reference_person_name.length !== 0 ? 
                    <View>
                      <View>
                      {
                        data.Lang[0]?.lang === 'English' ?
                        <Text style={{letterSpacing: 2, fontFamily: "Poppins-bold", fontSize: 12, marginLeft: 0, marginTop: 0}}>Reference</Text> : <Text style={{letterSpacing: 2, fontFamily: "Poppins-bold", fontSize: 12, marginLeft: 0, marginTop: 0}}>Referencia</Text>
                      }
                      <View style={{marginTop: 5, marginLeft: 2}}>
                        <Svg height="2" width="30">
                          <Line x1="0"
                            y1="0"
                            x2="100"
                            y2="0"
                            strokeWidth={3}
                            stroke="#233A5F" 
                          />
                        </Svg>
                      </View>
                    </View>
                    {/* map the Education */}
                    {
                      data.Reference?.map((R_data, key) => (
                        <View style={{display: "block", marginLeft: 0, marginTop: 10 }} key={key} >
                        <View style={{display: "flex", flexDirection: "row" }}>
                          <View style={{marginLeft: 0}}>
                            <Svg width="20" height="20" viewBox="0 0 400 400" style={{marginLeft: 0}}>
                              <Circle
                                cx='200'
                                cy="200"
                                r="150"
                                fill='#E5E5E5'
                              />
                            </Svg>
                          </View>
                          <View>
                            <Text style={{marginLeft: 10,fontFamily: "Poppins-bold", width: 220, fontSize: 8}}>{R_data.Reference_person_name}</Text>
                            <Text style={{marginLeft: 10,fontFamily: "Poppins", width: 220, fontSize: 8}}>{R_data.Referencee_job_title}</Text>
                            <Text style={{marginLeft: 10,fontFamily: "Poppins", width: 220, fontSize: 8}}>{R_data.How_do_i_know_this_person}</Text>
                            <Text style={{marginLeft: 10,fontFamily: "Poppins", width: 220, fontSize: 8}}>{R_data.Reference_email}</Text>
                            {/* <Text style={{marginLeft: 10,fontFamily: "Poppins", width: 220, fontSize: 8}}>{R_data.Reference_phone_number}</Text> */}
                          </View>
                        </View>
                      </View>
                      ))
                    }
                    </View> : null}
                </View>
                  }
                  {/* Reference end */}

                  {/* Volunteer start */}
                  {/* <View style={{margin: 10}}> 
                  { data.Voluteer_org.length === 0 ? null : 
                  <View>
                  <View>
                  {
                    data.Lang[0].lang === 'English' ?
                    <Text style={{letterSpacing: 2, fontFamily: "Poppins-bold", fontSize: 12, marginLeft: 20, marginTop: 0}}>Volunteer</Text> : <Text style={{letterSpacing: 2, fontFamily: "Poppins-bold", fontSize: 12, marginLeft: 20, marginTop: 0}}> Voluntario </Text>
                  }
                  <View style={{marginTop: 5, marginLeft: 22}}>
                    <Svg height="2" width="30">
                      <Line x1="0"
                        y1="0"
                        x2="100"
                        y2="0"
                        strokeWidth={3}
                        stroke="#233A5F" 
                      />
                    </Svg>
                  </View>
                </View>

                { Array.isArray(data.Volunteer) === true ? 
                <View>
                  {
                  data.Volunteer?.map((V_data, key) => (
                    <View style={{display: "flex", flexDirection: "row", marginLeft: 20, margin: 10}} key={key}>
                    <View style={{display: "flex", flexDirection: "row", alignItems: "center", width: 70}}>
                      <Text style={{fontFamily: "Poppins", fontSize: "10px", width: 30}}>{V_data.Volunteer_join_date} -</Text>{V_data.Volunteer_end_date === null ? <Text style={{fontFamily: "Poppins", fontSize: "10px", width: 30, marginLeft: 5}}>Present</Text> : <Text style={{fontFamily: "Poppins", fontSize: "10px", width: 30}}>{V_data.Volunteer_end_date}</Text>}
                    </View>
                    <View style={{display: "flex", flexDirection: "row" }}>
                      <View style={{marginLeft: 15}}>
                      <Svg width="20" height="20" viewBox="0 0 400 400" style={{marginLeft: 15}}>
                        <Circle
                          cx='200'
                          cy="200"
                          r="150"
                          fill='#E5E5E5'
                        />
                      </Svg>
                    </View>
                    <View>
                      <Text style={{marginLeft: 10,fontFamily: "Poppins", width: 220, fontSize: 10}}>{V_data.Volunteer_Org}</Text>
                      <Text style={{marginLeft: 10,fontFamily: "Poppins-bold", width: 220, fontSize: 10}}>{V_data.Membership_level}</Text>
                    </View>
                    </View>
                  </View>
                  ))
                }
                </View> :
              <View style={{display: "flex", flexDirection: "row", marginLeft: 20, margin: 10}} key={key}>
                  <View style={{ width: 70}}>
                    <Text style={{fontFamily: "Poppins", fontSize: "10px", width: 30}}>{data.Volunteer_join_date} -</Text>{data.Volunteer_end_date === null ? <Text style={{fontFamily: "Poppins", fontSize: "10px", width: 30, marginLeft: 0}}>Present</Text> : <Text style={{fontFamily: "Poppins", fontSize: "10px", width: 30}}>{data.Volunteer_end_date}</Text>}
                  </View>
                  <View style={{display: "flex", flexDirection: "row" }}>
                    <View style={{marginLeft: 15}}>
                    <Svg width="20" height="20" viewBox="0 0 400 400" style={{marginLeft: 15}}>
                      <Circle
                        cx='200'
                        cy="200"
                        r="150"
                        fill='#E5E5E5'
                      />
                    </Svg>
                  </View>
                  <View>
                    <Text style={{marginLeft: 10,fontFamily: "Poppins", width: 220, fontSize: 10}}>{data.Volunteer_Org}</Text>
                    <Text style={{marginLeft: 10,fontFamily: "Poppins-bold", width: 220, fontSize: 10}}>{data.Membership_level}</Text>
                  </View>
                  </View>
                </View>
                }
                </View>
                }
                  </View> */}
                  {/* Volunteer end */}
                </View>
                {/* Right side end */}
              </View>
            ))}

            </Page>
          </Document>
  )
}

export default BlackEagle