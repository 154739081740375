import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { createPortal } from 'react-dom';
import UpgradeConfirm from '../../common/confirm_upgrade'; // Import the modal component
import SubscriptionSuccess from '../subscription_success';
import LoaderModal from '../LoaderModal';
import { getExpectedAmountToPay } from '../../../utils/subscriptionHelper/getExpectedAmount';
import CancelSubscriptionModal from "../../common/cancel_subscription"
import { useNavigate } from "react-router-dom";
import { baseURL, baseURL1 } from "../../../Redux/baseURL";
import { useDispatch, useSelector } from "react-redux";
import { getEmployerProfile, returnHome, SiteError } from "../../../Redux/ActionCreator";
import { ToastContainer, toast } from "react-toastify";
import PaymentFailedModal from '../paymentFailed';
import { data } from 'jquery';
import { useStripe } from '@stripe/react-stripe-js';

export default function PreviewPricingItem({ benefits, plan, price, cta, preShow, click, display, operationType, percentOff }) {
  // console.log("cta", cta);
  const dispatch = useDispatch();
  const employer = useSelector(state => state.employer.message);
  
  const stripe = useStripe();
  let [ text, setText ] = useState("Getting the upgrade fee...");


  let [load, setLoad] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subscriptionSuccess, setSubscriptionSucess] = useState(false);

  let planType;

  if (plan === "BASIC" && preShow === "monthly") {
    planType = "basic_monthly";
  } else if (plan === "BASIC" && preShow === "annually") {
    planType = "basic_yearly";
  } else if (plan === "STANDARD" && preShow === "monthly") {
    planType = "standard_monthly";
  } else if (plan === "STANDARD" && preShow === "annually") {
    planType = "standard_yearly";
  } else if (plan === "PROFESSIONAL" && preShow === "monthly") {
    planType = "professional_monthly";
  } else if (plan === "PROFESSIONAL" && preShow === "annually") {
    planType = "professional_yearly";
  }

  const [ paymentFailed, setPaymentFailed ] = useState(false);
  useEffect(() => {
    if (employer?.paymentStatus == "success" && load == true) {
      setLoad(false);
      setSubscriptionSucess(true);
    } else if (employer?.paymentStatus == "failed" && load == true) {
      setLoad(false);
      setPaymentFailed(true);
    }
  }, [employer]);

  const [amountToPay, setAmoutToPay] = useState(null);
  const handleClick = async () => {
    // console.log("called to subscribe");
    // console.log("CTA", cta);
    if (cta === "Current Plan") {
      setTimeout(() => {
        toast.warning("You selected your current plan. You can upgrade your plan by selecting other others", {
          position: toast.POSITION.TOP_CENTER,
        })
      }, 50);
      return;
    }

    try {
      if (cta === "Upgrade Plan") {
        // before this, you get the expected amount to pay
        setLoad(true);
        setText("Getting the upgrade fee...");
        let expectedAmount = await getExpectedAmountToPay(planType);
        expectedAmount = await expectedAmount.json();
        // console.log("expectedAmount", expectedAmount);
        if (expectedAmount) {
          setLoad(false);
          setAmoutToPay(expectedAmount.data);
          // click(planType, cta, operationType); // Call the click function when confirming the upgrade
          setIsModalOpen(true); // Open the modal when the upgrade plan button is clicked
        }
      } else {
        // click(planType, cta, operationType);
        if (cta == "Sign Up Now") {
          // console.log("CTA se", cta);
          handleModalAction();
          // setIsModalOpen(true); // Open the modal when the upgrade plan button is clicked
        } else {
          setIsModalOpen(true); // Open the modal when the upgrade plan button is clicked
        }
      }
    } catch (error) {
      setLoad("false");
    }
  };

  const handleModalAction = () => {
    // click(planType, cta, operationType); // Call the click function when confirming the upgrade
    if (cta == "Upgrade Plan") {
      handleUpgradePlan(planType, "upgrade");
    } else if (cta == "Downgrade Plan") {
      // set a state for the downgrade modal.
      handleUpgradePlan(planType, "downgrade");
    } else if (cta == "Sign Up Now" || cta == "Start Free 14-day Trial" || cta == "Start Free 30-day Trial") {
      setLoad(true);
      handleSubscribe(planType, cta, operationType);
    }
    if (cta !== "Sign Up Now") {
      setIsModalOpen(false); // Close the modal after action
      // setSubscriptionSucess(true);
    }
  };

  const handleSubscribe = async (planType, cta, operationType) => {
    // console.log("type", planType, "cta", cta, "operationType", operationType);

    if ((employer?.plan == undefined || (employer?.plan !== undefined && employer?.cancelStatus == true)) && (cta == "Sign Up Now" || cta == "Start Free 14-day Trial" || cta == "Start Free 30-day Trial") && operationType == "subscribe") {
      // if (!stripe || !elements) {
      //   // Stripe.js hasn't yet loaded.
      //   // Make sure to disable form submission until Stripe.js has loaded.
      //   return;
      // }
      const response = async () => {
        fetch(baseURL + "/api/stripe/test", {
          method: "POST",
          body: JSON.stringify({
            name: employer?.name,
            email: employer?.email,
            subPlanType: planType,
            // coupon: coupon
          }),
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + localStorage.getItem("token"),
          },
          credentials: "include"
        }).then(resp => resp.json())
          .then(resp => {
            // console.log("response", resp);
            if (resp.statusCode == 200 && resp.success === true) {
              setLoad(false);
              window.open(resp.data.url, "_self");
            } else if (resp.success == false && resp.message == "Token expired") {
              dispatch(returnHome(resp?.status));
            } else if (resp.success === false && resp?.message == "Unauthorized") {
              dispatch(returnHome(resp?.status));
            } else if (resp.statusCode == undefined) {
              if (resp?.title == "Error") {
                setTimeout(() => {
                  toast.error(resp.error?.raw?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }, 50);
              }
            }
            return resp;
          }).catch(err => {
            // console.log("my err", err)
          });
      }
      let res = await response();
    }
  }

  const handleUpgradePlan = async (planType, typeOfAction) => {
    // // console.log("upgrade type", type);
    if (employer.plan !== undefined) {
      // if (!stripe || !elements) {
      //   // Stripe.js hasn't yet loaded.
      //   // Make sure to disable form submission until Stripe.js has loaded.
      //   return;
      // }
      // const stripe = useStripe();
      setText("Processing...");
      const response = async () => {
        setLoad(true)
        fetch(baseURL + "/api/stripe/upgradePlan", {
          method: "PATCH",
          body: JSON.stringify({
            subscriptionPlan: planType,
            type: typeOfAction
          }),
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include"
        }).then(resp => resp.json())
          .then(async resp => {
            // setLoad(false)
            // console.log("Upgrade response", resp);
            if (resp.statusCode == 200 && resp?.data.success === true) {
              // console.log("resp", resp);
              // dispatch(getting_employer_profile_successful(resp.data));
              setTimeout(() => {
                // console.log("set time out profile call was called!!")
                dispatch(getEmployerProfile()); // wait for the webhook to update the employer profile
                close("cancel");
              }, 300);
            } else if (resp?.data.requiresAction && resp?.data.clientSecret) {
              setText(`${resp?.data.message}. Please, wait for your bank OTP so you can authenticate your account for the payment.`);
              const { error, paymentIntent } = await stripe.confirmCardPayment(resp.data.clientSecret);

              if (error) {
                  console.error('Authentication failed:', error.message);
                  setLoad(false);
                  setPaymentFailed(true);
                  // toast.error("Payment authentication failed. Please try again.", { position: toast.POSITION.TOP_RIGHT });
              } else if (paymentIntent && paymentIntent.status === 'succeeded') {
                  // console.log("Payment authentication succeeded!");
                  setLoad(false);
                  setSubscriptionSucess(true);
                  setTimeout(() => {
                      dispatch(getEmployerProfile());
                      close("cancel");
                  }, 300);
              }
          } else if (resp?.data?.success === false && resp?.data.message == "Payment failed. Upgrade reverted to original plan.") {
              setLoad(false);
              setPaymentFailed(true);
              // dispatch(SiteError(resp?.status));
            } else if (resp.success === false && resp?.message == "Unauthorized") {
              dispatch(returnHome(resp?.status));
            } else if (resp.statusCode == undefined) {
              if (resp?.title == "Error") {
                setTimeout(() => {
                  toast.error(resp.error?.raw?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }, 50);
              }
            }
            return resp;
          }).catch(err => {
            // console.log("my err", err)
          });
      }
      if (planType == employer?.plan) {
        setTimeout(() => {
          toast.warning("You can't upgrade to the same plan. Thanks", {
            position: toast.POSITION.TOP_CENTER,
          })
        }, 50);
      } else {
        let res = await response();
      }

    } else {
      setTimeout(() => {
        toast.warning("You have to subscribe to a plan first. Thanks", {
          position: toast.POSITION.TOP_CENTER,
        })
      }, 50);
    }
  }

  const handleClose = (type) => {
    if (type == "cancel") {
      setIsModalOpen(false);
      // setOperation("subscribe");
    }
  }

  const handleCloseFailedPaymentModal = () => {
    setPaymentFailed(false);
  }

  let failedPaymentModal = paymentFailed == true ? createPortal(
    <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px] " onClick={() => handleCloseFailedPaymentModal()}  >
      <PaymentFailedModal handleCloseFailedPaymentModal={handleCloseFailedPaymentModal} cta={cta} />
    </div>, document.getElementById("modal")
  ) : null

  return (
    <div className={`w-full cursor-pointer min-h-full ${plan === "STANDARD" ? 'bg-[#F9F4FD] rounded-[8px]' : 'bg-white'} ${operationType === "upgrade" && "border border-[#E6E9F5] rounded-none"} hover:mx-4 hover:scale-110 transform transition-transform flex flex-col justify-between gap-y-5 px-4 py-7`}>
      <div className={`${operationType === "subscribe" ? "flex h-full flex-col gap-y-4 justify-between" : "flex flex-col gap-y-4"}`}>
        {operationType !== "subscribe" && <p className="text-[#858BA0] text-center text-sm leading-normal"><span className="text-[#252430] text-[1.5rem] lg:text-[1.75rem] xl:text-[40px] font-[700]">{`$${preShow === "monthly" ? price.monthly : price.annually}`}</span>/{preShow === 'monthly' ? 'Month' : 'Year'}</p>}

        {operationType === "subscribe" && display === true && <div className="flex text-[#000000DE] flex-col gap-y-4">
          <p className="text-[24px] text-center leading-[1.2] font-medium">{plan}</p>
          <ul className="flex flex-col gap-y-1">
            {
              benefits.map((ben, index) => {
                return <li key={index} className="text-sm md:text-xs list-disc leading-[1.5] style-list ml-4 font-medium">{ben}</li>
              })
            }
          </ul>
        </div>}

        {preShow === 'annually' && (
          <p className='text-xs leading-normal text-center text-[#ff6633]'>
            Save {percentOff} with annual billing
          </p>
        )}

        <div>
          {operationType === "subscribe" && <p className="text-[#858BA0] text-center text-sm leading-normal"><span className="text-[#252430] text-[1.5rem] lg:text-[1.75rem] xl:text-[40px] font-[700]">{`$${preShow === "monthly" ? price.monthly : price.annually}`}</span>/{preShow === 'monthly' ? 'Month' : 'Year'}</p>}
          <button className={`py-4 px-6 text-center w-full ${plan === "STANDARD" ? "bg-[#5843BD] text-[#E6E9F5]" : "text-[#5843BD] bg-[#EEECF8]"} rounded-[0.25rem] text-sm leading-normal`} onClick={handleClick}>
            {cta}
          </button>
        </div>
      </div>
      {isModalOpen && ReactDOM.createPortal(
        <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px] ">
          <UpgradeConfirm cta={cta} setCancel={setIsModalOpen} handleAction={handleModalAction} amountToPay={amountToPay} employer={employer} /></div>,
        document.getElementById('modal') // Portal the modal to the element with id 'modal'
      )}

      {subscriptionSuccess && ReactDOM.createPortal(
        <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px] ">
          <SubscriptionSuccess setCancel={setSubscriptionSucess} type={cta === "Upgrade Plan" ? "upgrade" : cta === "Downgrade Plan" ? "downgrade" : "subscribe"} /></div>,
        document.getElementById('modal') // Portal the modal to the element with id 'modal'
      )}
      {failedPaymentModal}
      {load && <LoaderModal text={employer?.plan == undefined ? "Processing..." : text } />}
    </div>
  );
}