import React from "react";
import TopBar from "../../components/topbar/Top-Bar";
import SideBar from "../../components/sidebar/Side-Bar";
import BottonBar from "../../components/bottom_bar";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const EmployerHomeDashboard = () => {
  const stat = useSelector((e) => e.login);
  const sidebar = useSelector(e => e.sidebarOpen.message);
  // console.log("header", stat.profile_status.profile_status);
  return (
    <div className={`Dashboard bg-[#FAFAFA] grid grid-cols-auto h-full md:grid-cols-smdash grid-rows-sm_dash ${sidebar ? 'lg:grid-cols-dash' : ''} md:grid-rows-dash bg-tet-912 overflow-x-hidden overflow-y-hidden`}>
      <TopBar topBarShowMenu={true} />
      <SideBar />
      <Outlet />
      <BottonBar />
    </div>
  );
};

export default EmployerHomeDashboard;
