import React from "react";

const UpgradeConfirm = ({ cta, setCancel, handleAction, amountToPay, employer }) => {
  // console.log("amountToPay", amountToPay);
  return (
    <section className="bg-white md:max-w-[550px] sm:max-w-[500px] min-w-[350px] w-[80%] z-100 m-auto rounded-[24px] pb-5 overflow-hidden relative">
      <div className="flex flex-col items-center relative z-[1000] justify-center gap-y-4">
        <div className="flex items-center px-4 md:px-6 py-6 justify-between bg-[#5843BD] PLAN w-full border-[#E9F1FF] border-b-[8px] text-[#fff]">
          <div className="text-xl font-medium leading-none text-center">Confirm {cta === "Upgrade Plan" ? "Upgrade" : cta === "Downgrade Plan" ? "Downgrade" : "Sign Up"}</div>
          <div className="w-8 h-8 rounded-full bg-[#fff] flex justify-center items-center font-bold text-[#1C1C1C] text-opacity-60 text-[20px] cursor-pointer" onClick={() => setCancel(false)}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.2103 1.14844L1.14844 11.3612M1.14844 1.14844L11.2103 11.3612" stroke="black" strokeWidth="1.02128" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        </div>
        <div className="w-full mt-4">
          <p className="text-[20px] font-semibold leading-[1.2] text-center text-[#5843BD] PLAN">
            Are you sure you want to
            <br className="hidden md:block" />{cta === "Upgrade Plan" ? "upgrade" : cta === "Downgrade Plan" ? "downgrade" : "subscribe?"} {cta === "Upgrade Plan" || cta === "Downgrade Plan" ? "your plan?" : null }
          </p>
        </div>
        <p className="text-base font-normal leading-[1.2] text-center max-w-[280px] sm:max-w-[400px]">
        {cta === "Upgrade Plan" ? "Upgrading" : cta === "Downgrade Plan" ? "Downgrading" : "Subscribing"} {cta === "Upgrade Plan" || cta === "Downgrade Plan" ? (<span>
          your plan means you will be <br className="hidden md:block" /> subscribed to a {cta === "Upgrade Plan" ? "higher" : "lower"} plan with more features.
        </span>) : "to an exciting new plan that will unlock amazing possibilities for your business"}
        </p>
        <p className="text-base font-normal leading-[1.2] text-center max-w-[280px] sm:max-w-[400px]">
            {cta === "Upgrade Plan" ? (<span>
              Price to upgrade to the selected plan is <br className="hidden md:block" /> ${amountToPay}.
              </span>) : null} <br className="hidden md:block" />
              {employer.paid_amount == 0 ? "Note that this will cancel your free trail." : null}
        </p>
        <div className="flex justify-between items-center justify-center flex-col py-4 gap-x-4 w-full px-4 md:px-8 sm:flex-row gap-y-4">
          <button className="w-full md:w-max px-9 py-3 gap-3 rounded-3xl text-base font-semibold leading-normal text-center bg-[#5843BD] PLAN text-white" onClick={handleAction}>
            Yes, {cta === "Upgrade Plan" ? "upgrade" : cta === "Downgrade Plan" ? "downgrade" : "sign up"} {cta === "Upgrade Plan" || cta === "Downgrade Plan" ? "plan" : null}
          </button>
          <button className="w-full md:w-max items-center justify-center flex gap-x-2 px-9 py-3 gap-3 rounded-3xl text-base font-semibold leading-normal text-center text-[#22263A80]" onClick={() => setCancel(false)}>
            Cancel
          </button>
        </div>
      </div>
    </section>
  );
};

export default UpgradeConfirm;
