import React, { useEffect, useState } from "react"
import { Document, Page, StyleSheet, View, Text, PDFViewer, Svg, Line, Image, Link, Circle } from '@react-pdf/renderer';


// require fonts


import font from "./font/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf";
import font1 from "./font/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf";
import font2 from "./font/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf";
import font3 from "./font/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf";
import { Font } from '@react-pdf/renderer';

Font.register({ family: 'Inter', src: font1});
Font.register({ family: 'Inter-bold', src: font});
Font.register({ family: 'Inter-semi-bold', src: font2});
Font.register({ family: 'Inter-regular', src: font3});


// require useSelector from react-redux
import { useSelector } from "react-redux";


const styles = StyleSheet.create({
  page: {
    fontFamily: "Inter-regular",
    paddingTop: 20,
    paddingBottom: 35,
  },
  viewer: {
    width: '65vw', height: '100vh',
    '@media maxWidth: 500' : {
      width: "80vw", height: "auto",
      overFlow: "scroll"
    }
  }
});


const Designer = (props) => {
  // console.log("props", props.details);
  let details;
  details = [props.details]
  const [ wrap_stat, setWrap_stat ] = useState(false);
  useEffect(() => {
    if (details[0].Experience.length >= 2) {
      setWrap_stat(true);
    } else {
      setWrap_stat(false);
    }
  }, [])

  let my_interest;
  if (details[0] !== null) {
    if (Array.isArray(details[0]?.Interest)) {
      my_interest = details[0]?.Interest;
    } else if (typeof details[0]?.Interest === "string") {
      my_interest = details[0]?.Interest.split(", ");
    }
  }
  if (Array.isArray(details[0]?.Technical_skills) === false ) {
    if (typeof details[0]?.Technical_skills === "string") {
      details[0].Technical_skills = details[0]?.Technical_skills.split(", ");
    }
   }

   if (Array.isArray(details[0]?.Personal_skills) === false ) {
    if (typeof details[0]?.Personal_skills === "string") {
      details[0].Personal_skills = details[0]?.Personal_skills.split(", ");
    }
   }
  
   const chunkSubstr = (str, size) => {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }

    return chunks;
  };

  Font.registerHyphenationCallback((word) => { // to avoid breaking of words.
    if (word.length > 16) {
      return chunkSubstr(word, 14);
    } else {
      return [word];
    }
  });


  return (        
        <Document style={styles.page}>
          <Page size="A4" style={styles.page} wrap={true}>
          {details?.map((data, key) => (
              <View key={key}>
                <View style={{left: 30, paddingTop: 30}}>
                <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 500}}>
                  {data.Lang[0]?.lang === "English" ? <Text style={{color: "#FF6633", fontFamily: "Inter", fontSize: 22}}>Hello, I am </Text> : <Text style={{color: "#FF6633", fontFamily: "Inter", fontSize: 22}}>Hola, soy </Text>}
                    <Text style={{color: "#FF6633", fontFamily: "Inter", fontSize: 22}}>{data.Fullname} </Text>
                </View>
                {data.CvType !== null || data.CvType !== undefined ? 
                <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 500}}>
                  {data.Lang[0]?.lang === "English" ? <Text style={{color: "#FF6633", fontFamily: "Inter", fontSize: 15}}>A </Text> : <Text style={{color: "#FF6633", fontFamily: "Inter", fontSize: 15}}></Text>}
                  <Text style={{color: "#FF6633", fontFamily: "Inter", fontSize: 15}}>{data.CvType} </Text>
                </View> : null}
              </View>
  
              <View style={{position: "relative", marginTop: 20}}>
                <Svg  height="2" width="600" style={{left: 30, top: 0}} >
                  <Line
                    x1="0"
                    y1="0"
                    x2="530"
                    y2="0"
                    strokeWidth={2}
                    stroke="#C5C5C5"
                  />
                </Svg>
              </View>
  
              {/* contact start */}
              <View style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                <View style={{left: 30, top: 20,}}>
                {
                  data.Lang[0]?.lang === 'English' ? 
                  <Text style={{color: "#000000", fontFamily: "Inter", fontSize: 14}}>Contact</Text>  : <Text style={{color: "#000000", fontFamily: "Inter", fontSize: 14}}>Contacto </Text> 
                }
                  <View style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight:10, top:10 }}>
                    <View style={{width: 14, height: 14, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: 5}}>
                      <Image src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704123/Website%20images/icons8-mail-24_1_xakt8v.png" style={{ width: 12, height: 12}}/>
                    </View>
                    <View>
                      <Text style={{fontFamily: "Inter-regular", fontSize: "10px"}}><Link src="" style={{ textDecoration: "underline", color: "#000000"}}>{data.Email}</Link></Text>
                    </View>
                  </View>
  
                  <View style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight:10, top:20 }}>
                    <View style={{width: 14, height: 14, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: 5}}>
                      <Image src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704126/Website%20images/icons8-phone-24_tysfjb.png" style={{ width: 12, height: 12}}/>
                    </View>
                    <View>
                      <Text style={{fontFamily: "Inter-regular", fontSize: "10px"}}><Link src="" style={{ textDecoration: "underline", color: "#000000"}}>{data.Phone_number}</Link></Text>
                    </View>
                  </View>

                  {data.Website?.length !== 0 ? 
                    <View style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight:10,marginTop:30, position: "relative" }}>
                    <View style={{width: 14, height: 14, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: 5}}>
                      <Image src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704132/Website%20images/icons8-world-30_qgzkz1.png" style={{ width: 12, height: 12}}/>
                    </View>
                    <View>
                      <Text style={{fontFamily: "Inter-regular", fontSize: "10px"}}><Link src="" style={{ textDecoration: "underline", color: "#000000"}}>{data.Website}</Link></Text>
                    </View>
                  </View> : null
                  }
                </View>
                <View style={{marginRight: 30, marginTop:30}}>
                <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                      {data.Linkedin?.length === 0 ? null :
                      <View style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight:10,marginTop:20, position: "relative" }}>
                        <View style={{width: 14, height: 14, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: 5}}>
                          <Image src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704122/Website%20images/icons8-linkedin-32_hlof14.png" style={{ width: 12, height: 12}}/>
                        </View>
                        <View>
                          <Text style={{fontFamily: "Inter-regular", fontSize: "10px"}}><Link src={data.Linkedin} style={{ textDecoration: "underline", color: "#000000"}}>Linkedin</Link></Text>
                        </View>
                      </View>
                    }
                  </View>
  
  
  
                  <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
{/*                     <View style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight:30, top:10 }}>
                      <View style={{width: 14, height: 14, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: 5}}>
                        <Image src={window.location.origin + '/assets/icons8-instagram-48 (1).png'} style={{ width: 12, height: 12}}/>
                      </View>
                      <View>
                        <Text style={{fontFamily: "Inter-regular", fontSize: "10px"}}><Link src="" style={{ textDecoration: "underline", color: "#000000"}}>Instagram</Link></Text>
                      </View>
                    </View>
  
                      <View style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight:30, top:10 }}>
                        <View style={{width: 14, height: 14, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: 5}}>
                          <Image src={window.location.origin + '/assets/icons8-dribble-64.png'} style={{ width: 12, height: 12}}/>
                        </View>
                        <View>
                          <Text style={{fontFamily: "Inter-regular", fontSize: "10px"}}><Link src="" style={{ textDecoration: "underline", color: "#000000"}}>Dribble</Link></Text>
                        </View>
                      </View>
   */}
                  </View>
                </View>
              </View>
              {/* contact end */}
                
              <View style={{position: "relative", marginTop: 40, marginBottom: 10}}>
                <Svg  height="2" width="600" style={{left: 30, top: 0,}} >
                  <Line
                    x1="0"
                    y1="0"
                    x2="530"
                    y2="0"
                    strokeWidth={2}
                    stroke="#C5C5C5"
                  />
                </Svg>
              </View>
  
              {/* About start */}
                <View style={{display: "flex", flexDirection: "row", left: 30, top: 10}}>
                  <View style={{ width: 200}}>
                    {
                    data.Lang[0]?.lang === 'English' ?
                    <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 14 }}>About</Text> : <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 14 }}>Sobre</Text> 
                    }
                  </View>
                  <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 330}}>
                    <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10,}} >{data.Professional_summary} </Text>
                  </View>
                  </View>
              {/* About end */}

              {data.Education?.length === 0 ? null : 
                <View style={{position: "relative", marginTop: 30}}>
                <Svg  height="2" width="600" style={{left: 30, top: 0}} >
                  <Line
                    x1="0"
                    y1="0"
                    x2="530"
                    y2="0"
                    strokeWidth={2}
                    stroke="#C5C5C5"
                  />
                </Svg>
              </View>
              }
  
              {/* Education start */}
              {Array.isArray(data.Education) === false ? null : 
                <View>
                {data.Education?.length === 0 ? null : 
                <View style={{display: "flex", flexDirection: "row", left: 30, top: 20}}>
                  <View style={{ width: 200, marginTop: 5}}>
                  {
                    data.Lang[0]?.lang === 'English' ?
                    <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 14 }}>Education</Text> : <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 14 }}>Educación</Text>
                  }
                </View>
                  <View>
                  {data.Education?.map((E_data, key) => (
                      <View style={{ width: 330,}} key={key}>
                      <View style={{width: 330, marginTop: 5}}>
                        <View style={{display: "flex", flexDirection: "row", alignItems: "center", width: 300}}>

                          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 300, marginRight: 10}}>
                              <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 10, }}>{E_data.Degree} {E_data.Course}</Text>
                            </View>
                          
                            <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10,}}>{E_data.End_date === null ? <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10,}}>Present</Text> : <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10,}}>{E_data.End_date}</Text>}</Text>
                        </View>

                        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 300, marginRight: 10}}>
                          <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10 }}>{E_data.School} </Text>
                        </View>
                      </View>
                    </View>
                  ))}
                  </View>
                </View>}
              </View>
              }
              {/* Education end */}
  
              {data.Experience?.length === 0 ? null :
                <View style={{position: "relative", marginTop: 30}}>
                <Svg  height="2" width="600" style={{left: 30, top: 0}} >
                  <Line
                    x1="0"
                    y1="0"
                    x2="530"
                    y2="0"
                    strokeWidth={2}
                    stroke="#C5C5C5"
                  />
                  </Svg>
                </View>
              }
  
              {/* Experience start */}
              {Array.isArray(data.Experience) === false ? null : 
                <View>
                  {data.Experience?.length === 0 ? null : 
                  <View style={{display: "flex", flexDirection: "row", marginTop: 20, left: 30}}>
                  <View style={{width: 200}}>
                    {
                      data.Lang[0]?.lang === 'English' ?
                      <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 14 }}>Past Experience</Text> : <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 14 }}>Experiencia </Text>
                    }
                  </View>
                  <View style={{ width: 330}}>
                    {data.Experience?.map((E_data, key) => (
                      <View style={{marginTop: 20}}>

                        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 270, marginRight: 10}}>
                          <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 10,}}>{E_data.Where_i_worked} </Text>
                        </View>

                        
                        <View style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: 10, width: 330}}>

                        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 165, marginRight: 10}}>
                          <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 10, }}>{E_data.My_job_title} </Text>
                        </View>
                          
                          <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: 165 }}>
                            <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10,  }}>{E_data.Work_start_date} - {E_data.Work_end_date?.length === 0 ? <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10, }}>Present</Text> : <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10, }}>{E_data.Work_end_date}</Text> }</Text>
                          </View>

                        </View>
                        <View style={{ width: 320}}>
                          {Array.isArray(E_data.Day_to_day_work) === true ? 
                            <View style={{ width: 330 }}>
                              {E_data.Day_to_day_work?.map((ex_data, key) => (
                                <View style={{ display: "flex", flexDirection: "row", }}>
                                  <View>
                                    <Text style={{ marginRight: 8, marginTop: 0 }}>•</Text>
                                  </View>
                                  <View style={{  marginTop: 5}} key={key}>
                                    {ex_data[0] === "-" ? <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10,}}>{ex_data.slice(1, ex_data?.length)}</Text> : <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10,}}>{ex_data.replace(/^(\d[^\w\s]*|[^\w\s])+/g, '')}</Text>}
                                  </View>
                                </View>
                              ))}
                            </View> : <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10, marginTop: 5}}>
                              {E_data.Day_to_day_work.replace(/^(\d[^\w\s]*|[^\w\s])+/g, '')}
                            </Text>
                          }
                        </View>
                      </View>
                    ))}
                  </View>
                </View>
                }
                </View>
                }
              {/* Experience end */}
  
              {data.Technical_skills?.length === 0 && data.Personal_skills?.length === 0 ? null : 
                <View style={{position: "relative", marginTop: 30}}>
                  <Svg  height="2" width="600" style={{left: 30, top: 0}} >
                    <Line
                      x1="0"
                      y1="0"
                      x2="530"
                      y2="0"
                      strokeWidth={2}
                      stroke="#C5C5C5"
                    />
                  </Svg>
                </View>
              }
  
              {/* skills start */}
              {data.Technical_skills?.length === 0 && data.Personal_skills?.length === 0 ? null :
              <View>
                <View style={{display: "flex", flexDirection: "row", marginTop: 20, left: 30}}>
                  <View style={{width: 200}}>
                    {
                      data.Lang[0]?.lang === 'English' ? 
                      <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 14 }}>Skills</Text> : <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 14 }}>Habilidades</Text> 
                    }
                  </View>
                  <View>
                    {data.Technical_skills?.length === 0 ? null : 
                    <View style={{ width: 330}}>
                      {
                        data.Lang[0]?.lang === 'English' ? 
                        <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 10, }}>Technical Skills</Text> : <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 10, }}>Habilidades Técnicas </Text> 
                      }
                      <View style={{display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", width: 330, marginTop: 5}}>
                      {data.Technical_skills?.map((T_data, key) => (
                          <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10, }} key={key}>
                            {T_data.value !== undefined ? <Text>{T_data.value}, </Text> : <Text>{T_data}, </Text>}
                          </Text>
                      ))}
                      </View>
                    </View>
                    }
                    
                    {data.Personal_skills?.length === 0 ? null : 
                    <View style={{ width: 330, marginTop: 10}}>
                      {
                        data.Lang[0]?.lang === 'English' ? 
                        <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 10, }}>Personal Skills</Text> : <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 10, }}>Habilidades personales</Text> 
                      }
                      <View style={{display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", width: 330, marginTop: 5}}>
                      {data.Personal_skills?.map((P_data, key) => (
                          <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10, }}>
                            {P_data.value !== undefined ? <Text>{P_data.value}, </Text> : <Text>{P_data}, </Text>}
                          </Text>
                      ))}
                      </View>
                    </View>
                    }
                  </View>
                </View>
              </View>}
              {/* Skills end */}
  
              {data.Award?.length !== 0 && data.Award[0]?.Award_name.length !== 0 ? 
                <View style={{position: "relative", marginTop: 30}}>
                  <Svg  height="2" width="600" style={{left: 30, top: 0}} >
                    <Line
                      x1="0"
                      y1="0"
                      x2="530"
                      y2="0"
                      strokeWidth={2}
                      stroke="#C5C5C5"
                    />
                  </Svg>
                </View> : null
              }
  
              {/* Award start */}
              {
                data.Award?.length !== 0 && data.Award[0]?.Award_name?.length !== 0 ? 
                <View>
                  {data.Award[0]?.Award_name === null ? null : 
                  <View style={{display: "flex", flexDirection: "row", marginTop: 20, left: 30}}>
                    <View style={{width: 200, marginTop: 10}}>
                    {
                      data.Lang[0]?.lang === 'English' ? 
                      <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 14 }}>Award</Text> : <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 14 }}>Premio</Text> 
                    }
                    </View>
                    <View style={{width: 330}}>
                      {data.Award?.map((A_data, key) => (
                        <View style={{marginTop: 10}}>
                          <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                            <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 10, }}>
                              {A_data.Award_name}
                            </Text>
                            <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10, }}>
                              {A_data.Award_year}
                            </Text>
                          </View>
                          <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10, }}>{A_data.Award_giver}</Text>
                        </View>
                      ))}
                    </View>
                  </View>}
                </View> : null
              }
              {/* Award end */}
  
{/*               {data.Reference?.length !== 0 && data.Reference[0]?.Reference_person_name?.length !== 0 ?
                <View style={{position: "relative", marginTop: 40}}>
                  <Svg  height="2" width="600" style={{left: 30, top: 0}} >
                    <Line
                      x1="0"
                      y1="0"
                      x2="530"
                      y2="0"
                      strokeWidth={2}
                      stroke="#C5C5C5"
                    />
                  </Svg>
                </View> : null
              }
   */}
  
              {/* Reference start */}
              {/* {Array.isArray(data.Reference) === false ? null : 
              <View>
                {data.Reference?.length !== 0 && data.Reference[0]?.Reference_person_name.length !== 0 ? 
                  <View style={{display: "flex", flexDirection: "row", marginTop: 20, left: 30}}>
                  <View style={{width: 200, marginTop: 10}}>
                    {
                      data.Lang[0]?.lang === 'English' ?
                      <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 14 }}>Reference</Text> : <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 14 }}>Referencia</Text>
                    }
                  </View>
                  <View style={{width: 330, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                    {data.Reference?.map((R_data, key) => (
                      <View style={{marginTop: 20, width: 165}}>
                        <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 10, }}>{R_data.Reference_person_name}</Text>
                        <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10, marginTop: 2}}>
                          {R_data.How_do_i_know_this_person}
                        </Text>
                        <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10, marginTop: 2}}>
                          {R_data.Referencee_job_title}
                        </Text>
                        <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10, marginTop: 2}}>{R_data.Reference_email}</Text>
                      </View>
                        
                    ))}
                  </View>
                </View> : null}
              </View>} */}
              {/* Reference end */}

              {data.Language?.length !== 0 ?
                <View style={{position: "relative", marginTop: 40}}>
                  <Svg  height="2" width="600" style={{left: 30, top: 0}} >
                    <Line
                      x1="0"
                      y1="0"
                      x2="530"
                      y2="0"
                      strokeWidth={2}
                      stroke="#C5C5C5"
                    />
                  </Svg>
                </View> : null
              }

              {/* Language section */}
              
                {data.Language?.length !== 0 ? 
                  <View style={{display: "flex", flexDirection: "row", marginTop: 20, left: 30}}>
                  <View style={{width: 200, marginTop: 10}}>
                    {
                      data.Lang[0]?.lang === 'English' ?
                      <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 14 }}>Language</Text> : <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 14 }}>Idiomas</Text>
                    }
                  </View>
                  <View style={{width: 330, display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap"}}>
                    {data.Language?.map((L_data, key) => (
                      <View style={{marginTop: 20, width: 165, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Text style={{ color: "#000000", fontFamily: "Inter", fontSize: 10, }}>{L_data.language} - </Text>
                        <Text style={{ color: "#848484", fontFamily: "Inter-regular", fontSize: 10, marginTop: 2}}>
                        {L_data.rate}
                        </Text>
                      </View>
                        
                    ))}
                  </View>
                </View> : null}
              {/* language section end */}

              <View style={{position: "relative", marginTop: 30}}>
                <Svg  height="2" width="600" style={{left: 30, top: 0}} >
                  <Line
                    x1="0"
                    y1="0"
                    x2="530"
                    y2="0"
                    strokeWidth={2}
                    stroke="#000000"
                  />
                </Svg>
              </View>
            </View>
        ))}
            </Page>
          </Document>
  )
}

export default Designer;