import React, { useEffect, useState, useRef } from 'react';
import Chart from 'chart.js/auto';
import { useSelector, useDispatch } from 'react-redux';
import { getEmployerAnalyticsWeekly } from '../../../Redux/ActionCreator';

const DashboardLineChart = ({ type, timeFrame }) => {
  // console.log("Type", type);
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const analyticsWeekly = useSelector(e => e.analytics_weekly.message);

  useEffect(() => {
    if (analyticsWeekly == null || analyticsWeekly.length == 0) {
      const { startDate, endDate } = getLast7DaysRange();
      dispatch(getEmployerAnalyticsWeekly(startDate, endDate, 'last 7 days'));
    }
  }, []);

  const getLast7DaysRange = () => {
    const today = new Date();
    const last7Days = new Date();

    // Subtract 7 days from today
    last7Days.setDate(today.getDate() - 7);

    return {
      startDate: last7Days,
      endDate: today
    };
  };

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    let highest;
    if (type == "jobs") {
      if (analyticsWeekly?.stat != null) {
        highest = analyticsWeekly?.stat[0]?.jobs;
        analyticsWeekly?.stat?.forEach(e => {
          if (highest < e.jobs) {
            highest = e.jobs;
          }
        });
      }
    } else if (type == "category") {
      if (analyticsWeekly?.categories_stat != null) {
        highest = analyticsWeekly?.categories_stat[0]?.jobs;
        analyticsWeekly?.categories_stat?.forEach(e => {
          if (highest < e.jobs) {
            highest = e.jobs;
          }
        });
      }
    } else if (type == "applications") {
      // applications stat;
      if (analyticsWeekly?.monthlyApplicationStat != null) {
        highest = analyticsWeekly?.monthlyApplicationStat[0]?.applications;
        analyticsWeekly?.monthlyApplicationStat?.forEach(e => {
          if (highest < e.applications) {
            highest = e.applications;
          }
        });
      }
    }

    let weeklyHighest;
    if (type == "jobs") {
      if (analyticsWeekly?.stat != null) {
        weeklyHighest = analyticsWeekly?.stat[0]?.jobs;
        analyticsWeekly?.stat?.forEach(e => {
          if (weeklyHighest < e.jobs) {
            weeklyHighest = e.jobs;
          }
        });
      }
    } else if (type == "category") {
      if (analyticsWeekly?.categories_stat != null) {
        weeklyHighest = analyticsWeekly?.categories_stat[0]?.jobs;
        analyticsWeekly?.categories_stat?.forEach(e => {
          if (weeklyHighest < e.jobs) {
            weeklyHighest = e.jobs;
          }
        });
      }
    } else if (type == "applications") {
      // applications stat;
      if (analyticsWeekly?.monthlyApplicationStat != null) {
        weeklyHighest = analyticsWeekly?.weeklyApplicationStat[0]?.applications;
        analyticsWeekly?.monthlyApplicationStat?.forEach(e => {
          if (weeklyHighest < e.applications) {
            weeklyHighest = e.applications;
          }
        });
      }
    }

    // labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    // data: [65, 59, 80, 81, 56, 55, 40, 90, 95, 70, 85, 110],

    const ctx = chartRef.current.getContext('2d');
    const getLabels = () => {
      let labels = type == "jobs" ? analyticsWeekly?.stat?.map(e => e.week) :
        type == "category" ? analyticsWeekly?.categories_stat?.map(e => e.category) :
          analyticsWeekly?.weeklyApplicationStat?.map(e => e.week);

      let currentDate;
      let previousDate;
      if (labels == null || labels.length == 0) {
        return [];
      } else {
        currentDate = new Date(labels[0]);
        previousDate = new Date(currentDate);
        previousDate.setDate(previousDate.getDate() - (timeFrame == "last 30 days" ? 30 : 7));
      }



      const formatDate = (date) => {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return `${days[date.getDay()]} ${String(date.getDate()).padStart(2, '0')}`;
      };

      labels = type !== "category" ? [formatDate(previousDate), ...(labels || [])] : ["", ...(labels || [])];

      return labels;
    };

    const getData = () => {
      let data = type == "jobs" ? analyticsWeekly?.stat?.map(e => e.jobs) :
        type == "category" ? analyticsWeekly?.categories_stat?.map(e => e.jobs) :
          analyticsWeekly?.weeklyApplicationStat?.map(e => e.applications);

      data = [0, ...(data || [])];

      return data;
    };

    const data = {
      labels: getLabels(),
      datasets: [
        {
          label: 'weekly Data',
          data: getData(),
          fill: true,
          borderColor: '#5843BD66',
          borderWidth: 2,
          borderDash: [5, 5],
          tension: 0.1,
          pointRadius: 0,
          backgroundColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            if (!chartArea) {
              return null;
            }
            const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            gradient.addColorStop(0, 'rgba(88, 67, 189, 0)');
            gradient.addColorStop(1, 'rgba(88, 67, 189, 0.4)');
            return gradient;
          },
        }
      ]
    };

    // console.log("Data", data.datasets[0].data);
    // console.log("Labels", data.labels);

    chartInstance.current = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: highest + highest, // 100,
            ticks: {
              stepSize: highest // 20
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        },
        elements: {
          point: {
            radius: 5, // This ensures no points are drawn
            hoverRadius: 5,
            hitRadius: 10,
            pointStyle: "star"
          }
        },
        tooltips: {
          mode: 'point'
        }
      }
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [type, timeFrame]);

  return (
    <div>
      <canvas ref={chartRef} />
    </div>
  );
};

export default DashboardLineChart;