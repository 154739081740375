import React, { useContext, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { ToastContainer, toast } from "react-toastify";
import TextInput from "../../common/text_input/text_input";
import Tab from "../../common/tab/tab";
import { TextArea } from "../../common/textarea/textarea";
import CustomDropdown from "../../common/custom_dropdown";
import { useDispatch, useSelector } from "react-redux";
import { job_to_update, dispatchJobDetail, updateJob, job_creation_helper_function, job_loading, job_success, returnHome } from "../../../Redux/ActionCreator";
import $ from "jquery";
import LoaderModal from "../../common/LoaderModal";
import HelmetProvide from "../../common/helmet";
import { handleKeyDown } from "../../../utils/keydown/keydown.js";
import { useAnalytics } from "../../../context/AnalyticsContext";
import { saveIntoServerCache, getOneFromServerCache } from "../../../utils/saveIntoCache";
import SelectLanguage from "../../common/resumeLangSelection/selectLanguage.js"
import BackButton from "../../common/back_button/index.jsx";
import { useNavigate } from "react-router-dom";
import TextEditor from "../../common/textarea/NewTextArea";
import { convertStringToHtmlWithBr, convertStringToArray } from "../../../utils/stringToArray/stringToArray";
import { baseURL, baseURL1 } from "../../../Redux/baseURL.js";
import { WindowRounded } from "@mui/icons-material";

export default function JobDetails(props) {
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const info = useSelector(info => info.jobDetails.jobDetails);
  const allPostedJobs = useSelector(e => e.jobs_posted.message);
  const gpt_help = useSelector(e => e.job);
  const employer = useSelector(e => e.employer);
  const [loading, setLoading] = useState(false);
  const jTU = useSelector(e => e.job_to_update.message);
  const [save, setSave] = useState(false);
  const navigate = useNavigate();

  const [jobDetails, setJobDetails] = useState({
    Category: null,
  });
  const urlPath = window.location.pathname;
 // console.log("urlPath", urlPath);
  const jobName = urlPath.split("/")[3];

  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [lang, setLang] = useState(null);
  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search).get("NovFreePost");
    if (searchParams !== undefined && searchParams !== null && searchParams == 1) {
      // save into localstorage
      localStorage.setItem("NovCoupon", searchParams);
    }
    if (lang == null) {
      let Language = getOneFromServerCache("Language");
      Language.then(resp => resp.json())
        .then(resp => {
          // console.log("Language", resp.data);
          if (resp.success == true) {
            if (resp.data == null) {
              setShowLanguageModal(true);
            } else {
              setLang(resp.data);
              setShowLanguageModal(false);
            }
          } else if (resp.success === false && (resp.message == "Unauthorized" || resp.message == "Refresh token mismatch" || resp.message == "No token provided")) {
            dispatch(returnHome(baseURL1));
          }
        }).catch(err => {
        //  console.log("Language getting error", err);
        });
    }

  }, [showLanguageModal]);

  useEffect(() => {
    // console.log("calledddd")
    if (urlPath.includes("update-job")) {
      setSave(true);
      // get all jobs from redux
      if (allPostedJobs !== null) {
        const jobToUpdate = allPostedJobs.filter(element => element.custom_name === jobName);
        // console.log("jobToUpdate", jobToUpdate);
        if (jobToUpdate) {
          // dispatch into Job state in redux;
          dispatch(job_to_update(jobToUpdate)); // remember to clear this on getting to the list of jobs screen and list of candidate screen.
        }
      };
    }
  }, [urlPath]);

  //   if (employer.message?.pay_structure.includes("Hourly")){
  //     $("#myhourly").prop("checked", true);
  //   }
  const [verify, setVerify] = useState("");
  useEffect(() => {
    $("#verify").on("click", () => {
      if ($("#verify").prop("checked") === true) {
        setVerify("E-verify")
        $("#nverify").prop("checked", false);
      }
    });
    $("#nverify").on("click", () => {
      if ($("#nverify").prop("checked") === true) {
        setVerify("Does not E-verify")
        $("#verify").prop("checked", false);
      }
    });
  }, [])

  useEffect(() => {
    if (urlPath === "/employer/create-job") {
      if (gpt_help.message !== null) {
        $('[name="Description"]').val(gpt_help.message?.Description);
        setJobDetails(e => ({ ...e, Description: gpt_help.message?.Description }));
        $('[name="Responsibilities"]').val(gpt_help.message?.Responsibilities);
        let responsibilities = Array.isArray(gpt_help.message?.Responsibilities) ? convertStringToHtmlWithBr(gpt_help.message?.Responsibilities.join("\n")) : convertStringToHtmlWithBr(gpt_help.message?.Responsibilities);
        setJobDetails(e => ({ ...e, Responsibilities: responsibilities }));
        let benefit = Array.isArray(gpt_help.message?.Benefits) ? convertStringToHtmlWithBr(gpt_help.message?.Benefits.join("\n")) : convertStringToHtmlWithBr(gpt_help.message?.Benefits);
        $('[name="Benefits"]').val(gpt_help.message?.Benefits);
        setJobDetails(e => ({ ...e, Benefits: benefit }));
        if (gpt_help.message?.Category !== null && gpt_help.message?.Category !== undefined) {
          setJobDetails(e => ({ ...e, Category: gpt_help.message?.Category[0] }));
        }
        // also set skills in jobDetails
        setJobDetails(e => ({ ...e, Skills: gpt_help.message?.Skills }));
        $('[name="jobTitle"]').val(gpt_help.message?.Title);
        setJobDetails(e => ({ ...e, jobTitle: gpt_help.message?.Title }));
        if (gpt_help.message?.verify == "Does not E-verify") {
          $("#nverify").prop("checked", true);
          setVerify(gpt_help.message?.verify)
        } else if (gpt_help.message?.verify == "E-verify") {
          $("#verify").prop("checked", true);
          setVerify(gpt_help.message?.verify)
        }
      } else {
        // if it is null lets check backend server and get anything we have there.
        let cacheInfo = getOneFromServerCache("jobPostPG1")
        cacheInfo.then(res => res.json())
          .then(res => {
            // console.log("server cache value", res);
            if (res.data !== null && res.data !== undefined) {
              let resObj = JSON.parse(res.data);
              // console.log("respObj", resObj);
              setJobDetails(resObj);
              $('[name="company"]').val(resObj?.company);
              setJobDetails(e => ({ ...e, company: resObj?.company }));
              $('[name="Description"]').val(resObj?.Description);
              $('[name="Responsibilities"]').val(resObj?.Responsibilities);
              $('[name="Benefits"]').val(resObj?.Benefits);
              if (jobDetails.Category !== null && jobDetails.Category !== undefined) {
                setJobDetails(e => ({ ...e, Category: e?.Category[0] }));
              }
              $('[name="jobTitle"]').val(resObj?.jobTitle);
              if (resObj?.verify == "Does not E-verify") {
                $("#nverify").prop("checked", true);
                setVerify(resObj?.verify)
              } else if (resObj?.verify == "E-verify") {
                $("#verify").prop("checked", true);
                setVerify(resObj?.verify)
              }
            } else {
              if (employer.message !== null) {
                $('[name="company"]').val(employer.message?.name);
                setJobDetails(e => ({ ...e, company: employer.message?.name }));
              }
            }
            // console.log("Done");
          })
      }

      if (info !== null) {
        $('[name="jobTitle"]').val(info?.jobTitle);
        setJobDetails(e => ({ ...e, jobTitle: info?.jobTitle }));
      }

      if (employer.message !== null) {
        $('[name="company"]').val(employer.message?.name);
        setJobDetails(e => ({ ...e, company: employer.message?.name }));
      }
    } else {
      if (urlPath.includes("update-job")) {
        if (gpt_help.message !== null && gpt_help.message !== undefined) {
          $('[name="Description"]').val(gpt_help.message?.Description);
          setJobDetails(e => ({ ...e, Description: gpt_help.message?.Description }));
          $('[name="Responsibilities"]').val(gpt_help.message?.Responsibilities);
          setJobDetails(e => ({ ...e, Responsibilities: gpt_help.message?.Responsibilities }));
          if (gpt_help.message?.Category !== null && gpt_help.message?.Category !== undefined) {
            setJobDetails(e => ({ ...e, Category: gpt_help.message?.Category[0] }));
          }
          // also set skills in jobDetails
          setJobDetails(e => ({ ...e, Skills: gpt_help.message?.Skills }));
          $('[name="jobTitle"]').val(gpt_help.message?.Title);
          setJobDetails(e => ({ ...e, jobTitle: gpt_help.message?.Title }));
          if (gpt_help.message?.verify == "Does not E-verify") {
            $("#nverify").prop("checked", true);
            setVerify(gpt_help.message?.verify)
          } else if (gpt_help.message?.verify == "E-verify") {
            $("#verify").prop("checked", true);
            setVerify(gpt_help.message?.verify)
          }
        } else {
          if (jTU !== null) {
            $('[name="Description"]').val(jTU[0]?.jobDescription);
            setJobDetails(e => ({ ...e, Description: jTU[0]?.jobDescription }));
            $('[name="Responsibilities"]').val(jTU[0]?.responsibilities);
            setJobDetails(e => ({ ...e, Responsibilities: jTU[0]?.responsibilities }));
            if (jTU[0]?.jobCategory !== null && jTU[0]?.jobCategory !== undefined) {
              setJobDetails(e => ({ ...e, Category: jTU[0]?.jobCategory[0] }));
            }
            // also set skills in jobDetails
            setJobDetails(e => ({ ...e, Skills: jTU[0]?.skills }));
            $('[name="company"]').val(employer.message?.name);
            setJobDetails(e => ({ ...e, company: employer.message?.name }));
            $('[name="jobTitle"]').val(jTU[0]?.jobTitle);
            setJobDetails(e => ({ ...e, jobTitle: jTU[0]?.jobTitle }));
            if (jTU[0]?.verify == "Does not E-verify") {
              $("#nverify").prop("checked", true)
            } else if (jTU[0]?.verify == "E-verify") {
              $("#verify").prop("checked", true);
            }
          } else {
            // if it is null lets check backend server and get anything we have there.
            let cacheInfo = getOneFromServerCache("jobPostPG1")
            cacheInfo.then(res => res.json())
              .then(res => {
                // console.log("server cache value", res);
                if (res.data !== null && res.data !== undefined) {
                  let resObj = JSON.parse(res.data);
                  // console.log("respObj", resObj);
                  setJobDetails(resObj);
                  $('[name="company"]').val(resObj?.company);
                  setJobDetails(e => ({ ...e, company: resObj?.company }));
                  $('[name="Description"]').val(resObj?.Description);
                  $('[name="Responsibilities"]').val(resObj?.Responsibilities);
                  setJobDetails(e => ({ ...e, Category: e?.Category[0] }));
                  $('[name="jobTitle"]').val(resObj?.jobTitle);
                  if (resObj?.verify == "Does not E-verify") {
                    $("#nverify").prop("checked", true);
                    setVerify(resObj?.verify)
                  } else if (resObj?.verify == "E-verify") {
                    $("#verify").prop("checked", true);
                    setVerify(resObj?.verify)
                  }
                }
              })
          }
        }
      }
    }
  }, [urlPath, jTU, gpt_help.message, employer.message]);

  const jobCategories = [
    "Hospitality and Tourism",
    "Warehousing",
    "Food and Drinks",
    "Construction and Trades",
    "Manufacturing and Production",
    "Entry Level Office",
    "Landscaping",
    "Electrical",
    "Hvac & Plumbing"
  ]

  // // const command = `Categorise this job ${jobTitle} into one of this categories: "Information Technology (IT), Sales and Marketing, Healthcare and Medical, 
  // Customer Service, Education, Finance and Accounting, Engineering, Hospitality and Tourism, 
  // Manufacturing and Production, Creative and Design, Human Resources (HR), Legal, Retail and Sales, Construction and Trades,
  // Nonprofit and Social Services, Science and Research, Transportation and Logistics, Government and Public Administration,
  // Environmental and Sustainability, Freelance and Gig Economy". return the result in JSON readable format following this key: "Category" `

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setJobDetails(
      prev => {
        return (
          {
            ...prev, Category: value
          }
        )
      }
    );
  };

  const onChangeHandler = (e) => {
    const { name, value, type, checked } = e.target;
    setJobDetails(prev => {
      return {
        ...prev, [name]: value
      }
    })
  }

  const benefitChangeHandler = (e) => {
    setJobDetails(prev => {
      return {
        ...prev, Benefits: e
      }
    })
  }

  const responsibilitiesChangeHandler = (e) => {
    setJobDetails(prev => {
      return {
        ...prev, Responsibilities: e
      }
    })
  }

  const descriptionChangeHandler = (e) => {
    setJobDetails(prev => {
      return {
        ...prev, Description: e
      }
    })
  }

  const handleSubmitJobDetails = (e) => {
    e.preventDefault();

    if (urlPath === "/employer/create-job") {
      let errMessage;
      if (jobDetails.company === undefined || jobDetails.company === "") {
        errMessage = 'Company name is required';
      } else if (jobDetails.jobTitle === undefined || jobDetails.jobTitle === "") {
        errMessage = 'Job title is required';
      } else if (jobDetails.Category === null || jobDetails.Category === "") {
        errMessage = 'Job category is required';
      } else if (jobDetails.Description === undefined || jobDetails.Description === "") {
        errMessage = 'Job description is required';
      } else if (jobDetails.Responsibilities === undefined || jobDetails.Responsibilities === "") {
        errMessage = 'Key responsibilities is required';
      } else {
        errMessage = null;
      }
      setTimeout(() => {
        if (errMessage !== null) {
          toast.error(errMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }, 50);
      // console.log("errMessage", errMessage);
      if (errMessage === null) {
        // console.log("gtp_help", gpt_help.message)
        if (gpt_help.message !== null && gpt_help.message !== undefined) {
          if (typeof gpt_help.message?.Category === "string") {
            gpt_help.message.Category = [gpt_help.message?.Category];
          }
          dispatch(dispatchJobDetail({ ...jobDetails, Category: gpt_help.message?.Category == undefined || gpt_help.message?.Category == null ? [jobDetails?.Category] : gpt_help.message?.Category, verify: verify }));
          dispatch(job_success({ ...jobDetails, Category: gpt_help.message?.Category == undefined || gpt_help.message?.Category == null ? [jobDetails?.Category] : gpt_help.message?.Category, verify: verify }));
          saveIntoServerCache("jobPostPG1", { ...jobDetails, Category: gpt_help.message?.Category == undefined || gpt_help.message?.Category == null ? [jobDetails?.Category] : gpt_help.message?.Category, verify: verify })
        } else {
          if (typeof jobDetails?.Category === "string") {
            jobDetails.Category = [jobDetails?.Category];
          }
          dispatch(dispatchJobDetail({ ...jobDetails, Category: jobDetails?.Category, verify: verify }));
          dispatch(job_success({ ...jobDetails, Category: jobDetails?.Category, verify: verify }));
          saveIntoServerCache("jobPostPG1", { ...jobDetails, Category: jobDetails?.Category, verify: verify })
        }
        analytics.track("Job posting next btn", {
          Type: "Employer",
          time: new Date(),
          Email: employer?.message?.email,
          Company_name: employer.message?.name,
          profileId: employer.message?._id,
          userId: employer.message?.userId
        });
        if (verify == "") {
          setTimeout(() => {
            toast.error("Please select verification type at the bottom of the page.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 50);
        } else {
          props.handleStepChangeForward();
        }
      }
    } else {
      if (urlPath.includes("update-job")) {
        const formerValue = jTU[0];
        formerValue.jobDescription = jobDetails.jobDescription,
          formerValue.responsibilities = jobDetails.responsibilities,
          formerValue.Benefits = jobDetails.Benefits;
        formerValue.jobTitle = jobDetails.jobTitle;
        if (typeof jobDetails.Category === "string") {
          jobDetails.Category = [jobDetails.Category];
        }
        formerValue.jobCategory = jobDetails.Category;
        formerValue.verify = verify;
        let errMessage;
        if (jobDetails.company === undefined || jobDetails.company === "") {
          errMessage = 'Company name is required';
        } else if (jobDetails.jobTitle === undefined || jobDetails.jobTitle === "") {
          errMessage = 'Job title is required';
        } else if (jobDetails.Category === null || jobDetails.Category === "") {
          errMessage = 'Job category is required';
        } else if (jobDetails.Description === undefined || jobDetails.Description === "") {
          errMessage = 'Job description is required';
        } else if (jobDetails.Responsibilities === undefined || jobDetails.Responsibilities === "") {
          errMessage = 'Key responsibilities is required';
        } else {
          errMessage = null;
        }
        setTimeout(() => {
          if (errMessage !== null) {
            toast.error(errMessage, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }, 50);

        if (errMessage === null) {
          analytics.track("Job posting next btn", {
            Type: "Employer",
            time: new Date(),
            Email: employer?.message?.email,
            Company_name: employer.message?.name,
            profileId: employer.message?._id,
            userId: employer.message?.userId
          });
          dispatch(updateJob(jobName, formerValue));
          props.handleStepChangeForward();
        }
      }
    }
  }

  const generateJobPosting = () => {
    dispatch(job_loading(true));
    if (info !== null) {
      let info_value = info;
      info_value.jobTitle = jobDetails?.jobTitle;
      dispatch(dispatchJobDetail(info_value));
    }
    const cred = {
      job_title: jobDetails.jobTitle,
      Language: lang
    }
    if (jobDetails.jobTitle === undefined) {
      setTimeout(() => {
        toast.error("Job title is required!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
      setLoading(true);
      analytics.track("Job About generation", {
        Type: "Employer",
        time: new Date(),
        Email: employer?.message?.email,
        Company_name: employer.message?.name,
        profileId: employer.message?._id,
        userId: employer.message?.userId
      });
      dispatch(job_creation_helper_function(cred));
    }
  }

  let showModal = showLanguageModal ? createPortal(
    <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center md:bg-black md:bg-opacity-70 backdrop-blur-[2px] sm:bg-white xsm:bg-white">
      <SelectLanguage handleClick={() => setShowLanguageModal(false)} back="/employer/jobs" />
    </div>, document.getElementById("Language")
  ) : null;

  // console.log("showLanguageModal", showLanguageModal);

  return (
    <>
      <HelmetProvide
        title="Moil | Create job"
        description="Create and manage job listings on Moil. Connect with talented blue-collar workers and find the best candidates for your team."
      />
      <div className="px-3 lg:pl-6 py-8 w-full flex justify-center h-calc-150 md:h-calc-70 overflow-y-scroll">
        <div className="h-max w-full flex w-full justify-center">
          <div className="flex max-w-[900px] w-full xtraxl:max-w-[1200px] flex-col gap-y-6 justify-center">

            <BackButton onClick={() => navigate('/employer/dashboard')} />

            <div className="flex flex-col gap-y-1">
              <p class=" text-[24px] font-semibold leading-normal text-left text-[#0D0D0D]">
                Post a job
              </p>

              <p class="text-base font-normal leading-normal text-left text-[#0D0D0D]">
                Create job with just 2 easy steps.<br />Type in your job title and click “Generate for me”.
              </p>
            </div>

            <div className="tabarea justify-start items-center gap-6 inline-flex w-full flex-shrink-0  overflow-hidden">
              <Tab
                // label="Personal Information"
                label="Job Details"
                current={true}
                completed={false}
                num="1"
              />
              <Tab num="2" label="Employment Details" current={false} completed={false} />
              <Tab num="3" label="Skill Test" current={false} completed={false} />
            </div>

              <form
                className="flex-col justify-start items-start gap-y-8 flex w-full"
                onSubmit={handleSubmitJobDetails}
              >
                <div className="flex-col justify-start items-start gap-y-6 flex w-full p-6 bg-white bg-opacity-80 rounded-2xl shadow border border-indigo-400 border-opacity-30">
                  <div className="flex flex-col md:flex-row items-center w-full gap-y-8 gap-x-8">

                    <div className="flex-col justify-start items-start gap-2 flex w-full">
                      <div className="flex gap-3 items-center">
                        <li className="list-disc ml-2 font-medium text-base text-black">
                          Company
                        </li>
                      </div>
                      <p className="text-[#FF6633] text-xs font-medium">
                        What is the name of the company recruiting for this role?
                      </p>
                      <TextInput
                        placeholder={`Johnasah  Inc`}
                        id="comapny"
                        name="company"
                        value={jobDetails.company}
                        onChangeHandler={onChangeHandler}
                      />
                    </div>

                    <div className="flex-col justify-start items-start gap-2 flex w-full">
                      <div className="flex gap-3 items-center">
                        <li className="list-disc ml-2 font-medium text-base text-black">
                          Job Title
                        </li>
                      </div>
                      <p className="text-[#FF6633] text-xs font-medium">
                        What is the job title for the position you’re hiring for? For example, “professional plumber.”
                      </p>
                      <TextInput
                        placeholder={`Job title here.`}
                        id="jobTitle"
                        name="jobTitle"
                        onChangeHandler={onChangeHandler}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-y-2">
                    <p className="text-[#FF6633] text-xs font-medium font-['Poppins']">
                      Click on the button below so that our AI can automatically give you job description, job and key responsibilities for you, based on job title.
                      <span className="block">
                        You can edit the generated texts if you feel like changing anything.
                      </span>
                    </p>
                    <a
                      href="#"
                      className={`max-w-max px-5 py-3 bg-orange-500 rounded-3xl border justify-start items-start gap-2.5 inline-flex cursor-pointer`}
                      onClick={generateJobPosting}
                    >
                      <span className="text-center text-white text-base font-medium">
                        Generate for me
                      </span>
                    </a>
                  </div>
                  <div className="flex-col justify-start items-start gap-2 flex w-full">
                    <div className="flex gap-3 items-center">
                      <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
                      <label
                        className="text-neutral-900 text-base font-medium leading-tight"
                        for="jobTitle"
                      >
                        Job Category
                      </label>
                    </div>
                    <p className="text-[#FF6633] text-xs font-medium">
                      This can be a name that represents other related jobs, such as “Art”, “Design”, “Construction”
                    </p>
                    {/* <BasicSelect
                    placeholder={`Job title here.`}
                    id="jobTitle"
                    name="jobTitle"
                  /> */}
                    <CustomDropdown
                      items={jobCategories}
                      label="Select a job category here"
                      handleChange={handleChange}
                      selectedItem={jobDetails.Category}
                      placeholder="Select a job category here"
                    />
                  </div>
                  <div className="flex-col justify-start items-start gap-2 flex w-full">
                    <div className="flex gap-3 items-center">
                      <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
                      <label
                        className="text-neutral-900 text-base font-medium leading-tight"
                        for="jobTitle"
                      >
                        Job Description
                      </label>
                    </div>
                    <p className="text-[#FF6633] text-xs font-medium">
                      A description of this role.
                    </p>
                    <TextEditor
                      onCut={(e) => e.preventDefault()}
                      onCopy={(e) => e.preventDefault()}
                      onKeyDown={handleKeyDown}
                      className="h-56 w-[100%] xl:w-[100%] 2xl:w-[100%] rounded-lg border border-radius border-indigo-400 border-opacity-30   focus:outline-none focus:border-2 focus:border-indigo-400 placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal placeholder:align-top"
                      style={{ resize: "none" }}
                      placeholder="0/300"
                      value={jobDetails.Description}
                      type="text"
                      id="jobDescription"
                      name="Description"
                      onChange={descriptionChangeHandler}
                      required
                    />
                  </div>
                  <div className="flex-col justify-start items-start gap-2 flex w-full">
                    <div className="flex gap-3 items-center">
                      <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
                      <label
                        className="text-neutral-900 text-base font-medium leading-tight"
                        for="jobTitle"
                      >
                        Key Responsibilities
                      </label>
                    </div>
                    <p className="text-[#FF6633] text-xs font-medium">
                      The responsibilities of the prospective employee.
                    </p>
                    <TextEditor
                      onCut={(e) => e.preventDefault()}
                      onCopy={(e) => e.preventDefault()}
                      onKeyDown={handleKeyDown}
                      className="h-56 w-[100%] xl:w-[100%] 2xl:w-[100%] rounded-lg border border-radius border-indigo-400 border-opacity-30   focus:outline-none focus:border-2 focus:border-indigo-400 placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal placeholder:align-top"
                      style={{ resize: "none" }}
                      placeholder="0/300"
                      value={jobDetails.Responsibilities}
                      type="text"
                      id="keyResponsibilities"
                      name="Responsibilities"
                      onChange={responsibilitiesChangeHandler}
                      editable={true}
                    />
                  </div>
                  <div className="flex-col justify-start items-start gap-2 flex w-full">
                    <div className="flex gap-3 items-center">
                      <div className="px-1 w-2 h-2 rounded-full bg-black"></div>
                      <label
                        className="text-neutral-900 text-base font-medium leading-tight"
                        for="jobTitle"
                      >
                        Special Benefits
                      </label>
                    </div>
                    <p className="text-[#FF6633] text-xs font-medium">
                    Are there any special benefits of working with you? e.g free lunch, health insurance, etc.
                    </p>
                    <TextEditor
                      onCut={(e) => e.preventDefault()}
                      onCopy={(e) => e.preventDefault()}
                      onKeyDown={handleKeyDown}
                      className="h-56 w-[100%] xl:w-[100%] 2xl:w-[100%] rounded-lg border border-radius border-indigo-400 border-opacity-30   focus:outline-none focus:border-2 focus:border-indigo-400 placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal placeholder:align-top"
                      style={{ resize: "none" }}
                      placeholder="0/300"
                      value={jobDetails.Benefits}
                      type="text"
                      id="Benefits"
                      name="Benefits"
                      onChange={benefitChangeHandler}
                      editable={true}
                    />
                  </div>
                  <div className="flex items-center ">
                    <div className="m-4 flex items-center">
                      <input type="checkbox" name="E-verify" id="verify" />
                      <label className="ml-2 text-neutral-900 text-base font-medium leading-tight" for="verify">E-verify</label>
                    </div>
                    <div className="m-4 flex items-center">
                      <input type="checkbox" name="Does not E-verify" id="nverify" />
                      <label className="ml-2 text-neutral-900 text-base font-medium leading-tight" for="nverify">Does not E-verify</label>
                    </div>
                  </div>
                </div>

                <div className="self-stretch justify-start gap-4 flex">
                  <button
                    type="submit"
                    className="px-5 py-3 bg-[#5843BD] rounded-lg shadow self-end justify-center items-start gap-2.5 flex w-full sm:max-w-[200px]"
                  >
                    <span className="text-center text-white text-base font-semibold whitespace-nowrap">
                      <>{save ? "Update and continue" :
                        <>Save and Continue</>}</>
                    </span>
                  </button>
                </div>
              </form>
          </div>
        </div>
      </div>
      {loading === true ? (
        <>
          {gpt_help.message !== null ? null : (
            <LoaderModal text="Our AI tool is generating job description, key responsibilities and skills for you." />
          )}
        </>
      ) : null}
      <ToastContainer />
      {showModal}
    </>
  );
}
