export function PositionSvg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1176_6424)">
        <path
          d="M14.6664 1.77777H1.33312C1.21524 1.77777 1.1022 1.8246 1.01885 1.90795C0.935497 1.9913 0.888672 2.10434 0.888672 2.22222V2.96444C0.888755 3.06941 0.909611 3.17333 0.950039 3.2702C0.990467 3.36708 1.04967 3.45499 1.12423 3.52888L6.22201 8.70222V13.2355L7.11089 13.5733V8.44444C7.11123 8.38595 7.10002 8.32796 7.0779 8.27381C7.05579 8.21966 7.0232 8.17041 6.98201 8.12888L1.77756 2.92888V2.66666H14.222V2.93777L9.03534 8.12888C8.99092 8.16891 8.95504 8.21749 8.92984 8.27171C8.90464 8.32593 8.89064 8.38468 8.88867 8.44444V14.3155L9.77756 14.6667V8.66666L14.8753 3.55555C14.9511 3.47978 15.011 3.38965 15.0514 3.29043C15.0919 3.19121 15.1121 3.08491 15.1109 2.97777V2.22222C15.1109 2.10434 15.0641 1.9913 14.9807 1.90795C14.8974 1.8246 14.7843 1.77777 14.6664 1.77777Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_1176_6424">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function JobTypeSvg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 8.5V14H1.5V8.5M8 11V9M10 4C10 4 10 2 8 2C6 2 6 4 6 4M1 4H15V8C15 8 12 10 8 10C4 10 1 8 1 8V4Z"
        stroke="#CCCCCC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function JobLocationSvg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 9C7.50555 9 7.0222 8.85338 6.61108 8.57868C6.19995 8.30397 5.87952 7.91352 5.6903 7.45671C5.50108 6.99989 5.45157 6.49723 5.54804 6.01228C5.6445 5.52732 5.8826 5.08187 6.23223 4.73223C6.58187 4.3826 7.02732 4.1445 7.51228 4.04804C7.99723 3.95157 8.49989 4.00108 8.95671 4.1903C9.41353 4.37952 9.80397 4.69995 10.0787 5.11108C10.3534 5.5222 10.5 6.00555 10.5 6.5C10.4992 7.1628 10.2356 7.79822 9.76689 8.26689C9.29822 8.73556 8.6628 8.99921 8 9ZM8 5C7.70333 5 7.41332 5.08797 7.16665 5.2528C6.91997 5.41762 6.72771 5.65189 6.61418 5.92598C6.50065 6.20007 6.47095 6.50167 6.52882 6.79264C6.5867 7.08361 6.72956 7.35088 6.93934 7.56066C7.14912 7.77044 7.41639 7.9133 7.70737 7.97118C7.99834 8.02906 8.29994 7.99935 8.57403 7.88582C8.84812 7.77229 9.08238 7.58003 9.24721 7.33336C9.41203 7.08668 9.5 6.79667 9.5 6.5C9.4996 6.1023 9.34144 5.721 9.06022 5.43978C8.779 5.15856 8.3977 5.0004 8 5Z"
        fill="#CCCCCC"
      />
      <path
        d="M8.00001 15L3.78201 10.0255C3.7234 9.95081 3.66539 9.87564 3.60801 9.8C2.8875 8.85089 2.49826 7.69161 2.50001 6.5C2.50001 5.04131 3.07947 3.64236 4.11092 2.61091C5.14237 1.57946 6.54132 1 8.00001 1C9.4587 1 10.8576 1.57946 11.8891 2.61091C12.9205 3.64236 13.5 5.04131 13.5 6.5C13.5018 7.69107 13.1127 8.84982 12.3925 9.7985L12.392 9.8C12.392 9.8 12.242 9.997 12.2195 10.0235L8.00001 15ZM4.40601 9.1975C4.40701 9.1975 4.52301 9.3515 4.54951 9.3845L8.00001 13.454L11.455 9.379C11.477 9.3515 11.594 9.1965 11.5945 9.196C12.1831 8.42056 12.5012 7.47352 12.5 6.5C12.5 5.30653 12.0259 4.16193 11.182 3.31802C10.3381 2.47411 9.19348 2 8.00001 2C6.80653 2 5.66194 2.47411 4.81803 3.31802C3.97411 4.16193 3.50001 5.30653 3.50001 6.5C3.49896 7.47412 3.81689 8.42171 4.40601 9.1975Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}

export function RecruitmentDurationSvg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 2.00002V0.666687H10V2.00002H6ZM7.33333 9.33335H8.66667V5.33335H7.33333V9.33335ZM8 14.6667C7.17778 14.6667 6.40267 14.5082 5.67467 14.1914C4.94667 13.8745 4.31067 13.444 3.76667 12.9C3.22222 12.3556 2.79156 11.7194 2.47467 10.9914C2.15778 10.2634 1.99956 9.48847 2 8.66669C2 7.84446 2.15845 7.06935 2.47533 6.34135C2.79222 5.61335 3.22267 4.97735 3.76667 4.43335C4.31111 3.88891 4.94733 3.45824 5.67533 3.14135C6.40333 2.82446 7.17822 2.66624 8 2.66669C8.68889 2.66669 9.35 2.7778 9.98333 3.00002C10.6167 3.22224 11.2111 3.54446 11.7667 3.96669L12.7 3.03335L13.6333 3.96669L12.7 4.90002C13.1222 5.45558 13.4444 6.05002 13.6667 6.68335C13.8889 7.31669 14 7.9778 14 8.66669C14 9.48891 13.8416 10.264 13.5247 10.992C13.2078 11.72 12.7773 12.356 12.2333 12.9C11.6889 13.4445 11.0527 13.8751 10.3247 14.192C9.59667 14.5089 8.82178 14.6671 8 14.6667ZM8 13.3334C9.28889 13.3334 10.3889 12.8778 11.3 11.9667C12.2111 11.0556 12.6667 9.95558 12.6667 8.66669C12.6667 7.3778 12.2111 6.2778 11.3 5.36669C10.3889 4.45558 9.28889 4.00002 8 4.00002C6.71111 4.00002 5.61111 4.45558 4.7 5.36669C3.78889 6.2778 3.33333 7.3778 3.33333 8.66669C3.33333 9.95558 3.78889 11.0556 4.7 11.9667C5.61111 12.8778 6.71111 13.3334 8 13.3334Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}

export  function GamefiedVector() {
  return (
    <svg width="660" height="420" viewBox="0 0 660 420" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M217.44 348.928C101.198 318.13 -12.6713 410.524 -19.1951 420L-42.3248 408.747L-53 -15.3149L133.817 -116C363.137 -106.129 823.082 -85.4391 828.301 -81.6486C834.824 -76.9105 793.903 212.707 780.262 243.505C766.622 274.303 713.838 212.707 698.419 212.707C682.999 212.707 566.757 263.642 531.173 348.928C495.589 434.214 333.681 379.726 217.44 348.928Z" fill="url(#paint0_linear_7576_2021)" />
      <defs>
        <linearGradient id="paint0_linear_7576_2021" x1="388" y1="-116" x2="388" y2="1195.87" gradientUnits="userSpaceOnUse">
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="1" stop-color="#5843BD" />
        </linearGradient>
      </defs>
    </svg>
  )
}
