import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../Redux/baseURL';import { getEmployerProfile, getting_employer_profile_successful } from "../../Redux/ActionCreator";
import { useDispatch, useSelector } from "react-redux";

const AddPaymentMethod = ({ setUpdatePaymentMtd }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error(error);
    } else {
      const response = await fetch(`${baseURL}/api/stripe/add-payment-method`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ paymentMethodId: paymentMethod.id }),
        credentials: "include"
      });

      const data = await response.json();
      console.log('New payment method added:', data);

      if (data.success == true) {
        toast.success("Payment Informations updated successfully.", {
            position: toast.POSITION.TOP_CENTER,
        });

        // dispatch employer info
        dispatch(getting_employer_profile_successful(data.data.employer));
        setTimeout(() => {
            navigate("/employer/subscribe");
        }, 100);
        setUpdatePaymentMtd(false);
      }
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
        <div style={{ maxWidth: '400px' }} className=" h-[35px] text-white text-md mb-2">
            <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>
      <button type="submit" className="text-base cursor-pointer focus:text-white active:text-white hover:text-white leading-normal w-full md:min-w-[190px] flex justify-center items-center py-3 dash-link-grad text-center text-white font-medium rounded-lg" disabled={!stripe}>
        Add Payment Method
      </button>
      <ToastContainer />
    </form>
  );
}

export default AddPaymentMethod;