import React, { useEffect, useState } from "react"
import { Document, Page, StyleSheet, View, Text, PDFViewer, Svg, Line, Image, Link, Circle } from '@react-pdf/renderer';


// require fonts


import font from "./font/Gilroy-Medium.ttf";
import font1 from "./font/Gilroy-Regular.ttf";
import font2 from "./font/Gilroy-SemiBold.ttf";
import { Font } from '@react-pdf/renderer';

Font.register({ family: 'gilroy-medium', src: font });
Font.register({ family: 'gilroy-regular', src: font1 });
Font.register({ family: 'gilroy-semiBold', src: font2 });


// require useSelector from react-redux
import { useSelector } from "react-redux";


const styles = StyleSheet.create({
  page: {
    fontFamily: "Inter-regular",
    paddingTop: 30,
    paddingBottom: 35,
  },
  viewer: {
    width: '65vw', height: '100vh',
    '@media maxWidth: 500': {
      width: "80vw", height: "auto",
      overFlow: "scroll"
    }
  },
  leftside: {
    left: 30,
    fontFamily: "gilroy-medium",
    marginTop: 65,
    width: 250,
  },
  rightSide: {
    width: 250,
    fontFamily: "gilroy-medium",
    marginTop: 65,
    paddingRight: 30,
    left: 50,
    marginRight: 20
  }
});


const SamCv = (props) => {
  // console.log("props", props.details);
  let details;
  details = [props.details]
  const [wrap_stat, setWrap_stat] = useState(false);
  useEffect(() => {
    if (details[0].Experience.length >= 2) {
      setWrap_stat(true);
    } else {
      setWrap_stat(false);
    }
  }, [])

  let my_interest;
  if (details[0] !== null) {
    if (Array.isArray(details[0]?.Interest)) {
      my_interest = details[0]?.Interest;
    } else if (typeof details[0]?.Interest === "string") {
      my_interest = details[0]?.Interest.split(", ");
    }
  }
  if (Array.isArray(details[0]?.Technical_skills) === false) {
    if (typeof details[0]?.Technical_skills === "string") {
      details[0].Technical_skills = details[0]?.Technical_skills.split(", ");
    }
  }

  if (Array.isArray(details[0]?.Personal_skills) === false) {
    if (typeof details[0]?.Personal_skills === "string") {
      details[0].Personal_skills = details[0]?.Personal_skills.split(", ");
    }
  }

  const chunkSubstr = (str, size) => {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }

    return chunks;
  };

  Font.registerHyphenationCallback((word) => { // to avoid breaking of words.
    if (word.length > 16) {
      return chunkSubstr(word, 14);
    } else {
      return [word];
    }
  });



  return (
    /*     <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
          {details[0] === null ? null :
          <div>
            <PDFViewer style={styles.viewer}> */

    <Document style={styles.page} >
      <Page size="A4" style={styles.page} >
        {details?.map((data, key) => (
          <View>
            {/* header start */}
            <View style={{ left: 30, top: 10 }}>
              <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 300 }} >
                <Text style={{ color: "#FF6633", fontFamily: "gilroy-semiBold", fontSize: 27 }} key={key}>{data.Fullname} </Text>
              </View>

              {data.CvType === null || data.CvType === undefined ? null : <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 300 }} >
                <Text style={{ color: "#000000", fontFamily: "gilroy-medium", fontSize: 14 }}>{data.CvType} </Text>
              </View>}

              <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 10, top: 10 }}>
                <View style={{ width: 14, height: 14, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: 5 }}>
                  <Image src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704123/Website%20images/icons8-mail-24_1_xakt8v.png" style={{ width: 12, height: 12 }} />
                </View>
                <View>
                  <Text style={{ fontFamily: "gilroy-medium", fontSize: "10px" }}><Link src={data.Email} style={{ textDecoration: "underline", color: "#000000" }}>{data.Email}</Link></Text>
                </View>
              </View>

              <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 10, top: 20 }}>
                <View style={{ width: 14, height: 14, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: 5 }}>
                  <Image src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704126/Website%20images/icons8-phone-24_tysfjb.png" style={{ width: 12, height: 12 }} />
                </View>
                <View>
                  <Text style={{ fontFamily: "gilroy-medium", fontSize: "10px" }}><Link src="" style={{ textDecoration: "underline", color: "#000000" }}>{data.Phone_number}</Link></Text>
                </View>
              </View>

              <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 10, top: 30 }}>
                <View style={{ width: 14, height: 14, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: 5 }}>
                  <Image src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704122/Website%20images/icons8-location-24_jkl8ho.png" style={{ width: 12, height: 12 }} />
                </View>
                <Text style={{ fontFamily: "gilroy-medium", fontSize: "10px" }}>{data.Address}</Text>
              </View>
            </View>
            {/* header end */}

            {/* general view block */}
            <View style={{ display: "flex", flexDirection: "row" }}>

              {/* left side start */}
              <View style={styles.leftside}>
                {/* About me start */}
                <View style={{ width: 250 }}>
                  {
                    data.Lang[0]?.lang === 'English' ?
                      <Text style={{ fontFamily: "gilroy-medium", fontSize: "14px", color: "#0D0D0D" }}>About me</Text> : <Text style={{ fontFamily: "gilroy-medium", fontSize: "14px", color: "#0D0D0D" }}>Sobre mí</Text>
                  }
                  <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: 5 }} >
                    <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", lineHeight: '1.5px' }} key={key}>{data.Professional_summary}</Text>
                  </View>
                </View>
                {/* About me end */}

                {/* Skills start */}
                {data.Technical_skills?.length !== 0 || data.Personal_skills?.length !== 0 ?
                  <View style={{ width: 250, marginTop: 10 }}>
                    {
                      data.Lang[0]?.lang === 'English' ?
                        <Text style={{ fontFamily: "gilroy-medium", fontSize: "14px", color: "#0D0D0D" }}>Skills</Text> : <Text style={{ fontFamily: "gilroy-medium", fontSize: "14px", color: "#0D0D0D" }}>Habilidades</Text>
                    }
                    <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 250, marginTop: 5 }}>
                      <View style={{ width: 125 }}>
                        {
                          data.Technical_skills?.length === 0 ? null :
                            <View style={{ marginTop: 0 }}>
                              {
                                data.Lang[0]?.lang === 'English' ?
                                  <Text style={{ fontFamily: "gilroy-medium", fontSize: "10px", color: "#0D0D0D" }}>Technical Skills</Text> : <Text style={{ fontFamily: "gilroy-medium", fontSize: "14px", color: "#0D0D0D", marginBottom: 5 }}>Habilidades Técnicas </Text>
                              }
                              <View style={{ marginTop: 5, display: 'flex', flexDirection: 'column', gap: '2px' }}>
                                {/* Technical skills */}
                                {data.Technical_skills?.map((T_data, key) => (
                                  <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", }} key={key}>
                                    <View style={{ display: "flex", flexDirection: "row", }}>
                                      <Text style={{ marginHorizontal: 0, marginTop: 0 }}>•</Text>
                                      {T_data.value !== undefined ? <Text style={{ marginLeft: 5, marginTop: 2, fontFamily: "gilroy-medium", width: 100, fontSize: 10, color: "#686868" }}>{T_data.value}</Text> : <Text style={{ marginLeft: 5, marginTop: 2, fontFamily: "gilroy-medium", width: 100, fontSize: 10, color: "#686868" }}>{T_data}</Text>}
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                        }
                      </View>
                      <View style={{ width: 125 }}>
                        {
                          data.Personal_skills?.length === 0 ? null :
                            <View style={{ marginTop: 0 }}>
                              {
                                data.Lang[0]?.lang === 'English' ?
                                  <Text style={{ fontFamily: "gilroy-medium", fontSize: "10px", color: "#0D0D0D" }}>Personal Skills</Text> : <Text style={{ fontFamily: "gilroy-medium", fontSize: "14px", color: "#0D0D0D", marginTop: -15, marginBottom: 5 }}>Habilidades personales</Text>
                              }
                              <View style={{ marginTop: 5, display: 'flex', flexDirection: 'column', gap: '2px' }}>
                                {data.Personal_skills?.map((P_data, key) => (
                                  <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center" }} key={key}>
                                    <View style={{ display: "flex", flexDirection: "row", }}>
                                      <Text style={{ marginHorizontal: 0, marginTop: -2 }}>•</Text>
                                      {P_data.value !== undefined ? <Text style={{ marginLeft: 5, marginTop: 2, fontFamily: "gilroy-medium", width: 110, fontSize: 10, color: "#686868" }}>{P_data.value}</Text> : <Text style={{ marginLeft: 5, marginTop: 7, fontFamily: "gilroy-medium", width: 110, fontSize: 10, color: "#686868" }}>{P_data}</Text>}
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                        }
                      </View>
                    </View>
                  </View> : null}
                {/* Skills end */}

                {/* Education start */}
                {Array.isArray(data.Education) === false ? null :
                  <View>
                    {data.Education?.length === 0 ? null :
                      <View style={{ width: 250, marginTop: 10 }}>
                        {
                          data.Lang[0]?.lang === 'English' ?
                            <Text style={{ fontFamily: "gilroy-medium", fontSize: "14px", color: "#0D0D0D" }}>Education</Text> : <Text style={{ fontFamily: "gilroy-medium", fontSize: "14px", color: "#0D0D0D" }}>Educación</Text>
                        }
                        <View style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                          {data.Education?.map((E_data, key) => (
                            <View style={{ marginTop: 5 }}>
                              <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                {E_data.End_date === undefined ? <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", marginTop: 0 }}>{data.Lang[0]?.lang === "English" ? <Text>Present</Text> : <Text>Presente</Text>}</Text> : <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", marginTop: 0 }}>{E_data.End_date}</Text>}
                              </View>
                              <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 250 }}>
                                <Text style={{ fontFamily: "gilroy-semiBold", fontSize: 10, color: "#000000", marginTop: 2 }} key={key}>{E_data.Degree} {E_data.Course}</Text>
                              </View>
                              <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 250 }} >
                                <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", marginTop: 2 }} key={key}>{E_data.School} </Text>
                              </View>
                            </View>
                          ))}
                        </View>
                      </View>
                    }
                  </View>}
                {/* Education end */}

                {/* Award start */}
                {(data.Award?.length !== 0 && data.Award[0]?.Award_name.length === 0 || data.Award?.length === 0) ? null :
                  <View>
                    {data.Award[0]?.Award_name.length === 0 ? null :
                      <View style={{ width: 220, marginTop: 10 }}>
                        {
                          data.Lang[0]?.lang === 'English' ?
                            <Text style={{ fontFamily: "gilroy-medium", fontSize: "14px", color: "#0D0D0D" }}>Award</Text> : <Text style={{ fontFamily: "gilroy-medium", fontSize: "14px", color: "#0D0D0D" }}>Premio</Text>
                        }
                        <View style={{ display: "flex", flexDirection: "column", gap: "5px", marginTop: 5 }}>
                          {data.Award?.map((A_data, key) => (
                            <View>
                              <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", marginTop: 2 }}>{A_data.Award_name}</Text>
                                <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", marginTop: 2 }}>{A_data.Award_year}</Text>
                              </View>
                              <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", marginTop: 2 }}>{A_data.Award_giver}</Text>
                            </View>
                          ))}
                        </View>
                      </View>
                    }
                  </View>
                }
                {/* Award end */}
              </View>
              {/* left side end */}
              {/*     
                  <View style={{position: "absolute", marginTop: 65, left: 200,}}>
                    <Svg  height="800" width="2" style={{left: 100, }} >
                      <Line
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="580"
                        strokeWidth={2}
                        stroke="#ECECEC"
                      />
                    </Svg>
                  </View>
     */}
              {/* right side start */}
              <View style={styles.rightSide}>
                {data.Experience?.length === 0 ? null :
                  <View>
                    {
                      data.Lang[0]?.lang === 'English' ?
                        <Text style={{ fontFamily: "gilroy-medium", fontSize: "14px", color: "#0D0D0D" }}>Past Experience</Text> : <Text style={{ fontFamily: "gilroy-medium", fontSize: "14px", color: "#0D0D0D" }}>Experiencia Pasada</Text>
                    }
                    <View style={{ marginTop: 0, width: 250 }}>

                      {data.Experience?.map((Ex_data, key) => (
                        <View style={{ marginBottom: 10, marginTop: 5 }} key={key}>
                          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 250 }}>
                            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 150 }}>
                              <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#000000", }} key={key}>{Ex_data.Where_i_worked} </Text>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", width: 100 }}>
                              <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", }}>{Ex_data.Work_start_date} -  </Text>
                              {Ex_data.Work_end_date !== null ? <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", }}>  {Ex_data.Work_end_date}</Text> : <Text>{data.Lang[0].lang === "English" ? <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", }}> Present</Text> : <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", }}>Presente</Text>}</Text>}
                            </View>
                          </View>
                          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 250, marginBottom: 5 }}>
                            <Text style={{ fontFamily: "gilroy-semiBold", fontSize: 10, color: "#000000", marginTop: 2 }}>{Ex_data.My_job_title} </Text>
                          </View>

                          {Array.isArray(Ex_data.Day_to_day_work) === false ? <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", marginTop: 5 }}>{Ex_data.Day_to_day_work}</Text> :
                            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                              {Ex_data.Day_to_day_work?.map((ex_data, key) => (
                                <View style={{ display: "flex", flexDirection: "row" }} key={key} >
                                  <View>
                                    <Text style={{ marginRight: 8, marginTop: -2 }}>•</Text>
                                  </View>
                                  {ex_data[0] === "-" ? <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", marginTop: 2, lineHeight: '1.5px' }}>{ex_data.slice(1, ex_data?.length).replace(/^(\d[^\w\s]*|[^\w\s])+/g, '')}</Text> : <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", marginTop: 2, lineHeight: '1.5px' }}>{ex_data.replace(/^(\d[^\w\s]*|[^\w\s])+/g, '')}</Text>}
                                </View>
                              ))}
                            </View>
                          }
                        </View>
                      ))}

                    </View>
                  </View>
                }

                {/* Language start */}
                {data.Language?.length === 0 ? null :
                  <View style={{ width: 250, marginTop: 10 }}>
                    {
                      data.Lang[0]?.lang === 'English' ?
                        <Text style={{ fontFamily: "gilroy-medium", fontSize: "14px", color: "#0D0D0D" }}>Language</Text> : <Text style={{ fontFamily: "gilroy-medium", fontSize: "14px", color: "#0D0D0D" }}>Idiomas</Text>
                    }
                    <View style={{ marginTop: 5 }}>
                      {data.Language?.map((L_data, key) => (
                        <View>
                          <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 2 }}>
                            <Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", }}>{L_data.language} - </Text><Text style={{ fontFamily: "gilroy-medium", fontSize: 10, color: "#686868", }}>{L_data.rate}</Text>
                          </View>
                        </View>
                      ))}
                    </View>
                  </View>}
                {/* Language end */}
              </View>
              {/* right side end */}
            </View>

            <View style={{ position: "relative", marginTop: 40 }}>
              <Svg height="2" width="600" style={{ left: 30, top: 0 }} >
                <Line
                  x1="0"
                  y1="0"
                  x2="530"
                  y2="0"
                  strokeWidth={2}
                  stroke="#ECECEC"
                />
              </Svg>
            </View>
          </View>


        ))}
      </Page>
    </Document>
  )
}

export default SamCv;