import React, { useState } from "react"
import BackButton from "../common/back_button";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import HelmetProvide from "../common/helmet";
import UploadJobDoc from "./updload";


const UploadDoc = (props) => {

  const navigate = useNavigate();

  const [ uploadStatus, setUploadStatus ] = useState(false);

  // console.log("uploadStatus", uploadStatus);

  let uploadModal = uploadStatus ? (<UploadJobDoc status={setUploadStatus} />) : null;

  return (
    <>
     <HelmetProvide
        title="Moil | Upload"
        description="Upload a job description document to fast track creating a job on Moil"
      />
    <div className="w-full overflow-y-scroll h-calc-150 md:h-calc-70 bg-transparent flex justify-center ">
      <div className="w-full flex px-6 py-4 md:p-0 md:items-center  md:justify-center">
        <div className="rounded-[24px] md:self-center flex flex-col gap-y-6 w-full max-w-[650px]">
          <div className="bg-[#5843BD] hidden md:block  py-4 header-border rounded-tl-[24px] rounded-tr-[24px]">
            <p className="text-white text-[24px] leading-normal font-semibold text-center">Post a Job</p>
          </div>

          <div className="md:hidden">
            <BackButton onClick={() => navigate('/employer/dashboard')} />
          </div>

          <p className="text-black text-[20px] md:self-center leading-[1.2] max-w-[330px] text-black font-medium md:text-center">To post a job, select any of the following options.</p>

          <div className="flex flex-col md:flex-row items-center pt-6 md:pt-0 pb-6 gap-6 px-6 ">
            <div className="border border-point-5 flex flex-col max-w-[300px] rounded-xl px-[18px] py-8 border-[#5843BD] relative">
              <div className="bg-[#5843BD] w-[36px] h-[36px] flex justify-center items-center absolute left-[50%] translate-x-[-50%] rounded-[50%] top-[-18px]">
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3763 2.08333C12.3763 2.02808 12.3544 1.97509 12.3153 1.93602C12.2762 1.89695 12.2232 1.875 12.168 1.875H6.33464C5.72685 1.875 5.14395 2.11644 4.71418 2.54621C4.28441 2.97598 4.04297 3.55888 4.04297 4.16667V15.8333C4.04297 16.4411 4.28441 17.024 4.71418 17.4538C5.14395 17.8836 5.72685 18.125 6.33464 18.125H14.668C15.2758 18.125 15.8587 17.8836 16.2884 17.4538C16.7182 17.024 16.9596 16.4411 16.9596 15.8333V7.6225C16.9596 7.56725 16.9377 7.51426 16.8986 7.47519C16.8595 7.43612 16.8066 7.41417 16.7513 7.41417H13.0013C12.8355 7.41417 12.6766 7.34832 12.5594 7.23111C12.4422 7.1139 12.3763 6.95493 12.3763 6.78917V2.08333ZM13.0013 10.2083C13.1671 10.2083 13.326 10.2742 13.4432 10.3914C13.5605 10.5086 13.6263 10.6676 13.6263 10.8333C13.6263 10.9991 13.5605 11.1581 13.4432 11.2753C13.326 11.3925 13.1671 11.4583 13.0013 11.4583H8.0013C7.83554 11.4583 7.67657 11.3925 7.55936 11.2753C7.44215 11.1581 7.3763 10.9991 7.3763 10.8333C7.3763 10.6676 7.44215 10.5086 7.55936 10.3914C7.67657 10.2742 7.83554 10.2083 8.0013 10.2083H13.0013ZM13.0013 13.5417C13.1671 13.5417 13.326 13.6075 13.4432 13.7247C13.5605 13.8419 13.6263 14.0009 13.6263 14.1667C13.6263 14.3324 13.5605 14.4914 13.4432 14.6086C13.326 14.7258 13.1671 14.7917 13.0013 14.7917H8.0013C7.83554 14.7917 7.67657 14.7258 7.55936 14.6086C7.44215 14.4914 7.3763 14.3324 7.3763 14.1667C7.3763 14.0009 7.44215 13.8419 7.55936 13.7247C7.67657 13.6075 7.83554 13.5417 8.0013 13.5417H13.0013Z" fill="white" />
                  <path d="M13.625 2.35322C13.625 2.19988 13.7858 2.10238 13.905 2.19822C14.0061 2.27988 14.0958 2.37488 14.1742 2.48322L16.685 5.98072C16.7417 6.06072 16.68 6.16405 16.5817 6.16405H13.8333C13.7781 6.16405 13.7251 6.1421 13.686 6.10303C13.6469 6.06396 13.625 6.01097 13.625 5.95572V2.35322Z" fill="white" />
                </svg>
              </div>

              <div className="flex flex-col gap-y-1 mb-4">
                <p className="text-[#5843BD] text-base leading-[1.2] text-[#5843BD] font-semibold text-center">Upload a job document.</p>
                <p className="text-[#A3A3A3] text-sm leading-[1.2] text-[#A3A3A3] font-normal text-center">If you have a document that contains your job information</p>
              </div>

              <button className="bg-[#5843BD] focus:bg-[#5843BD] text-white flex item-center justify-center rounded-xl px-6 py-3 font-semibold text-base leading-normal text-center" onClick={() => setUploadStatus(true)}>Upload job document</button>
            </div>

            <div className="border border-point-5 flex flex-col max-w-[300px] px-[18px]  rounded-xl py-8 border-[#FF6633] relative">
              <div className="bg-[#FF6633] w-[36px] h-[36px] flex justify-center items-center absolute left-[50%] translate-x-[-50%] rounded-[50%] top-[-18px]">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.5739 5.81628C16.8656 5.03294 15.6822 4.64128 13.9656 4.64128H13.7656V4.60794C13.7656 3.20794 13.7656 1.47461 10.6322 1.47461L9.36556 1.47461C6.23222 1.47461 6.23222 3.21628 6.23222 4.60794V4.64961H6.03222C4.30722 4.64961 3.13223 5.04128 2.42389 5.82461C1.59889 6.74128 1.62389 7.97461 1.70723 8.81628L1.71556 8.87461L1.77701 9.51987C1.79086 9.66523 1.86917 9.79657 1.99143 9.8764C2.19331 10.0082 2.50729 10.2098 2.69889 10.3163C2.81556 10.3913 2.94056 10.4579 3.06556 10.5246C4.49056 11.3079 6.05723 11.8329 7.64889 12.0913C7.72389 12.8746 8.06556 13.7913 9.89056 13.7913C11.7156 13.7913 12.0739 12.8829 12.1322 12.0746C13.8322 11.7996 15.4739 11.2079 16.9572 10.3413C17.0072 10.3163 17.0406 10.2913 17.0822 10.2663C17.4043 10.0843 17.7381 9.86107 18.0441 9.64118C18.1539 9.56226 18.2241 9.43985 18.239 9.30543L18.2489 9.21628L18.2906 8.82461C18.2989 8.77461 18.2989 8.73294 18.3072 8.67461C18.3739 7.83294 18.3572 6.68294 17.5739 5.81628ZM10.9072 11.5246C10.9072 12.4079 10.9072 12.5413 9.88222 12.5413C8.85722 12.5413 8.85722 12.3829 8.85722 11.5329V10.4829H10.9072L10.9072 11.5246ZM7.42389 4.64128V4.60794C7.42389 3.19128 7.42389 2.66628 9.36556 2.66628H10.6322C12.5739 2.66628 12.5739 3.19961 12.5739 4.60794V4.64961L7.42389 4.64961V4.64128Z" fill="white" />
                  <path d="M17.2795 11.4975C17.623 11.3349 18.0177 11.6074 17.9833 11.9859L17.6953 15.1583C17.5203 16.8249 16.837 18.5249 13.1703 18.5249H6.82031C3.15365 18.5249 2.47031 16.8249 2.29531 15.1666L2.02226 12.163C1.98825 11.789 2.37459 11.5171 2.71714 11.6712C3.67571 12.1025 5.31175 12.8076 6.35601 13.0875C6.51523 13.1302 6.64436 13.2446 6.71614 13.393C7.22889 14.453 8.31948 15.0166 9.88698 15.0166C11.439 15.0166 12.543 14.4312 13.0577 13.3682C13.1296 13.2197 13.2586 13.1054 13.4179 13.0624C14.5283 12.7631 16.2746 11.973 17.2795 11.4975Z" fill="white" />
                </svg>

              </div>

              <div className="flex flex-col gap-y-1 mb-4">
                <p className="text-[#5843BD] text-base leading-[1.2] text-[#FF6633] font-semibold text-center">Create job from scratch</p>
                <p className="text-[#A3A3A3] text-sm leading-[1.2]  text-[#A3A3A3] font-normal text-center">Enter your company name and job title. Let our AI handle the rest.</p>
              </div>

              <button className="bg-[#FF6633] focus:bg-[#FF6633] text-white flex item-center justify-center rounded-xl px-6 py-3 font-semibold text-base leading-normal text-center" onClick={() => navigate("/employer/create-job")}>Create from scratch</button>
            </div>

          </div>
        </div>
      </div>
    </div>
    {uploadModal}
    <ToastContainer />
    </>
  )
}

export default UploadDoc;