import React, { useState, useContext, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  signInFunction,
  officialGoogleAuth,
  clearLogin,
  login_loading,
  savelink
} from "../../Redux/ActionCreator";
import HelmetProvide from "../common/helmet";
import FormLogin from "../common/login_form";
import TranslateBtnDropdown from "../common/translateBTN";
import { baseURL1 } from "../../Redux/baseURL";
import { useAnalytics } from "../../context/AnalyticsContext";
import HomeButton from "../common/HomeButton/homeButton";

const HireLogin = () => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  
  const user_unique_link = new URLSearchParams(window.location.search).get('link');
  const login_status = useSelector((stat) => stat.login);
  const [checked1, setChecked1] = React.useState(true);
  const [checked2, setChecked2] = React.useState(false);
  const [hide, setHide] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  let flow = queryParams.get("flowId");
  localStorage.setItem("subflow", flow);
  // create a state to handle the form value
  const [cred, setCred] = useState({
    username: "",
    password: "",
    AcctType: "Official"
  });
  const account_stat = new URLSearchParams(window.location.search).get("auth");
  

  // inintialize the useDispatch hook
  const dispatch = useDispatch();

  useEffect(() => {
    if (user_unique_link !== null) {
        dispatch(savelink(baseURL1 + user_unique_link));
    }
    // translateButton();
  }, [])

  useEffect(() => {
    dispatch(clearLogin());
    if (queryParams.get('ref') !== null) {
      localStorage.clear();
      localStorage.setItem("ref", queryParams.get('ref'));
    } else {
      let ref = localStorage.getItem("ref");
      localStorage.clear();
      localStorage.setItem("ref", ref);
    }
    localStorage.setItem("subflow", flow);

    if (account_stat === "clientAcct") {
      setTimeout(() => {
        toast.error("This account has been registered as a client account.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
      // dispatch(
      //   login_failed("This account have been registered as a client account.")
      // );

      dispatch(login_loading());
    }

    if (login_status.errMess !== null) {
      setTimeout(() => {
        toast.error(login_status.errMess, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);

      dispatch(login_loading());
    }
    if (login_status.message !== null) {
      setTimeout(() => {
        toast.success(login_status.message?.status, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);

      dispatch(login_loading());
    }
  }, [account_stat, login_status.errMess, login_status.message]);

  // submit function
  const handleSubmit = () => {
    if (cred.username === "") {
      setTimeout(() => {
        toast.error("Username is required", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else if (cred.password === "") {
      setTimeout(() => {
        toast.error("Password is required", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
      dispatch(signInFunction(cred));
      localStorage.removeItem("ref");
      setCred((prev) => ({ ...prev, username: "", password: "" }));
    }
    analytics.track("Login", {
      Type: "Employer login",
      time: new Date(),
      Email: cred.username
    });
  };

  const screenheight = window.innerHeight;

  const heighting = screenheight >= 755 ? "md:top-[50%] md:translate-y-[-50%]" : "md:top-[35px]";

  return (
    <>
      <HelmetProvide
        title="Moil | Talent Recruiting Platform"
        description="Moil is your partner in finding the best blue-collar talent. We provide tools to help workers showcase their skills and connect them with recruiters on our platform."
      />

      <div className="h-screen relative max-w-[100vw] overflow-x-hidden">
        <div className={`absolute z-10 ${heighting} w-full gap-x-4 gap-y-4 justify-center lg:max-w-[876px] xl:max-w-[1024px] left-[50%] translate-x-[-50%] top-[80px] flex lg:gap-x-0 lg:justify-between flex-col md:flex-row items-center px-4`}>
          <div className="w-full">
            <HomeButton className="bg-white p-4 vsm:mb-5 md:mb-0" path="https://moilapp.com/business" text="homepage" />
            <div className="md:flex md:items-center md:justify-between w-full">
              <p className="text-white font-poppins text-[24px] md:text-[32px] font-bold leading-[30px] md:leading-[36px] tracking-normal flex flex-col gap-y-10 max-w-[380px]">Go in there and start posting jobs!
                <span className="font-poppins text-[14px] md:text-[24px] font-[500] leading-[18px] md:leading-[21px] tracking-normal vsm:mb-4 md:mb-0"> Employees are ready to apply
                </span>
              </p>
              <FormLogin type="employer" navigate={navigate} handleSubmit={handleSubmit} username={cred.username} password={cred.password} setCred={setCred} googleLogin={() => dispatch(officialGoogleAuth())} />
            </div>
          </div>
        </div>


        <div className={`w-full h-added-25 ${screenheight > 755 ? "md:h-full " : "md:h-added-25"} grid custom-grid`}>

          <div className="w-full vsm:h-[340px] md:h-full bg-[#5843BD]"></div>


          <div className="relative h-full overflow-hidden -z-1000">
            <svg width="851" height="851" viewBox="0 0 1028 992" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-[-30%] right-[-30%] -z-10">
              <g opacity="0.1" filter="url(#filter0_f_3436_5351)">
                <circle cx="739.5" cy="252.5" r="425.5" fill="#777DEA" />
              </g>
              <defs>
                <filter id="filter0_f_3436_5351" x="0" y="-487" width="1479" height="1479" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feGaussianBlur stdDeviation="157" result="effect1_foregroundBlur_3436_5351" />
                </filter>
              </defs>
            </svg>

            <svg width="1065" height="1065" viewBox="0 0 1201 1024" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute bottom-[-25%] right-[-20%] -z-10">
              <g opacity="0.1" filter="url(#filter0_f_3436_5429)">
                <circle cx="847.5" cy="695.5" r="533.5" fill="#FF6633" />
              </g>
              <defs>
                <filter id="filter0_f_3436_5429" x="0" y="-152" width="1695" height="1695" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feGaussianBlur stdDeviation="157" result="effect1_foregroundBlur_3436_5429" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default HireLogin;
