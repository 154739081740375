import React, { useState, useEffect } from "react";
// import AddedItem from "../../Resume/added_item/added_item";
import Skillsbtn from "../common/Skillsbtn";
import ReadMoreText from "../common/ReadMore";
import LoaderModal from "../common/LoaderModal";
import { ToastContainer, toast } from "react-toastify";
import { sendDecisionCard, sendMessage, returnHome } from "../../Redux/ActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../Redux/baseURL";
import CustomDate from "../common/custom_date";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import BackButton from "../common/back_button";
import { nanoid } from "nanoid";
import TextEditor from "../common/textarea/NewTextArea"
import { handleKeyDown } from "../../utils/keydown/keydown";

function EmployerDecisionCardRedesign() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobLocation, setJobLocation] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [compensationInfo, setCompensationInfo] = useState("");
  const chat_info = useSelector((e) => e.chat_info.message);
  const index = new URLSearchParams(window.location.search).get("index");
  const view = new URLSearchParams(window.location.search).get("view");
  const [cardInfo, setCardInfo] = useState();
  const decisionCard = useSelector((e) => e.decisionCard);
  const params = useParams().receiverId;
  const [hide, setHide] = useState(false);
  const employer = useSelector(e => e.employer);
  const publicInfo = useSelector(e => e.public);
  const publicProfile = publicInfo.message?.profile;
  const socket = useSelector(e => e.socket?.message);
  const newMessage = useSelector(e => e.end_to_end.message);

  useEffect(() => {
    if (chat_info !== null) {
      initializeDecisionCard({
        employerId: chat_info[index]?.chatIds[0]?.employerId,
        employeeId: chat_info[index]?.chatIds[0]?.employeeId,
        jobId: chat_info[index]?.chatIds[0]?.jobId,
      });
    }
  }, []);

  useEffect(() => {
    if (view === "true") {
      setHide(true);
      $('[name="startingDateSelect"]').val(decisionCard?.message?.start_date);
      $('[name="additionalInfo"]').val(cardInfo?.JobId?.additional_info);
      // setAdditionalInfo(decisionCard?.message?.additional_info);
      setJobLocation(decisionCard.message?.location);
    }
  }, [view]);

  const initializeDecisionCard = async (cred) => {
    // console.log("cred", cred);
    const obj = {
      employerId: cred?.employerId,
      employeeId: cred?.employeeId?._id,
      jobId: cred?.jobId,
    };
    // console.log("obj", obj);
    fetch(baseURL + "/api/decision/init", {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("token"),
      },
      credentials: "include"
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success === true) {
          // console.log("Decision card initialized successfully", resp);
          setCardInfo(resp.data);
        } else if (resp.success === false && resp.message == "Unauthorized") {
          dispatch(returnHome(resp.status));
        }
      });
  };
  // console.log("card Info", cardInfo);

  useEffect(() => {
    if (cardInfo !== undefined) {
      $('[name="compensationSelect"]').val(cardInfo?.JobId?.compensation);
      $('[name="startingDateSelect"]').val(cardInfo?.start_date);
      $('[name="paymentPlanSelect"]').val(cardInfo?.JobId?.paymentPlan);
      $('[name="additionalInfo"]').val(cardInfo?.additional_info);
      $('[name="jobtypeSelect"]').val(cardInfo?.JobId?.JobType);
      $('[name="opentime"]').val(cardInfo?.open_time);
      $('[name="closeTime"]').val(cardInfo?.closing_time);
      setJobLocation(cardInfo?.location);
    }
  }, [cardInfo]);

  const [selectedDays, setSelectedDays] = useState([]);

  const handleCheckboxChange = (event) => {
    const day = event.target.value;
    if (event.target.checked) {
      // Add the selected day to the list
      setSelectedDays([...selectedDays, day]);
    } else {
      // Remove the day from the list
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay !== day)
      );
    }
  };

  const [selectedOptions, setSelectedOptions] = useState({
    jobtypeSelect: "",
    paymentPlanSelect: "",
    startingDateSelect: "",
    openingTimeSelect: "",
    closingTimeSelect: "",
  });

  const handleSelectChange = (event) => {
    const { name, value } = event.target;

    // Update the selected value in state
    setSelectedOptions({
      ...selectedOptions,
      [name]: value,
    });
  };

  let DecisionFormData = {
    selectedDays,
    selectedOptions,
    compensationInfo,
    jobLocation,
    additionalInfo,
    decisionCardId: cardInfo?._id,
  };

  // console.log("%DecisionFormData%====>", DecisionFormData);

  const handleMatch = () => {
    if (DecisionFormData.jobLocation === undefined || DecisionFormData.selectedDays.length == 0) {
      setTimeout(() => {
        toast.error("Fill the location and select at least one week day", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
      // send an event here to notify the employee that is online that you have sent them decision card.
      // console.log("Was called ===>>>");
      socket.emit("decisioncard", ({ ...DecisionFormData, employeeId: publicProfile, employerId: employer.message }));
      dispatch(sendDecisionCard(DecisionFormData));
      dispatch(
        sendMessage(
          chat_info[index]?._id,
          employer.message?._id,
          `Decison card implementation cardId:${cardInfo?._id}&secret:${nanoid()}`
        )
      );
      if (chat_info !== null) {
        if (newMessage !== null) {
          socket.emit("sendMessage", { message: [ ...newMessage, {text: `Decison card implementation cardId:${cardInfo?._id}&secret:${nanoid()}`, senderId: employer.message?._id}], otherId: chat_info[index]?.chatIds[0]?.employeeId?._id  });
        } else {
          socket.emit("sendMessage", { message: [ {text: `Decison card implementation cardId:${cardInfo?._id}&secret:${nanoid()}`, senderId: employer.message?._id}], otherId: chat_info[index]?.chatIds[0]?.employeeId?._id  });
        }
        
        // if (message !== null) {
        //   dispatch(end_to_end_chat(newMessage?.message))
        // }
      }
      navigate(`/employer/inbox/${params}?i=${index}`)
      // setTimeout(() => {
      //   toast.success("Decision card sent successfully", {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // }, 50);
    }
  };

  let dummy = {
    responsibility: [
        "Clean and tidy all areas to the standard cleanliness within time limits",
        "Deliver excellent customer service",
        "Create daily job lists and record all serviced rooms",
        "Maintain equipment in good condition",
        "Report on any shortages, damages or security issues",
        "Handle reasonable guests complaints/requests and inform others when required",
        "Check stocking levels of all consumables and replace when appropriate"],
    Benefits: ["Vacation", "Flexible work hours"],
    skills: ["Communication", "Customer Service", "Problem Solving", "Time Management", "Attention to Detail", "Teamwork", "Adaptability", "Self-motivation", "Conflict Resolution", "Decision Making", "Leadership", "Negotiation", "Perseverance", "Persuasion", "Planning", "Resilience", "Stress Management", "Tolerance", "Work Ethic", "Work Under Pressure"],
    workdays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
  }
  return (
    <>
      {cardInfo !== undefined ? (
      <div className=" h-calc-150 md:h-calc-70 px-4 py-8 overflow-y-scroll flex justify-center">
        <div className=" p-4 ipad:p-8 flex flex-col vsm:gap-3 md:gap-6 max-w-[960px] mx-auto">
          <BackButton
            onClick={() => navigate(`/employer/inbox/${params}?i=${index}`)}
          />
          <div className="md:bg-[#5843BD] flex justify-between items-center rounded-tl-2xl rounded-tr-2xl md:p-4 ">
            <h1 className=" font-semibold text-2xl vsm:text-md md:text-[#FFFFFF] vsm:text-[#5843BD]">
              Offer Card
            </h1>
            <span
              className="font-semibold text-base text-[#FFFFFF] md:flex md:justify-center md:items-center gap-2 cursor-pointer vsm:hidden"
              onClick={() => navigate(`/employer/inbox/${params}?i=${index}`)}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
                  fill="#FFFFFF"
                />
              </svg>
              Close
            </span>
          </div>

          {/* <hr className="w-full h-[1px] bg-black bg-opacity-20" /> */}

          <p className=" vsm:text-sm text-base font-normal text-black">
            Sending this decision card means you intend to match this candidate
            to your job. You can make changes to the editable fields before
            sending.
          </p>

        <div>
            <div className="bg-[#5843BD] p-2 rounded-md">
              <h2 className=" font-semibold text-base text-[#FFFFFF]">
                Candidate Details
              </h2>
            </div>
            <div className=" mt-6 flex flex-col tablet:flex-row gap-4 tablet:gap-16 vsm:gap-2">
              <div className="flex gap-2">
                <div>
                    <img
                        src={cardInfo?.employeeId?.photo}
                        alt=""
                        className=" w-16 h-16 rounded-2xl"
                    />
                </div>
                <div>
                    <h1 className=" font-medium text-lg text-black notranslate">
                    {cardInfo?.employeeId?.Fullname}
                    </h1>
                    <p className=" text-xs font-medium text-[#898989]">
                    Professional {cardInfo?.employeeId?.Job_type}
                    </p>
                    {/* rating system ? */}
                </div>
              </div>

              <div>
                <h2 className=" text-sm font-medium text-[#898989]">
                  Location
                </h2>
                <p className=" font-medium text-sm text-black "> {/* text-opacity-60 */}
                  {cardInfo?.employeeId?.Address}.
                </p>
              </div>
              
              <div>
                <h2 className=" text-sm font-medium text-[#898989]">
                  Work Experience
                </h2>
                <p className=" font-medium text-sm text-black "> {/* text-opacity-60 */}
                  {cardInfo?.employeeId?.Years_of_experience}4+ years Experience{" "}
                </p>
              </div>
            </div>

            <div className="mt-6 py-3 vsm:px-2 md:px-8 bg-[#777DEA1A] bg-opacity-10 border border-[#777DEA1A] border-opacity-[0.33] rounded-lg">

                <div className=" flex flex-col tablet:flex-row tablet:gap-12 gap-3 ">
                    <div>
                        <h2 className=" text-md font-normal text-[#898989]">
                        Job Title
                        </h2>
                        <h3 className=" font-medium vsm:text-md text-lg text-black">
                            Professional {cardInfo?.JobId?.jobTitle}
                        </h3>
                    </div>
                    <div>
                    <h3 className=" font-normal text-md text-[#898989] ">
                        Job Category
                    </h3>
                    <p className="text-black text-md font-medium vsm:text-md text-lg">{cardInfo?.JobId?.jobCategory}</p>
                    </div>
                </div>
                <div className=" mt-6">
                    <h3 className=" font-normal text-black text-xs text-opacity-50">
                        Key Responsibilities
                    </h3>
                    <ul className="ml-5 list-disc">
                        {
                        cardInfo?.JobId?.responsibilities?.map((responsibility, index) => (
                            <li key={index}>
                            <ReadMoreText
                                text={responsibility}
                                maxLength={90}
                            />
                            </li>
                        ))
                        }
                    </ul>
                </div>
                <div className="vsm:mt-2 mt-0 ">
                    <h3 className=" font-medium text-black text-xs text-opacity-50">
                        Job Description
                    </h3>
                    <ReadMoreText
                        text={cardInfo?.JobId?.jobDescription}
                        maxLength={60}
                    />
                    </div>
            </div>

        </div>

        <div>
            <div className="bg-[#5843BD] p-2 rounded-md">
                <h2 className=" font-semibold text-base text-[#FFFFFF]">
                    Payment Structure
                </h2>
            </div>

            <div className="mt-4 py-6 px-8 bg-[#777DEA1A] bg-opacity-10 border border-[#777DEA1A] border-opacity-[0.33] rounded-lg">
                <div className=" flex flex-col tablet:flex-row gap-4 ipad:gap-16">
                    <div>
                        <h3 className=" text-xs font-normal text-black text-opacity-50 mb-2">
                        Job Type
                        </h3>
                        <input
                        id="compensationSelect"
                        className=" rounded-lg border border-[#777DEA54] border-opacity-[0.33] w-[177px] vsm:w-full h-[40px] pl-3 placeholder:font-medium placeholder:text-sm"
                        placeholder="PART-TIME"
                        name="jobtypeSelect"
                        // value={compensationInfo}
                        onChange={(e) => setCompensationInfo(e.target.value)}
                        disabled={true}
                        />
                        {/* <select
                        id="jobtypeSelect"
                        className="jobType rounded-lg border border-[#777DEA54] border-opacity-[0.33] w-[177px]"
                        name="jobtypeSelect"
                        // value={selectedOptions.jobtypeSelect}
                        onChange={handleSelectChange}
                    >
                        <option value="">Part-time</option>
                        <option value="">Full-time</option>
                        <option value="">Contract</option>
                    </select> */}
                    </div>
                    <div>
                        <h3 className=" text-xs font-normal text-black text-opacity-50 mb-2">
                        Payment Plan
                        </h3>
                        <input
                        id="compensationSelect"
                        className=" rounded-lg border border-[#777DEA54] border-opacity-[0.33] w-[177px] vsm:w-full h-[40px] pl-3 placeholder:font-medium placeholder:text-sm"
                        placeholder="HOURLY"
                        name="paymentPlanSelect"
                        // value={compensationInfo}
                        onChange={(e) => setCompensationInfo(e.target.value)}
                        disabled={true}
                        />
                        {/* <select
                        id="paymentPlanSelect"
                        className=" rounded-lg border border-[#777DEA54] border-opacity-[0.33] w-[177px]"
                        name="paymentPlanSelect"
                        // value={selectedOptions.paymentPlanSelect}
                        onChange={handleSelectChange}
                    >
                        <option value="">Hourly</option>
                        <option value="">Weekly</option>
                        <option value="">Monthly</option>
                        <option value="">Per day</option>
                    </select> */}
                    </div>
                    <div>
                        <h3 className=" text-xs font-normal text-black text-opacity-50 mb-2">
                        Compensation
                        </h3>
                        <div className="flex items-center">
                            <div className="flex px-4 py-2 items-center justify-center bg-[#5843BD] rounded-tl-lg rounded-bl-lg ">
                                <span className=" text-[#FFFFFF] font-medium text-base">$</span>
                            </div>
                            <input
                                id="compensationSelect"
                                className=" rounded-br-lg rounded-tr-lg border border-[#777DEA54] border-opacity-[0.33] w-[177px] vsm:w-full h-[40px] pl-3 placeholder:font-medium placeholder:text-sm"
                                placeholder="2000"
                                name="compensationSelect"
                                // value={compensationInfo}
                                onChange={(e) => setCompensationInfo(e.target.value)}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                <h3 className=" font-medium text-[#5843BD] text-xs ">
                        Benefits
                    </h3>
                    <ul className="ml-5 list-disc">
                        {
                        cardInfo?.JobId?.responsibilities?.map((responsibility, index) => (
                            <li key={index}>
                            <ReadMoreText
                                text={responsibility}
                                maxLength={90}
                            />
                            </li>
                        ))
                        }
                    </ul>
                </div>
            </div>
        </div>

          <div>
            <div className="bg-[#5843BD] p-2 rounded-md">
              <h2 className=" text-base font-semibold text-[#FFFFFF]">
                Schedule and Location
              </h2>
            </div>

            <div className=" mt-6">
              <h2 className=" font-medium text-base text-black">Work Days</h2>
              <p className=" mt-2 font-medium text-base text-[#898989]">
                What days of the week is the candidate expected to be working
                with you?
              </p>

              <div className=" mt-2 flex flex-wrap flex-col tablet:flex-row gap-4">
                {hide ? (
                  <>
                    {cardInfo?.workdays?.map((data, key) => (
                      <div
                        className=" p-2 bg-[#EFEFFA] text-base font-semibold text-[#767676] rounded-lg border border-[#777DEA54] border-opacity-[0.33]"
                        key={key}
                      >
                        {data}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className=" flex items-center gap-2">
                      <input
                        id="monday"
                        type="checkbox"
                        className=" border-[2px] w-6 h-6 border-[#5843BD] rounded-md"
                        value="Monday"
                        onChange={handleCheckboxChange}
                      />
                      <label
                        htmlFor="checkbox"
                        className=" font-medium text-base text-black"
                      >
                        Monday
                      </label>
                    </div>
                    <div className=" flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="tue border-[2px] w-6 h-6 border-[#5843BD] rounded-md"
                        value="Tuesday"
                        onChange={handleCheckboxChange}
                      />
                      <label
                        htmlFor="checkbox"
                        className=" font-medium text-base text-black"
                      >
                        Tuesday
                      </label>
                    </div>
                    <div className=" flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="wed border-[2px] w-6 h-6 border-[#5843BD] rounded-md"
                        value="Wednesday"
                        onChange={handleCheckboxChange}
                      />
                      <label
                        htmlFor="checkbox"
                        className=" font-medium text-base text-black"
                      >
                        Wednesday
                      </label>
                    </div>
                    <div className=" flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="thur border-[2px] w-6 h-6 border-[#5843BD] rounded-md"
                        value="Thursday"
                        onChange={handleCheckboxChange}
                      />
                      <label
                        htmlFor="checkbox"
                        className=" font-medium text-base text-black"
                      >
                        Thursday
                      </label>
                    </div>
                    <div className=" flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="fri border-[2px] w-6 h-6 border-[#5843BD] rounded-md"
                        value="Friday"
                        onChange={handleCheckboxChange}
                      />
                      <label
                        htmlFor="checkbox"
                        className=" font-medium text-base text-black"
                      >
                        Friday
                      </label>
                    </div>
                    <div className=" flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="satu border-[2px] w-6 h-6 border-[#5843BD] rounded-md"
                        value="Saturday"
                        onChange={handleCheckboxChange}
                      />
                      <label
                        htmlFor="checkbox"
                        className=" font-medium text-base text-black"
                      >
                        Saturday
                      </label>
                    </div>
                    <div className=" flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="sun border-[2px] w-6 h-6 border-[#5843BD] rounded-md"
                        value="Sunday"
                        onChange={handleCheckboxChange}
                      />
                      <label
                        htmlFor="checkbox"
                        className=" font-medium text-base text-black"
                      >
                        Sunday
                      </label>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className=" mt-6 flex flex-col tablet:flex-row gap-4">
              <div className=" tablet:w-[33.333]">
                <h2 className=" font-medium text-base text-black">
                  Starting Date
                </h2>
                {/* <select
                id="startingDateSelect"
                className=" w-full tablet:w-[200px] rounded-lg border border-[#777DEA54] border-opacity-[0.33]"
                name="startingDateSelect"
                value={selectedOptions.startingDateSelect}
                onChange={handleSelectChange}
              >
                <option value="">select</option>
              </select> */}
                <CustomDate
                  className=" w-full tablet:w-[200px] rounded-lg border border-[#777DEA54] border-opacity-[0.33]"
                  ChangeHandler={handleSelectChange}
                  name="startingDateSelect"
                  selectedDate={selectedOptions.startingDateSelect}
                />
              </div>

              {hide ?
                <input
                  id="startTime"
                  className=" rounded-lg border border-[#777DEA54] border-opacity-[0.33] w-[177px] h-[40px] pl-3 md:mt-8 placeholder:font-medium placeholder:text-sm"
                  placeholder="PART-TIME"
                  name="opentime"
                  disabled={true}
                />
                :
                <div className=" tablet:w-[33.333]">
                  <h2 className=" font-medium text-base text-black">Opening Time</h2>
                  <select
                    id="openingTimeSelect"
                    className="w-full tablet:w-[200px] rounded-lg p-2.5 border border-[#777DEA54] border-opacity-[0.33]"
                    name="openingTimeSelect"
                    value={selectedOptions.openingTimeSelect}
                    onChange={handleSelectChange}
                  ><option value="12am">12am</option>
                    <option value="1am">1am</option>
                    <option value="2am">2am</option>
                    <option value="3am">3am</option>
                    <option value="4am">4am</option>
                    <option value="5am">5am</option>
                    <option value="6am">6am</option>
                    <option value="7am">7am</option>
                    <option value="8am">8am</option>
                    <option value="9am">9am</option>
                    <option value="10am">10am</option>
                    <option value="11am">11am</option>
                    <option value="12pm">12pm</option>
                    <option value="1pm">1pm</option>
                    <option value="2pm">2pm</option>
                    <option value="3pm">3pm</option>
                    <option value="4pm">4pm</option>
                    <option value="5pm">5pm</option>
                    <option value="6pm">6pm</option>
                    <option value="7pm">7pm</option>
                    <option value="8pm">8pm</option>
                    <option value="9pm">9pm</option>
                    <option value="10pm">10pm</option>
                    <option value="11pm">11pm</option>
                  </select>
                </div>
              }

              {hide ?

                <input
                  id="endTime"
                  className=" rounded-lg border border-[#777DEA54] border-opacity-[0.33] w-[177px] h-[40px] pl-3 md:mt-8 placeholder:font-medium placeholder:text-sm"
                  placeholder="PART-TIME"
                  name="closeTime"
                  disabled={true}
                />
                :
                <div className=" tablet:w-[33.333]">
                  <h2 className=" font-medium text-base text-black">Closing Time</h2>
                  <select
                    id="closingTimeSelect"
                    className=" w-full tablet:w-[200px]  p-2.5 rounded-lg border border-[#777DEA54] border-opacity-[0.33]"
                    name="closingTimeSelect"
                    value={selectedOptions.closingTimeSelect}
                    onChange={handleSelectChange}
                  ><option value="12am">12am</option>
                    <option value="1am">1am</option>
                    <option value="2am">2am</option>
                    <option value="3am">3am</option>
                    <option value="4am">4am</option>
                    <option value="5am">5am</option>
                    <option value="6am">6am</option>
                    <option value="7am">7am</option>
                    <option value="8am">8am</option>
                    <option value="9am">9am</option>
                    <option value="10am">10am</option>
                    <option value="11am">11am</option>
                    <option value="12pm">12pm</option>
                    <option value="1pm">1pm</option>
                    <option value="2pm">2pm</option>
                    <option value="3pm">3pm</option>
                    <option value="4pm">4pm</option>
                    <option value="5pm">5pm</option>
                    <option value="6pm">6pm</option>
                    <option value="7pm">7pm</option>
                    <option value="8pm">8pm</option>
                    <option value="9pm">9pm</option>
                    <option value="10pm">10pm</option>
                    <option value="11pm">11pm</option>
                  </select>
                </div>
              }
            </div>

            <div className=" mt-6">
              <h2 className=" font-medium text-base text-black">
                Job Location (please edit to full address)
              </h2>
              <input
                type="text"
                className=" w-full border  p-2.5 border-[#777DEA54] border-opacity-[0.33] rounded-lg"
                placeholder="Houston, Texas."
                name="location"
                value={jobLocation}
                onChange={(e) => setJobLocation(e.target.value)}
                disabled={hide}
              />
            </div>
          </div>

          <div>
            <div className="bg-[#5843BD] p-2 rounded-md">
                <h2 className=" font-semibold text-base text-[#FFFFFF]">
                    Additional Information
                </h2>
            </div>
            <p className=" font-medium text-base text-[#898989]">
              Please add any additional information the employee should know{" "}
              <br /> or any additional requirement before starting.
            </p>

            {/* <textarea
              type="text"
              className=" h-[260px] w-full rounded-xl border border-black border-opacity-30 resize-none"
              name="additionalInfo"
              // value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
              disabled={hide}
            /> */}
            <TextEditor
                className="h-56 w-[100%] xl:w-[100%] 2xl:w-[100%] rounded-lg border border-radius border-indigo-400 border-opacity-30   focus:outline-none focus:border-2 focus:border-indigo-400 placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal placeholder:align-top"
                style={{ resize: "none" }}
                placeholder="0/300"
                type="text"
                name="additionalInfo"
                value={additionalInfo}
                onChange={setAdditionalInfo}
                onCut={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                onKeyDown={handleKeyDown}
                // onBlur={formikform.handleBlur}
                disabled={hide}
            />
          </div>
          {hide ? null : (
            <>
              <p className=" font-medium text-base text-black">
                Once the candidate accepts the terms on the card, they will be
                matched.
              </p>

              <div>

                <button
                  className="w-[175px] tablet:w-fit tablet:px-[36px] py-3 rounded-lg bg-[#5843BD] border-[1.5px] border-[#FCFCFC33] border-opacity-20 text-base font-medium text-white "
                  onClick={handleMatch}
                >
                  Send Offer
                </button>
              </div>
            </>
          )}
        </div>
    </div>
    ) : (
        <LoaderModal text="getting decision card information" />
    )}
        <ToastContainer />
    </>
  );
}

export default EmployerDecisionCardRedesign;
