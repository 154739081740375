import { Link, useNavigate } from "react-router-dom";
import timeResolver from "../timeResolver";

export default function JobDetailItem(props) {
	const navigate = useNavigate();
	// const dateString = props.createdAt;
	// const date = new Date(dateString);

	// const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${String(date.getFullYear()).slice(-2)}`;
	return (
		<tr onClick={() => navigate(props.urlToDetails)} className="text-[#111111] cursor-pointer text-base font-medium tracking-wide bg-white py-1 font-Poppins rounded-lg border-b border-[#5843BD4D]">
			<td className="pl-4 xl:pl-8">
				<div className="flex py-4 gap-x-2 h-full items-center">
					<div>
						<img className="w-[45px] h-[45px] rounded-[4px]" src={props.logo} />
					</div>
					<div>
						<p class="text-sm lg:text-base font-medium truncate md:max-w-[90%] xl:max-w-full leading-normal text-left text-[#5843BD]">
							{props.jobPosition}
						</p>
						<p class="text-[10px] text-[#979696] uppercase font-normal leading-[15px] text-left">
							Posted {`${timeResolver(props.createdAt)}`}
						</p>
					</div>
				</div>
			</td>
			<td>
				<div className="py-2 flex justify-center items-center">
					<p className="text-sm lg:text-base w-max bg-[#5843BD08] rounded-[4px] leading-normal py-2  text-center px-8 font-normal text-[#000000CC]">{props.totalApplicationReceived ? props.totalApplicationReceived : '0'}</p>
				</div>
			</td>
			<td>
				<div className="py-2 flex justify-center items-center">
					<p className="text-sm lg:text-base w-max rounded-[4px] leading-normal bg-[#FF663308] text-center py-2 px-8 font-normal text-[#000000CC]">{props.views ? props.views : '0'}</p>
				</div>
			</td>
			<td>
				<div className="py-4 w-full flex justify-center items-center">
					{
						props.status === "Active" ? <div className="bg-[#5843BD26] rounded-[4px] py-2 px-3 text-sm lg:text-base font-medium leading-normal text-left text-[#111111]">
							Active
						</div> : props.status === "Pending" ? <div className="bg-[#FF663326] rounded-[4px] py-2 px-3 text-sm lg:text-base font-medium leading-normal text-left text-[#111111]">
							Pending
						</div>
							/* 						: props.status === "Hired" ? <div className="bg-[#5843BD80] rounded-[4px] text-white py-2 px-3 text-sm lg:text-base font-medium leading-normal text-left">
														Hired
													</div> */
							:
							<div className="bg-[#FF6633] py-2 px-3 rounded-[4px] text-sm lg:text-base font-medium leading-normal text-left text-white">
								Closed
							</div>
					}
				</div>
			</td>
			<td>
				<div className="py-2 pr-4 whitespace-nowrap text-sm lg:text-base font-medium leading-normal text-left text-[#5843BD]">
					View Details
				</div>
			</td>
		</tr>
	)
}

