export default function ApplicantSkillTestPrevItem({ question, options, answer, i, userAnswer }) {
  return (
    <div className="bg-white flex flex-col w-full gap-y-4 px-6 py-4">
      <p className="text-base leading-[1.5] font-semibold text-[#4E5161]">{question}</p>
      <div className="flex flex-col gap-y-4">
        {options.map((option, ind) => {
          return (
            <div key={ind} className="flex gap-x-2 items-center">
              <input
                checked={answer.includes(option) || userAnswer.includes(option)}
                type="checkbox"
                name={`question_${i}`}
                id={`option_${i}_${ind}`}
                className={`hidden peer`}
              />
              <label
                htmlFor={`option_${i}_${ind}`}
                className={`w-6 h-6 rounded-full border-2 border-[#5843BD] flex items-center justify-center
                ${answer.includes(option) && userAnswer.includes(option)
                  ? 'bg-[#5843BD] border-[#5843BD]'
                  : answer.includes(option)
                    ? 'bg-[#5843BD] border-[#5843BD]'
                    : userAnswer.includes(option)
                      ? 'bg-red-500 border-red-500'
                      : 'bg-transparent'}
                `}
              >
                <div
                  className="w-3 h-3 rounded-full bg-white peer-checked:bg-current"
                />
              </label>

              <label htmlFor={`option_${i}_${ind}`} className="text-base leading-normal text-[#22263ACC]">
                {option}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
