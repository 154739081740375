import React, { useState } from "react";
import BackButton from "../../../common/back_button";
import { ToastContainer } from "react-toastify";

export default function OptionSkillTest({ handleStepChangeForward, handleStepChangeBackward }) {

  return (
    <>
      <div className="px-3 lg:pl-6 py-8 w-full flex justify-center h-calc-150 md:h-calc-70 overflow-y-scroll">
        <div className="w-full flex px-6 py-4 md:p-0 md:items-center  md:justify-center">
          <div className="rounded-[24px] md:self-center relative flex flex-col gap-y-4 md:gap-y-6 w-full max-w-[650px]">
            <BackButton onClick={() => handleStepChangeBackward()} />
            <div className="bg-[#5843BD] flex justify-center items-center p-3 header-border rounded-tl-[24px] rounded-tr-[24px]">
              <p className="text-white text-[20px] leading-normal font-semibold text-center">Basic Skill Test - Optional</p>
            </div>

            <div className="flex flex-col bg-white rounded-bl-[24px] rounded-br-[24px] items-center pt-6 md:pt-0 pb-6 gap-4 md:gap-6 px-4 sm:px-6 md:px-12 ">
              {/* <div className="w-full max-w-[450px] flex flex-col items-center gap-3 justify-between rounded-3xl bg-white">
            <svg
              width="43"
              height="43"
              viewBox="0 0 43 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="21.5" cy="21.5" r="21" fill="#5843BD" />
              <path
                d="M11.125 21.3372L18.2239 28.0493L32.421 14.625"
                stroke="white"
                stroke-width="5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p className="max-w-[373px] leading-[1.2] text-center text-[#5843BD] text-[24px] lg:text-[32px] font-semibold">
              You have successfully <br />created your job.
            </p>
          </div> */}

              <p className="leading-[1.2] rounded-lg bg-[#FF66331A] px-7 py-4 text-center text-black">
                To hire the best hands, we encourage you to generate <br className="hidden md:block" /><span className="font-semibold">basic skill test</span> for your job post. Test scores will be attached to each application.
                <br /> <br />
                Don’t worry, Moil AI will generate <br className="hidden md:block" />
                the questions for you.
                <br /> <br />
                If you don’t want to attach a skill test, click “Preview Job”
              </p>

              <div className="flex flex-col w-full gap-y-4 md:flex-row item-center justify-between">
                <button className="rounded-[24px] py-3 px-9 bg-[#5843BD] text-center text-[#fff] leading-normal font-semibold"
                  onClick={() => {
                    handleStepChangeForward();
                  }}
                >
                  Generate Skill Test
                </button>
                <button className="rounded-[24px] py-3 px-9 border border-[#FF6633] text-center justify-center text-[#FF6633] leading-normal flex items-center gap-x-3 font-semibold"
                  onClick={() => {
                    handleStepChangeForward();
                    handleStepChangeForward();
                  }}
                >
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.544 12.0274C21.848 12.4534 22 12.6674 22 12.9824C22 13.2984 21.848 13.5114 21.544 13.9374C20.178 15.8534 16.689 19.9824 12 19.9824C7.31 19.9824 3.822 15.8524 2.456 13.9374C2.152 13.5114 2 13.2974 2 12.9824C2 12.6664 2.152 12.4534 2.456 12.0274C3.822 10.1114 7.311 5.98242 12 5.98242C16.69 5.98242 20.178 10.1124 21.544 12.0274Z" stroke="#F24E1E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M15 12.9824C15 12.1868 14.6839 11.4237 14.1213 10.8611C13.5587 10.2985 12.7956 9.98242 12 9.98242C11.2044 9.98242 10.4413 10.2985 9.87868 10.8611C9.31607 11.4237 9 12.1868 9 12.9824C9 13.7781 9.31607 14.5411 9.87868 15.1037C10.4413 15.6664 11.2044 15.9824 12 15.9824C12.7956 15.9824 13.5587 15.6664 14.1213 15.1037C14.6839 14.5411 15 13.7781 15 12.9824Z" stroke="#F24E1E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  Preview Job
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </>
  )
}