import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(`${process.env.REACT_STRIPE_LIVE_PUBLISHABLE_KEY}`);

export default function StripeComponent(props) {

  return (
    <>
      <div className='main_head top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px] ' style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        {stripePromise && (
          <Elements stripe={stripePromise}>
            <PaymentForm />
          </Elements>
        )}
      </div>
    </>
  );
};
