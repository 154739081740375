import React, { useEffect, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { extratName } from "../../Resume/pdf/Adjust_gptRes";
import { getNotification, notification_success } from "../../../Redux/ActionCreator";
import { baseURL } from "../../../Redux/baseURL";

function NotificationModal({ notificationvalue }) {
 const wrapperRef = useRef(null);
  const profile_stat = useSelector((prev) => prev.login);
  const dispatch = useDispatch();
  // const notify = useSelector(e => e.notification);
  // let notification_list = notify.message;

  // get decision card from database
  const getDecisionCards = async () => {
    try {
      const response = await fetch(baseURL + '/api/decision/getDecisionCards', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        // handle the data as needed
        // console.log("Decision card", data.data);
        dispatch(notification_success(data.data))
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      // console.error('Error:', error);
    }
  };

  // useEffect(() => {
  //   if (profile_stat.profile_status.AcctType === "Official") {
  //       dispatch(getNotification());
  //   }
  // }, [])

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //       setShowNavigation(false);
  //     }
  //   }

  //   // This will handle the case where the dropdown itself is clicked
  //   function handleDropdownClick(event) {
  //     event.stopPropagation();
  //   }

  //   document.addEventListener("mousedown", handleClickOutside);
  //   wrapperRef.current.addEventListener("mousedown", handleDropdownClick);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //     if (wrapperRef.current) {
  //       wrapperRef.current.removeEventListener("mousedown", handleDropdownClick);
  //     }
  //   };
  // }, [wrapperRef]);


  const navigate = useNavigate();
  const chat_info = useSelector(e => e.chat_info.message);

  const handleViewNotification = (id) => {
    // console.log("id", id);
    if (profile_stat.profile_status.AcctType === "Official") {
      let notify = notificationvalue.filter(element => element._id !== id);
      dispatch(notification_success(notify))
    }
  }

  return (
    <div ref={wrapperRef} className={`bg-white mx-auto px-2 md:px-4 w-[300px] max-h-[70vh] overflow-y-scroll shadow-lg md:w-[400px]`}>
        <div className=" pt-6 text-base font-medium text-[#898989] bg-white sticky top-0">
          <div className="flex items-center gap-3">
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 15C6 15.2652 5.89464 15.5196 5.70711 15.7071C5.51957 15.8946 5.26522 16 5 16C4.73478 16 4.48043 15.8946 4.29289 15.7071C4.10536 15.5196 4 15.2652 4 15L4 4C4 3.73478 4.10536 3.48043 4.29289 3.29289C4.48043 3.10536 4.73478 3 5 3C5.26522 3 5.51957 3.10536 5.70711 3.29289C5.89464 3.48043 6 3.73478 6 4L6 15ZM2 15C2 15.2652 1.89464 15.5196 1.70711 15.7071C1.51957 15.8946 1.26522 16 1 16C0.734784 16 0.48043 15.8946 0.292893 15.7071C0.105357 15.5196 0 15.2652 0 15L0 8C0 7.73478 0.105357 7.48043 0.292893 7.29289C0.48043 7.10536 0.734784 7 1 7C1.26522 7 1.51957 7.10536 1.70711 7.29289C1.89464 7.48043 2 7.73478 2 8L2 15ZM8 15L8 6C8 5.73478 8.10536 5.48043 8.29289 5.29289C8.48043 5.10536 8.73478 5 9 5C9.26522 5 9.51957 5.10536 9.70711 5.29289C9.89464 5.48043 10 5.73478 10 6L10 15C10 15.2652 9.89464 15.5196 9.70711 15.7071C9.51957 15.8946 9.26522 16 9 16C8.73478 16 8.48043 15.8946 8.29289 15.7071C8.10536 15.5196 8 15.2652 8 15ZM12 1C12 0.734784 12.1054 0.48043 12.2929 0.292893C12.4804 0.105357 12.7348 0 13 0C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1L14 15C14 15.2652 13.8946 15.5196 13.7071 15.7071C13.5196 15.8946 13.2652 16 13 16C12.7348 16 12.4804 15.8946 12.2929 15.7071C12.1054 15.5196 12 15.2652 12 15L12 1Z"
                fill="#F24E1E"
              />
            </svg>
            <span>Activities</span>
          </div>

          <hr className=" mt-5 h-[1px] w-full bg-[#00000033] bg-opacity-20" />
        </div>

        <div className=" mt-3 mb-2 flex flex-col gap-2">
          {notificationvalue?.map((data, index) => (
            <>{profile_stat.profile_status.AcctType === "Official" ? 
            <Link to={`/employer/jobs/${data?.JobId?.custom_name}`} key={index} onClick={() => handleViewNotification(data._id)}>
              <div key={index} className=" py-2 flex items-center gap-2">
              <img src={data?.senderId?.photo} alt="" className=" min-w-[32px] h-8 rounded-full" />
              <span className="font-medium text-sm text-[#181A1D]">
                {extratName(data?.senderId?.Fullname)} applied for the {data.JobId?.jobTitle} job
              </span>
            </div>
            </Link> : 
              <div key={index} className=" py-2 flex items-center gap-2" onClick={() => handleViewNotification(data._id)}>
                <img src={data?.employerId?.photo} alt="" className=" min-w-[32px] h-8 rounded-full" />
                <span className="font-medium text-sm text-[#181A1D]">
                  {data?.employerId?.name} {data?.notification?.length !== 0 && data?.notification !== undefined ? "is interested in your profile and wants to hire": "sent you an offer card"}
                </span>
              </div>}</>
          ))}
        </div>
      </div>
    // </div>
  );
}

export default NotificationModal;

