import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { useSelector, useDispatch } from "react-redux";
import { getEmployerProfile, getting_employer_profile_successful } from "../../../Redux/ActionCreator";

const SubscriptionSuccess = ({ setCancel, type }) => {
  const navigate = useNavigate(); // Initialize useNavigate
  const employer = useSelector(e => e.employer.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmployerProfile());

  }, []);

  let [subNextBillingDate, setSubNextBillingDate] = useState("No active plan");
  let [activePlan, setActivePlan] = useState("");
  let [duration, setDuration] = useState("");
  let [ cost, setCost ] = useState("");
  let [ postCount, setPostCount ] = useState("");

  useEffect(() => {

    if (employer?.plan == "basic_monthly") {
      setActivePlan("Basic");
      setDuration("Monthly");
      setCost("$40");
      setPostCount("1");
    } else if (employer?.plan == "basic_yearly") {
      setActivePlan("Basic");
      setDuration("Yearly");
      setCost("$408");
      setPostCount("1");
    } else if (employer?.plan == "standard_monthly") {
      setActivePlan("Standard");
      setDuration("Monthly");
      setCost("$75");
      setPostCount("3");
    } else if (employer?.plan == "standard_yearly") {
      setActivePlan("Standard");
      setDuration("Yearly");
      setCost("$720");
      setPostCount("3");
    } else if (employer?.plan == "professional_monthly") {
      setActivePlan("Professional");
      setDuration("Monthly");
      setCost("$150");
      setPostCount("Unlimited");
    } else if (employer?.plan == "professional_yearly") {
      setActivePlan("Professional");
      setDuration("Yearly");
      setCost("$1350");
      setPostCount("Unlimited");
    } else {
      setActivePlan("");
      setDuration("");
      setCost("");
    }
    let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let endDate = `${monthNames[new Date(employer?.subNextBillingDate)?.getMonth()]} ${new Date(employer?.subNextBillingDate)?.getDate()}. ${new Date(employer?.subNextBillingDate)?.getFullYear()}`
    if (employer?.subNextBillingDate !== undefined) {
      setSubNextBillingDate(endDate);
    } else {
      setSubNextBillingDate("No active plan");
    }
  }, [employer]);



  const handleViewSubscriptions = () => {
    setCancel(false);
    navigate("/employer/subscribe"); // Navigate to subscriptions
  };

  const handlePostJob = () => {
    navigate("/employer/upload"); // Navigate to post job
  };

  return (
    <section className="bg-white md:max-w-[600px] sm:max-w-[500px] min-w-[350px] bg-[#FBF9FF] w-[80%] z-100 m-auto rounded-[24px] pb-5 overflow-hidden relative">
      <div className="flex flex-col items-center relative z-[1000] justify-center">
        <div className="flex items-center px-4 md:px-6 py-6 justify-between bg-[#5843BD] PLAN w-full border-[#E9F1FF] border-b-[8px] text-[#fff]">
          <div className="text-xl font-medium leading-none text-center">{type === "subscribe" ? "Subscription successful!" : type === "upgrade" ? "Upgrade successful!" : "Downgrade successful!"}</div>
          <div className="w-8 h-8 rounded-full bg-[#fff] flex justify-center items-center font-bold text-[#1C1C1C] text-opacity-60 text-[20px] cursor-pointer" onClick={() => setCancel(false)}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.2103 1.14844L1.14844 11.3612M1.14844 1.14844L11.2103 11.3612" stroke="black" strokeWidth="1.02128" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        </div>
        <div className="w-full mt-4 mb-2">
          <p className="text-[20px] font-semibold leading-[1.2] text-center text-black">
            {type === "subscribe" ? <>You have successfully subscribed <br className="hidden md:block" />to the {activePlan} plan ({duration})</> :
              type === "upgrade" ? <>You have successfully upgraded <br className="hidden md:block" />to the {activePlan} plan ({duration})</> :
                <>You have successfully downgraded  <br className="hidden md:block" />to the {activePlan} plan ({duration})</>}
          </p>
        </div>
        <div className="mb-4 ">
          <p className="text-base font-normal leading-[1.2] text-center max-w-[280px] sm:max-w-[400px]">
            Below are the details of your new subscription.
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2 md:flex-row">
            <p className="bg-white text-[#131313] text-xl leading-[1.5] rounded-md p-2">
              Cost:  <span className="font-medium">{cost}</span>
            </p>
            <p className="bg-white text-[#131313] text-xl leading-[1.5] rounded-md p-2">
              Jobs post:  <span className="font-medium">{postCount}</span>
            </p>
          </div>
          <p className="bg-white text-[#131313] text-xl self-center w-max leading-[1.5] rounded-md p-2">
            Renews on:  <span className="font-medium">{subNextBillingDate}</span>
          </p>
        </div>
        <div className="flex justify-between md:justify-center gap-x-10 items-center justify-center flex-col py-4 gap-x-4 w-full px-4 md:px-8 sm:flex-row gap-y-4">
          <button className="w-full md:w-max px-9 py-3 gap-3 rounded-3xl text-base font-semibold leading-normal text-center bg-[#5843BD] text-white" onClick={handleViewSubscriptions}>
            View subscriptions
          </button>
          <button className="w-full md:w-max items-center border border-[#FF6633] justify-center flex gap-x-2 px-9 py-3 gap-3 rounded-3xl text-base font-semibold leading-normal text-center text-[#FF6633]" onClick={handlePostJob}>
            Post job
          </button>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionSuccess;
