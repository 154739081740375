import React, { useEffect, useState } from "react";
import MatchCandidateComp from "../../common/match_candidate_comp";
import { getPublicUserResumeData } from "../../../Redux/ActionCreator";
import BackButton from "../../common/back_button";
import { useSelector, useDispatch } from "react-redux";
import { createPortal } from "react-dom";
import ConfirmationModal from "../../common/confirmation_modal";
import { establishChat, ApplyForAJobPublic, getAllJobsPosted, getChatInformation } from "../../../Redux/ActionCreator";
import { useNavigate } from "react-router-dom";
import PaginationBtn from "../../common/paginationBtn";

export default function JobMatch() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const total_page = useSelector((prev) => prev.total_page);
    const ourtotalpage = total_page.message;
    const [currentDashboardPage, setCurrentDashboardPage] = useState(1);

    const userInfo = useSelector(e => e.userInfo.user);
    const chat_info = useSelector(e => e.chat_info.message);
    const jobsPostedState = useSelector(store => store.jobs_posted);
    const EmployerProfile = useSelector((prev) => prev.employer);
    const jobsPosted = jobsPostedState.message;
    const profile = EmployerProfile.message;
    const publicInfo = useSelector(e => e.public);

    const publicProfile = publicInfo.message?.profile;
    const publicResumes = useSelector((prev) => prev.publicResume);
    const lastResume = publicResumes.message?.length > 0 ? publicResumes.message[publicResumes.message?.length - 1] : []

    const [confirmModal, setConfirmModal] = useState(false);
    const [jobId, setJobId] = useState();
    let [ mainJobId, setMainJobId ] = useState();
    const [jobTitle, setJobTitle] = useState();

    const socket = useSelector((prev) => prev.socket?.message);

    // filter only available jobs
    const jobs = jobsPosted?.filter(job => job?.availability === true);

    let index = chat_info?.findIndex(entry => {
        return entry.chatIds.some(chat => chat?.employeeId?._id === publicProfile?._id);
    })

    useEffect(() => {
        // console.log(publicProfile._id);
        dispatch(getPublicUserResumeData(publicInfo.message?.user._id));
        dispatch(getAllJobsPosted(currentDashboardPage));
    }, [currentDashboardPage])

    // get the chat information
    useEffect(() => {
        dispatch(getChatInformation(userInfo?.profile_id));
    }, [])

    const handleEstablishMessage = () => {
        // you are to emit an event here to let the employee know that you have matched them to a job
        let notification = {
            notificationType: "Match",
            notificationMessage: "An employer just match you to a job.",
        }
        socket.emit("interestNotification", { employerId: profile, employeeId: publicProfile, notification: notification });
        dispatch(ApplyForAJobPublic(mainJobId, lastResume._id, publicInfo.message?.user?._id, "match")); // jobId is the custom_name
        dispatch(establishChat(profile?._id, publicProfile?._id, jobTitle, lastResume._id, mainJobId)); // function to establish chat;

        if (index >= 0) {
            navigate(`/employer/inbox/${publicProfile?.Fullname}?i=${index}`);
        } else {
            navigate(`/employer/inbox`);
        }
    }

    function goBack() {
        let searchParams = new URLSearchParams(window.location.search);
        let d = searchParams.get('d');
        let jobName = searchParams.get('jobName');
        let pr = searchParams.get('pr');
        let index = searchParams.get('index');
        let mode = searchParams.get('mode');
        navigate(`/profile/beta/${pr}?d=${d}&jobName=${jobName}&pr=${pr}&index=${index}&mode=${mode}`);
        // window.history.back();
    }

    const handleMatch = (jobId, jobTitle, job) => {
        setConfirmModal(true);
        setJobId(jobId);
        setMainJobId(job?._id)
        setJobTitle(jobTitle);
    }

    const confirmationModal = confirmModal ? createPortal(
        <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px] ">
            <ConfirmationModal action="match candidate" noWord="Don't Match" confirWord="Match" setNo={() => setConfirmModal(false)} setConfirm={handleEstablishMessage} />
        </div>, document.getElementById("modal")) : null;

    // console.log(confirmModal);

    return (
        <div className="px-3 lg:pl-6 py-8 w-full flex justify-center h-calc-70 overflow-y-scroll">
            <div className="w-full max-w-[800px]">
                <div className="mb-6">
                    <BackButton onClick={goBack} />
                </div>

                <div className="max-w-[800px] w-full bg-opacity-80 flex-col justify-start items-start inline-flex gap-y-4">

                    <p class="font-poppins text-base font-medium leading-6 tracking-normal text-left">
                        <span className="text-[#5843BD]">Match Candidate</span> to any of your jobs below. You will be taken to there inbox. Ask them to check out the job and send them an <span className="text-[#5843BD]">Offer Card</span> after the terms are discussed.
                    </p>

                    <div className="w-full flex flex-col gap-y-2">
                        {
                            jobs !== null ? jobs?.map((job, index) => (
                                <div className="w-full" key={index}>
                                    <MatchCandidateComp
                                        jobPosition={job?.jobTitle}
                                        postAge={job?.createdAt}
                                        logo={profile?.photo}
                                        urlToDetails={job?.display == true ? `/employer/jobs/${job._id}` : `/employer/create-job/preview?jI=${job._id}&complete=false`}
                                        display={job?.display}
                                        jobId={job?._id}
                                        onClick={() => handleMatch(job?.custom_name, job?.jobTitle, job)}
                                    />
                                </div>
                            )) : <div></div>
                        }
                    </div>

                    <div className="flex justify-end w-full">
                    <PaginationBtn
                        totalPages={ourtotalpage}
                        currentPage={currentDashboardPage}
                        onNextPage={() => {
                            if (currentDashboardPage < ourtotalpage) {
                                setCurrentDashboardPage((prev) => prev + 1);
                            }
                        }}
                        onPrevPage={() => {
                            if (currentDashboardPage > 1) {
                                setCurrentDashboardPage((prev) => prev - 1);
                            }
                        }}
                        onSelectPage={(selectedPage) => {
                            setCurrentDashboardPage(selectedPage);
                        }}
                    />
                    </div>
                </div>


                <div class="w-auto h-auto py-6 px-0 gap-2">
                    <p class="font-poppins text-base italic font-medium leading-6 tracking-normal text-left">
                        Can’t find a job to send them? <a href="/employer/create-job" className="text-[#FF6633]">
                            Post a New Job here.
                        </a>
                    </p>

                </div>
                {confirmationModal}
            </div>
        </div>
    )
}