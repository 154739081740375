import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import JobPostTableItem from "../common/job_posts_item/job_posts_table_item";
import JobPostCandidate from "../common/job_posts_candidates";
import { SortSvg } from "../common/svgs/svgs";
import BackButton from "../common/back_button";
import {
  job_to_update_loading,
  getJobApplicants,
  getting_profile_profile_loading,
  getting_cv_details_loading,
  recommended_candid,
  individual_job_cat,
  getAllJobsPosted,
  candidate_type_success,
  candidate_category_success
} from "../../Redux/ActionCreator";
import JobPostResponsiveCandidates from "../common/job_posts_candidates/responsive";
import PaginationBtn from "../common/paginationBtn";
import { getRecommendedCandidateForAJob } from "../../utils/recommendationAlgo";
import DashboardCandidateItem from "../common/dashboard_candidate_item";
import { useAnalytics } from "../../context/AnalyticsContext";
import DashboardOptionLink from "../common/dashboard_options_link";
import { getRecommendedCandidateByCategory, getRecommendedCandidate } from "../../utils/recommendationAlgo";
import HelmetProvide from "../common/helmet";
import { ToastContainer, toast } from "react-toastify";

export default function JobsCandidate() {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recommend_category = useSelector((e) => e.candidate_category.message);
  const recommend = useSelector((e) => e.individual_job_cat?.message);
  const jobsPostedState = useSelector((store) => store.jobs_posted);
  let tabSwitch = useSelector((e) => e.candidate_type.message);
  const jobName = useParams().jobName;
  const job = jobsPostedState.message?.filter(
    (item) => item.custom_name === jobName
  )?.[0];
  const applicants = useSelector((e) => e.applicants);
  const [closed, setClosed] = useState(false);
  const [total_page, setTotal_page] = useState(1);
  const [filterJob, setFilterJob] = useState(false);
  let [tabColor, setTabColor] = useState(null);

  const ourtotalpage = total_page; // handle the pagination here on the frontend.
  const [currentDashboardPage, setCurrentDashboardPage] = useState(1);
  // console.log("Total page ", total_page);

  // employer profile information
  const EmployerProfile = useSelector((prev) => prev.employer);
  const profile = EmployerProfile.message;

  // useEffect(() => {
  //   if (jobsPostedState.message === null) {
  //     getAllJobsPosted(1);
  //   }
  // }, []);

  const filterJobFunc = () => {
    setFilterJob(!filterJob)
  }

  let [load, setLoad] = useState(false);
  useEffect(() => {
    // let jobName = window.location.pathname.split("/")[3];
    let recommendation = getRecommendedCandidateForAJob(currentDashboardPage, jobName); // we will need to modify this while working with more datas (pagination)
    recommendation.then(resp => resp.json())
      .then(resp => {
        // console.log("resp?.data", resp)
        setLoad(false);
        if (resp.success == true) {
          if (resp?.data !== null && resp?.data?.result?.length !== 0) {
            dispatch(individual_job_cat(resp.data?.result));
            setTotal_page(resp.data?.page_num);
          } else {
            dispatch(individual_job_cat([]));
            setTotal_page(resp.data?.page_num);
          }
        } else {
          // do sth here;
          if (resp?.title === "Not Found") {
            dispatch(individual_job_cat([]));
            setTotal_page(resp.data?.page_num);
          }
        }
      }).catch(err => {
        // console.log("An error occured", err);
        setLoad(false);
        // setTimeout(() => {
        //   toast.error("An Error occured. Please check your network and try again.", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        // }, 50);
      });
  }, [currentDashboardPage]);

  const getRecommendedEmployeeByLastJobTitle = () => {
    setLoad(true);
    setTabColor("title");
    let recommendation = getRecommendedCandidate(currentDashboardPage); // we will need to modify this while working with more datas (pagination)
    recommendation.then(resp => resp.json())
      .then(resp => {
        setLoad(false);
        if (resp.success == true) {
          if (resp?.data !== null && resp?.data?.result?.length !== 0) {
            dispatch(recommended_candid(resp.data?.result));
            setTotal_page(resp.data?.page_num);
          } else {
            // do sth here;
            dispatch(recommended_candid([]));
            setTotal_page(resp.data?.page_num);
          }
        } else {
          // do sth here;
          if (resp?.title === "Not Found") {
            dispatch(recommended_candid([]));
            setTotal_page(resp.data?.page_num);
          }
        }
      }).catch(err => {
        // console.log("An error occured", err);
        setLoad(false);
        setTimeout(() => {
          toast.error("An Error occured. Please check your network and try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      })
  }

  const getRecommendedEmployeeByCategory = useCallback(
    () => {
      // setLoad(true);
      setTabColor("category");
      dispatch(candidate_type_success("categoryc"));
      let recommendation = getRecommendedCandidateByCategory(currentDashboardPage); // we will need to modify this while working with more datas (pagination)
      recommendation.then(resp => resp.json())
        .then(resp => {
          setLoad(false);
          if (resp.success == true) {
            if (resp?.data !== null && resp?.data?.result?.length !== 0) {
              dispatch(candidate_category_success(resp.data?.result));
              dispatch(total_page(resp.data?.page_num));
            } else {
              // do sth here;
              dispatch(candidate_category_success([]));
              dispatch(total_page(1));
            }
          } else {
            // do sth here;
            // dispatch(candidate_category_success([]));
            // dispatch(total_page(1));
            if (resp.success == false) {
              navigate("/");
            }
          }
        }).catch(err => {
          // console.log("An error occured", err);
          setLoad(false);
          setTimeout(() => {
            toast.error("An Error occured. Please check your network and try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 50);
        })
    }, []
  )

  //  console.log(
  //   "currentPage, totalPages 🚀🚀🚀🚀",
  //   currentDashboardPage,
  //   ourtotalpage
  // );

  useEffect(() => {
    dispatch(job_to_update_loading(true));
    dispatch(getting_profile_profile_loading(true));
    dispatch(getting_cv_details_loading(true));
    dispatch(getJobApplicants(jobName));

    // if (applicants.message?.jobInfo.availability === false) {
    //   setClosed(true);
    // }
  }, []);

  useEffect(() => {
    if (applicants.message !== null) {
      let total = applicants.message?.applications.length;
      let page_num = total / 15 // 15 is each page limit.
      if (page_num < 1) {
        setTotal_page(1);
      } else {
        setTotal_page(page_num);
      }
    }
  }, [applicants]);

  let applicants_to_show = [];
  useEffect(() => {
    if (currentDashboardPage === 1) {
      applicants_to_show = [];
      let num = currentDashboardPage * 15;
      for (let i = 0; i <= num - 1; i++) {
        applicants_to_show.push(applicants.message?.applications[i])
      }
    } else {
      applicants_to_show = [];
      let num = currentDashboardPage - 1;
      let skip_num = num * 15; // this will be the start number for the applicants_to_show candidates.
      for (let i = skip_num; i <= (skip_num * 2) - 1; ++i) {
        applicants_to_show.push(applicants.message?.applications[i])
      }
    }
  }, [currentDashboardPage]);

  const handleBack = () => {
    navigate("/employer/jobs");
  };

  const getDate = (date) => {
    // console.log("date", date);
    if (date !== null && date !== undefined) {
      let split_date = date.split("T")[0].split("-");
      const return_date =
        split_date[2] +
        "/" +
        split_date[1] +
        "/" +
        split_date[0][2] +
        split_date[0][3];
      return return_date;
    }
  };

  const handlePost = () => {
    if (profile !== null) {
      analytics.track("Post job btn click", {
        Type: "Employer",
        time: new Date(),
        Email: profile?.email,
        Company_name: profile?.name,
        profileId: profile?._id,
        userId: profile?.userId
      });
    }
    navigate("/employer/upload");
  }

  return (
    <>

      <HelmetProvide
        title="Moil | candidates"
        description="Check out candidate that applied to your job"
      />

      <div className="h-calc-150 md:h-calc-70 px-4 py-8 overflow-y-scroll flex justify-center">
        <div className="w-full flex flex-col gap-y-6  lg:max-w-[1000px] xl:max-w-[1100px] xllarge:max-w-[1200px]">
          <div className="flex flex-col gap-y-4  lg:flex-row lg:items-center justify-between">
            <div className="flex flex-col gap-y-1">
              <BackButton onClick={handleBack} />
              <h1 className="font-semibold text-[24px] leading-normal text-black">
                Job details and applications
              </h1>
            </div>

            <DashboardOptionLink
              header="Post a Job"
              description={`Use our AI technology to create a job post in quick, easy steps.`}
              ellipseStyle="bg-white left-[60%] top-[10%]  bg-opacity-80 rounded-full blur-[77.64px] ipad:left-[181px] top-[-100px]"
              bgBack="dash-link-grad"
              svgType="postJob"
              onClick={handlePost}
              leftBordStyle="bg-[#FF6633]"
            />
          </div>

          <JobPostTableItem
            jobPosition={job?.jobTitle}
            postAge={job?.createdAt}
            logo={profile?.photo}
            onClick={() => navigate(`/employer/create-job/preview?jN=${job?.custom_name}&pw=true`)}
            closed={applicants.message?.jobInfo.availability}
            jobName={jobName} // select the new job id here
          />

          <p className="text-[#000000DE] text-base font-normal">
            Candidates who applied for this role.
          </p>

          <table className="hidden md:table">
            <thead>
              <tr className="text-black text-base leading-normal bg-[#5843BD1A] rounded-lg mb-4">
                <th className="py-3 rounded-tl-[8px] text-start font-medium pl-6 whitespace-nowrap truncate md:w-[200px] lg:w-max md:pr-3">
                  Candidate’s Name
                </th>
                <th className="hidden xl:table-cell">
                  <div className="py-3 text-start text-center font-medium whitespace-nowrap flex justify-center">
                    Date Applied
                    <span>
                      <SortSvg />
                    </span>
                  </div>
                </th>
                <th className="py-3 text-start font-medium whitespace-nowrap">
                  <div className="py-3 text-start text-center font-medium whitespace-nowrap flex justify-center">
                    Test Score
                    <SortSvg />
                  </div>
                </th>
                <th className="py-3 text-start font-medium whitespace-nowrap">
                  <p className="text-center">Status</p>
                </th>
                <th className="py-3 rounded-tr-[8px] text-start font-medium pr-6 whitespace-nowrap">
                  <p className="text-center">Action</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {applicants.message !== null && (
                <>
                  {applicants.message?.applications?.map((data, key) => {
                   return (
                      <JobPostCandidate
                        candidate={data?.name}
                        avatar={data?.photo}
                        bgClassName={key % 2 === 0 ? 'bg-opacity-80' : "bg-[#EFEFEF]"}
                        rating={data?.rating}
                        status={data?.stages}
                        jobPosition={applicants.message?.jobInfo?.jobTitle}
                        jobApplicationDate={getDate(data?.createdAt)}
                        jobName={jobName}
                        profileId={data?.applicant?.profile_id}
                        dataIndex={applicants.message?.applications?.indexOf(data)}
                        testScore = {data?.testScore}
                        key={key}
                      />
                    )
                  })}
                </>
              )}
            </tbody>
          </table>
          <>{applicants.message !== null && (
            <>
              {applicants.message?.applications?.map((data, key) => (
                <JobPostResponsiveCandidates
                  candidate={data?.name}
                  avatar={data?.photo}
                  testScore={data?.testScore}
                  rating={data?.rating}
                  status={data?.stages}
                  jobPosition={applicants.message?.jobInfo?.jobTitle}
                  jobApplicationDate={getDate(data?.createdAt)}
                  jobId={job?.custom_name}
                  profileId={data?.applicant?.profile_id}
                  dataIndex={applicants.message?.applications?.indexOf(data)}
                  key={key}
                />
              ))}
            </>
          )}</>
          <PaginationBtn
            totalPages={ourtotalpage}
            currentPage={currentDashboardPage}
            onNextPage={() => {
              if (currentDashboardPage < ourtotalpage) {
                setCurrentDashboardPage((prev) => prev + 1);
              }
            }}
            onPrevPage={() => {
              if (currentDashboardPage > 1) {
                setCurrentDashboardPage((prev) => prev - 1);
              }
            }}
            onSelectPage={(selectedPage) => {
              setCurrentDashboardPage(selectedPage);
            }}
          />
          {/* Recommendation part */}
          <div className="flex bg-white p-6 rounded-[1rem] gap-y-4 flex-col">
            <div className="flex sm:items-center flex-col sm:flex-row justify-between py-2">
              <p className="text-[#131313] text-[20px] leading-normal font-medium">
                Recommended Candidates
              </p>
              <div className={`${filterJob ? 'bg-white shadow rounded-tl-[4px] rounded-tr-[4px]' : ''} p-2 relative`}>
                <button className="flex items-center gap-x-1 focus:bg-white focus:border-white focus:outline-white" onClick={filterJobFunc}>
                  <span className="text-sm text-[#454545] font-normal leading-normal">Industry Matches</span>
                  <svg className={`${filterJob ? 'hidden' : 'block'}`} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5 5.75L7 9.25L10.5 5.75" stroke="#7D7D7D" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                  <svg className={`${filterJob ? 'block' : 'hidden'}`} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5 8.75L7 5.25L10.5 8.75" stroke="#7D7D7D" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                </button>
                <div className={`${filterJob ? 'block h-max' : 'hidden h-[0px]'} transit-all absolute  bg-white p-2 pt-0 left-0 w-full shadow justify-start rounded-bl-[4px] rounded-br-[4px]`}>
                  <div className="flex flex-col border-t mt-1 pt-1 border-[#898989] gap-y-1">
                    <button className="text-xs leading-normal text-start text-[#454545] font-normal focus:bg-white focus:border-white focus:outline-white" onClick={getRecommendedEmployeeByLastJobTitle}>Job Matches</button>
                    <button className="text-xs leading-normal text-start text-[#454545] font-normal focus:bg-white focus:border-white focus:outline-white" onClick={getRecommendedEmployeeByCategory}>Industry Matches</button>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-black text-opacity-60 text-base font-medium max-w-[6] mb-3">
              These are the candidates whose skills match your company description. You can switch your recommendations by clicking on <span className="text-[#131313]">Industry Matches’</span> dropdown at the top righ-hand corner of this box.
            </p>
            <div className="flex justify-center">
              <div className="grid grid-cols-1 gap-4 tablet:grid-cols-2 desktop:grid-cols-3">
                <>{tabSwitch == "title" || tabColor === "title" || tabSwitch == "titlet" ?
                  <>
                    {recommend !== null && recommend?.length !== 0 ? (
                      <>
                        {recommend?.slice(0, 6)?.map((data) => (
                          <DashboardCandidateItem
                            key={data?.userId?.profile_id}
                            candidate={data?.Fullname}
                            candidateJob={data?.Job_type}
                            candidateLocation={data?.Address}
                            candidateExperienceLevel={data?.Years_of_experience}
                            candidateBio={data?.About}
                            candidateRating={data?.Rating}
                            candidateSalary="35"
                            profileId={data?.userId?.profile_id}
                            candidateImage={data?.photo}
                          />
                        ))}
                      </>
                    ) : (
                      <h2>
                        Sorry, we presently do not have candidate to recommend.
                      </h2>
                    )}
                  </> :
                  <>
                    {recommend_category !== null && recommend_category?.length !== 0 ? (
                      <>
                        {recommend_category?.slice(0, 6)?.map((data) => (
                          <DashboardCandidateItem
                            key={data?.userId?.profile_id}
                            candidate={data?.Fullname}
                            candidateJob={data?.Job_type}
                            candidateLocation={data?.Address}
                            candidateExperienceLevel={data?.Years_of_experience}
                            candidateBio={data?.About}
                            candidateRating={data?.Rating}
                            candidateSalary="35"
                            profileId={data?.userId?.profile_id}
                            candidateImage={data?.photo}
                          />
                        ))}
                      </>
                    ) : (
                      <h2>
                        Sorry, we presently do not have candidate to recommend.
                      </h2>
                    )}
                  </>
                }</>
              </div>
            </div>
            <div>
              <Link className="text-[14px] leading-normal font-medium text-[#5843BD] md:text-[20px]">View more recommendations</Link>
            </div>
          </div>

          {/* Recommendation ends */}
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
