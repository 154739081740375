import React, { useState, useEffect } from "react";
import { GamefiedVector } from "../svgs/employersvg";
import { useNavigate } from "react-router-dom";
import { baseURL, baseURL1 } from "../../../Redux/baseURL";
import { useDispatch, useSelector } from "react-redux";
import { getEmployerProfile, returnHome } from "../../../Redux/ActionCreator";
import { ToastContainer, toast } from "react-toastify";
import LoaderModal  from "../LoaderModal";

export default function PaymentFailedModal({ setCancel, handleCloseFailedPaymentModal, operationType, direct, cta, type, flag, amountToPay }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employer = useSelector(state => state.employer.message);

  return (
    <section onClick={(e) => e.stopPropagation()} className="bg-white md:max-w-[550px] sm:max-w-[500px] min-w-[350px] w-[80%] z-100 m-auto rounded-[24px] pb-5 overflow-hidden relative">
     { cta == "Upgrade Plan" && <div className="absolute top-[-2%] -z-1 left-[-20%]">
        <GamefiedVector />
      </div>}

      <div className="flex flex-col items-center relative z-[1000] justify-center gap-y-4">

        <div className={`flex  items-center px-4 md:px-6 py-6 justify-between  No active bg-[#FF6633] PLAN w-full border-[#E9F1FF] border-b-[8px] text-[#fff]`}>
          <div className="text-xl font-medium leading-none text-center">Payment Failed</div>

          <div onClick={() => handleCloseFailedPaymentModal()} class=" w-8 h-8 rounded-full bg-[#fff] flex justify-center items-center font-bold text-[#1C1C1C] text-opacity-60 text-[20px] cursor-pointer">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.2103 1.14844L1.14844 11.3612M1.14844 1.14844L11.2103 11.3612" stroke="black" stroke-width="1.02128" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>

        <div className="w-full ">
          <p className={`text-[20px] font-semibold  leading-[1.2] text-center No active ${cta == "Upgrade Plan"  ? "text-[#5843BD]" : "text-[#FF6633]"} PLAN`}>
            Your subscription payment
            <br className="hidden md:block" /> has failed.
          </p>

        </div>

        <p className=" text-base font-normal leading-[1.2] text-center max-w-[280px] sm:max-w-[400px]">
          Your attempt to subscribe to a plan failed due to either insufficient fund or card expired.
          Kindly update your payment details.
        </p>

        <div className="flex items-center justify-center flex-col py-4 gap-x-4 w-full px-4 md:px-8 sm:flex-row gap-y-4">

          <button className="w-full md:w-max items-center justify-center flex gap-x-2 px-9 py-3 gap-3 rounded-3xl text-base font-semibold leading-normal text-center text-[#22263A80]" onClick={() => handleCloseFailedPaymentModal()} >
            close
          </button>
        </div>
      </div>
    </section>
  )
}

